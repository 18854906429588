import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(private login: LoginService, private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if (localStorage.LoginSession) {
        return true;
      }
      // navigate to login page
      this._router.navigate(['/auth'], { queryParams: { redirectURL: state.url } });
      // you can save redirect url so after authing we can move them back to the page they requested
      return false;
    

  }
  
}
