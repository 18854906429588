<!-- <ng-container *ngFor="let singleField of completeJSON.fields.formFields"> -->
<ng-container *ngFor="let validation of completeJSON.validations;index as _index">
  <mat-expansion-panel hideToggle *ngIf="validation.uuid" [disabled]="true" [expanded]="togglePanel[_index]">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8">
              <h2>
                <ng-template [ngIf]="validation.type === 'group'">
                  Group: {{validation.name}}
                </ng-template>
                <ng-template [ngIf]="validation.type !== 'group'">
                  {{validation.label}}
                </ng-template>
              </h2>
            </div>
            <div class="col-md-4">
              <button class="custom-button float-right" (click)="togglePanel[_index]=!togglePanel[_index]">
                <i class="fa fa-arrows-v"></i>
              </button>
              <!-- Required <mat-slide-toggle (change)="required = !required"></mat-slide-toggle> -->
              <ng-template [ngIf]="validation.type !== 'group'">
                <ng-template [ngIf]="validation.type !== 'label'">
                  Required <mat-slide-toggle [checked]="validation.required"
                    (change)="changeRequireFlag(validation.uuid,!validation.required)">
                  </mat-slide-toggle>
                </ng-template>
                <button class="custom-button float-right" (click)="editField(validation)">
                  <i class="fa fa-cog"></i>
                </button>
                <button class="custom-button float-right" (click)="replicateField(validation.uuid)">
                  <i class="fa fa-copy"></i>
                </button>
                <button class="custom-button float-right" (click)="removeField(validation.uuid)">
                  <i class="fa fa-trash"></i>
                </button>
              </ng-template>

            </div>
          </div>

        </div>

      </mat-panel-title>

      <!-- <mat-panel-description>
          This is a summary of the content
        </mat-panel-description> -->
    </mat-expansion-panel-header>
    <ng-container *ngFor="let eachItem of  validation | keyvalue">
      <!-- {{ eachItem.value }}---==<br> -->
      <div class="row" *ngIf="eachItem.value || eachItem.value==false">
        <div class="col-md-3">{{eachItem.key}} </div>
        <div class="col-md">
          :
        </div>
        <div class="col-md-8 fw-600">
          {{eachItem.value === '' ? '-' : eachItem.value | json}}
        </div>
      </div>
    </ng-container>
  </mat-expansion-panel>
  <div class="card" *ngIf="validation.uuid" style="display: none;">
    <div class="card-body">
      <div class="row">
        <div class="col-md-8">
          <h2>
            <ng-template [ngIf]="validation.type === 'group'">
              Group: {{validation.name}}
            </ng-template>
            <ng-template [ngIf]="validation.type !== 'group'">
              {{validation.label}}
            </ng-template>
          </h2>
        </div>
        <div class="col-md-4" *ngIf="validation.type !== 'group'">
          <!-- Required <mat-slide-toggle (change)="required = !required"></mat-slide-toggle> -->
          Required <mat-slide-toggle [checked]="validation.required"
            (change)="changeRequireFlag(validation.uuid,!validation.required)">
          </mat-slide-toggle>
          <button class="custom-button" (click)="editField(validation)">
            <i class="fa fa-cog"></i>
          </button>
          <button class="custom-button" (click)="replicateField(validation.uuid)">
            <i class="fa fa-copy"></i>
          </button>
          <button class="custom-button" (click)="removeField(validation.uuid)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

    </div>
    <!-- {{validation.uuid === singleField.uuid}} -->
  </div>
</ng-container>
<!-- </ng-container> -->
<ng-template [ngIf]="!completeJSON?.fields?.formFields.length">
  <div class="container">
    <div class="no-field-selected fw-600">
      No fields added
    </div>
  </div>
</ng-template>