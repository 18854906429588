<h1>
  Select Dropdown
  <span class="float-right">
    <!-- <input id="check_required" type="checkbox" class="input-box" [checked]="required"
      (change)="required = !required" /> Required -->
    Required <mat-slide-toggle [checked]="required" (change)="required = !required">
    </mat-slide-toggle>
  </span>
</h1>
<div class="container-fluid">
  <div class="form-group">
    <select name="prefill" id="prefill" class="form-control" [(ngModel)]="preFill"
      (change)="onPrefillChange($event.target.value)">
      <option value="{{prefill.value}}" *ngFor="let prefill of prefillSelections">{{prefill.name}}</option>
    </select>
    <div class="form-label float-left">Name prefill</div>
    <div class="form-label float-right" style="font-weight: 600;">Disables name</div>
  </div>
  <!-- label with name -->
  <div class="form-group">
    <input class="form-control" id="label" type="text" placeholder="Label" [(ngModel)]="label" />
    <div class="form-label float-left">example: Select your Country?</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <!-- <<<<<< main input component -->
  <div class="form-group">
    <input class="form-control" id="inputID" type="text" placeholder="ID" [(ngModel)]="inputID" value="inputID" />
    <div class="form-label float-left">example: country</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>

  <div class="form-group">
    <input class="form-control" id="input_name" type="text" placeholder="Name" [(ngModel)]="inputName" value="inputName"
      [disabled]="disableNameAttr" />
    <div class="form-label float-left">example: country</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <div class="form-group">
    <input class="form-control" id="placeholder_Id" type="text" placeholder="Placeholder" [(ngModel)]="placeholder"
      value="placeholder" />
    <div class="form-label float-left">example: Select a Country</div>
    <!-- <div class="form-label float-right" style="font-weight: 600;">Field required</div> -->
  </div>

  <div class="form-group">
    <div class="form-control">
      <div class="float-left">
        Multi Select Dropdown
      </div>
      <input id="multiple_flag" type="checkbox" class="input-box checkbox-30 float-right" [checked]="multiple_flag"
        (change)="multiple_flag = !multiple_flag" />
    </div>
    <div class="form-label float-left"> default: false</div>
  </div>

  <div class="form-group">
    <div class="form-control">
      <div class="float-left">
        Dropdown Type
      </div>
      <div class="float-right">
        Dynamic
        <mat-slide-toggle [checked]="dynamic_flag" (change)="dynamic_flag = !dynamic_flag">
        </mat-slide-toggle> Static
      </div>
    </div>
    <div class="form-label float-left"> {{dynamic_flag ? 'Dynamic' : 'Static'}}</div>
  </div>
  <div class="form-group" *ngIf="dynamic_flag">
    <input class="form-control" id="apiPath" type="text" placeholder="API Path" [(ngModel)]="apiPath" value="apiPath"
      (keyup)="apiPathValidation($event.target.value)" />
    <div class="form-label float-left">example: property?propertySet=</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <div class="form-group" *ngIf="dynamic_flag">
    <input class="form-control" id="apiTrigger" type="text" placeholder="API Trigger" [(ngModel)]="apiTrigger"
      value="apiTrigger" />
    <div class="form-label float-left">example: 6248ba36-2948-4a54-886f-a8fcfd115d9b</div>
  </div>

  <div class="form-group" *ngIf="!dynamic_flag">
    <input class="form-control" id="selectValues" type="text" placeholder="Dropdown Values" [(ngModel)]="selectValues"
      value="selectValues" />
    <div class="form-label float-left">example: One,Two,Three</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>

  <div class="form-group" *ngIf="!dynamic_flag">
    <input class="form-control" id="defaultValue" type="text" placeholder="Default value" [(ngModel)]="defaultValue"
      value="defaultValue" />
    <div class="form-label float-left">example: One</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <div class="form-group" *ngIf="dynamic_flag">
    <div class="form-control">
      <div class="float-left">
        Auto Populate
      </div>
      <input id="autoPopulate" type="checkbox" class="input-box checkbox-30 float-right" [checked]="autoPopulate"
        (change)="autoPopulate = !autoPopulate" />
    </div>
    <div class="form-label float-left"> default: false</div>
  </div>
  <div class="form-group">
    <input class="form-control" id="validationAttribute_Id" type="text" placeholder="Validation Attribute"
      [(ngModel)]="validationAttribute" value="{{validationAttribute}}" />
    <div class="form-label float-left">example: Product</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>


  <div class="form-group">
    <input class="form-control" id="enable" type="text" placeholder="Enable" [(ngModel)]="enable" value="{{enable}}" />
    <div class="form-label float-left">example: UUID</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>
  <div class="form-group">
    <input class="form-control" id="disable" type="text" placeholder="Disable" [(ngModel)]="disable"
      value="{{disable}}" />
    <div class="form-label float-left">example: UUID</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>

  <div class="form-group">
    <input class="form-control" id="description" type="text" placeholder="Description"
      [(ngModel)]="description" value="{{description}}" maxlength="1000"/>
    <div class="form-label float-left">example: You can explain more about what this field will be used for.</div>
  </div>
  
  <div class="row" *ngFor="let count of validatorCount;index as i">
    <div class="col-md-1" *ngIf="i == 0">
      <button class="btn btn-sm" (click)="addMoreValidators()">
        +
      </button>
    </div>
    <div class="col-md">Add Validator</div>
    <div class="col-md">
      <input type="text" name="fromUUID" id="fromUUID" [(ngModel)]="count['from']" placeholder="From"
        class="form-control" />
    </div>
    <div class="col-md">
      <select id="typeOfComparision" name="typeOfComparision" [(ngModel)]="count['type']" class="form-control">
        <option value="" selected>Select comparision</option>
        <option value="lessThan">
          << /option>
        <option value="greaterThan">></option>
        <option value="notEqualsTo">!==</option>
        <option value="equalsTo">===</option>
      </select>
    </div>
    <div class="col-md">
      <input type="text" name="toUUID" id="toUUID" [(ngModel)]="count['to']" placeholder="To" class="form-control" />
    </div>
    <div class="col-md-1" *ngIf="i != 0">
      <button class="btn btn-sm" (click)="removeValidators(i)">
        -
      </button>

    </div>
  </div>
  <div class="text-danger">
    <span class="p-3 fw-600">
      {{errorMsg}}
    </span>
  </div>
  <div class="text-danger">
    <span class="p-3 fw-600">
      {{errorMsg2}}
    </span>
  </div>
  <div class="form-group">
    <button class="btn float-left" (click)="onCancel()">Cancel</button>
    <button class="btn float-right" (click)="modifyTemplateJSON()">Save & Update</button>
  </div>

</div>