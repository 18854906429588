export const ErrorConst = Object.freeze({
    
    pleaseSelect: 'Please select',
    pleaseEnter: 'Please enter',

    pleaseEnterName: 'Please enter name.',
    pleaseSelectProperty: 'Please select property.',
    pleaseEnterId: 'Please enter id.',
    pleaseEnterDescription: 'Please enter description.',
    pleaseSelectRecipients: 'Please select recipients.',
    pleaseEnterMessage: 'Please enter message.',
    pleaseEnterEmailSubject: 'Please enter email subject.',
    pleaseEnterEmailBody: 'Please enter email body.',
    pleaseSelectRequestType: 'Please select request type.',
    pleaseEnterQuantity: 'Please enter quantity.',
    pleaseEnterRate: 'Please enter rate.',
    pleaseSelectDate: 'Please select date.',
    pleaseSelectPriority: 'Please select priority.',
    pleaseSelectSuite: 'Please select suite.',
    pleaseSelectFloor: 'Please select floor.',
    pleaseEnterFloor: 'Please enter floor.',
    pleaseEnterArea: 'Please enter area.',
    pleaseEnterRent: 'Please enter rent.',
    pleaseSelectFrequency: 'Please select frequency.',
    pleaseSelectImage: 'Please select image.',
    pleaseEnterUnit: 'Please enter unit.',
    pleaseEnterBudget: 'Please enter budget.',
    pleaseEnterAddress: 'Please enter address.',
    pleaseSelectState: 'Please select state.',
    pleaseEnterState: 'Please enter state.',
    pleaseEnterCity: 'Please enter city.',
    pleaseEnterZipcode: 'Please enter zipcode.',
    pleaseEnterCountry: 'Please enter country.',
    pleaseEnterTotalSqarefeet: 'Please enter total sqare feet.',
    pleaseSelectAssetType: 'Please select asset type.',
    pleaseEnterEmail: 'Please enter email.',
    pleaseEnterValidEmail: 'Please enter valid email.',
    pleaseEnterLatitude: 'Please enter latitude.',
    pleaseEnterLongitude: 'Please enter longitude.',
    pleaseSelectTimezone: 'Please select timezone.',
    pleaseEnterOwner: 'Please enter owner.',
    pleaseEnterFundManager: 'Please enter fund manager.',
    pleaseEnterAssetManager: 'Please enter asset manager.',
    pleaseEnterPropertyManager: 'Please enter property manager.',
    pleaseEnterlargestTenant: 'Please enter largest tenant.',
    pleaseEnterEstimate: 'Please enter estimate.',
    pleaseEnterPassword: 'Please enter password.',

    pleaseSelectCaptcha: 'Please select captcha.',
    pleaseSelectProduct: 'Please select product.',
    pleaseEnterTitle: 'Please enter title.',
    pleaseEnterValidDomain: 'Please enter valid domain.',
    pleaseEnterDomain: 'Please enter domain.',
    pleaseEnterStreetAddress: 'Please enter street address.',
    pleaseEnterNumberOfEmployees: 'Please enter number of employees.',
    pleaseSelectIndustry: 'Please select industry.'

});

