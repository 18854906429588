<div class="field-container">
  <ng-container *ngFor="let menu of menuList">
    <button (click)="showForm(menu['elementValue'])" class="btn btn-block btn-large field-list-btn">
      <span class="float-left">
       {{menu['elementLabel']}}
      </span>
      <i class="fa fa-plus float-right"></i>
   </button>
  </ng-container>
</div>
