import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { JsonTemplateViewerComponent } from './main/json-template-viewer/json-template-viewer.component';
import { MainComponent } from './main/main.component';
import { FormBuilderComponent } from './main/form-builder/form-builder.component';
import { FieldComponentComponent } from './main/field-component/field-component.component';
import { HeaderComponent } from './main/header/header.component';
import { InputTextFieldComponent } from './main/form-builder/input-text-field/input-text-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropdownFieldComponent } from './main/form-builder/select-dropdown-field/select-dropdown-field.component';
import { InputDateFieldComponent } from './main/form-builder/input-date-field/input-date-field.component';
import { TextAreaFieldComponent } from './main/form-builder/text-area-field/text-area-field.component';
import { InputTimeFieldComponent } from './main/form-builder/input-time-field/input-time-field.component';
import { InputRadioButtonComponent } from './main/form-builder/input-radio-button/input-radio-button.component';
import { InputNumberFieldComponent } from './main/form-builder/input-number-field/input-number-field.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialogComponent } from './main/json-template-viewer/mat-dialog/mat-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InputEmailFieldComponent } from './main/form-builder/input-email-field/input-email-field.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { FileUploadFieldComponent } from './main/form-builder/file-upload-field/file-upload-field.component';
import { LabelFieldComponent } from './main/form-builder/label-field/label-field.component';
import { FieldCustomizationComponent } from './main/form-builder/field-customization/field-customization.component';
import { DigitOnlyDirective } from './directive/digit-only.directive';
import { GroupBuilderComponent } from './main/form-builder/group-builder/group-builder.component';
import { MatSelectModule } from '@angular/material/select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatExpansionModule } from '@angular/material/expansion';
import { ModalTemplateDetailComponent } from './main/modal-template-detail/modal-template-detail.component';
import { InputCheckboxFieldComponent } from './main/form-builder/input-checkbox-field/input-checkbox-field.component';
import { AssetUrlPipe } from './shared/pipes/asset-url.pipe';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastService } from './service/toast/toast.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoginService } from './service/login.service';
import {MatIconModule} from '@angular/material/icon';
import { PrintErrorPipe } from './shared/pipes/print-error.pipe';
// interceptors
import { RequestInterceptor } from '../app/service/interceptor/request-inteceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    JsonTemplateViewerComponent,
    MainComponent,
    FormBuilderComponent,
    FieldComponentComponent,
    HeaderComponent,
    InputTextFieldComponent,
    SelectDropdownFieldComponent,
    InputDateFieldComponent,
    TextAreaFieldComponent,
    InputTimeFieldComponent,
    InputRadioButtonComponent,
    InputNumberFieldComponent,
    MatDialogComponent,
    InputEmailFieldComponent,
    FileUploadFieldComponent,
    LabelFieldComponent,
    FieldCustomizationComponent,
    DigitOnlyDirective,
    GroupBuilderComponent,
    ModalTemplateDetailComponent,
    InputCheckboxFieldComponent,
    AssetUrlPipe,
    PrintErrorPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSlideToggleModule,
    NgxJsonViewerModule,
    MatSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatExpansionModule,
    HttpClientModule,
    MatSnackBarModule,
    MatIconModule
  ],
  providers: [ToastService,LoginService,{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },],
  bootstrap: [AppComponent],
  exports:[PrintErrorPipe]
})
export class AppModule { }
