import { Component, Input, OnInit } from '@angular/core';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {

  constructor(private jsonPreviewService: JsonPreviewServiceService) { }
  customViewFlag: boolean = true;
  currentUUID = '';
  completeJSON;
  @Input() showTextField: false;
  @Input() elementType: string = '';

  ngOnInit(): void {
    this.completeJSON = this.jsonPreviewService.getAllFields();
  }

  showCustom() {
    this.currentUUID = '';
    this.customViewFlag = true;
    // alert()
    // Scroll Down to JSON viewer div to see new field added
    let div = document.getElementById('jsonViewContainer');
    div.className += " blink-bg";
    setTimeout(() => {
      div.scrollTop = div.scrollHeight - div.clientHeight;
      setTimeout(() => {
        div.className = "json-content";
      }, 100);

    }, 200);
  }

  hideCustom() {
    this.currentUUID = '';
    this.customViewFlag = false;
  }

  editForm(event) {
    this.hideCustom();
    this.elementType = event.type;
    this.currentUUID = event.uuid;
  }
}
