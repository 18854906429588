
<div class="container">
  <h1>
    JSON PREVIEW
    <button (click)="expandViewer()" class="float-right transparent-icon">
      <i class="fa fa-expand"></i>
    </button>
    <button (click)="resetTemplate()" class="float-right mr-3 transparent-icon">
      <i class="fa fa-refresh"></i>
    </button>
  </h1>
  <input id="view_id" type="text" value="{{completeData | json}}" />
  <div id="jsonViewContainer" class="json-content">
    <pre>{{completeData | json}}</pre>
  </div>

  <button id="copyToClipboard" (click)="copyToClipboard()">
    <i class="fa fa-copy"></i> Copy to clipboard
  </button>

</div>
