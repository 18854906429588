import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import { PrmhttpService } from './http/prmhttp.service';
import { ApiUrls } from '../constants/apiUrls';
import { SystemService } from './system.service';
import { ToastService } from './toast/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as forge from 'node-forge';
import { HttpService } from './http/http.service';

let LoginSession = {
  accessToken: '', idToken: '', userData: {}, expiresIn: 0, loggedInDate: 0, name: '', userGroup: '',
  refreshToken: '', user_id: ''
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(
    private http: HttpClient,
    private dataService:DataService,
    private httpPrm:PrmhttpService,
    private systemSetting:SystemService,
    private toastService:ToastService,
    private route:ActivatedRoute,
    private router:Router,
    private _http:HttpService
  ){

  }
  urlify(...callPath: string[]) {
    let url = this.systemSetting.environmentUpdatedUrl();
    return url + '/prmauthentication/v1/PrmAuth/' + callPath.join('/');
  }

  loginPRM(username: string, password: string) {
    localStorage.email = username;
    this.getToggleGraphs();
    let headersReq = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET',
      'Access-Control-Allow-Origin': '*'
    });
    //return this.http.post(LoginService.urlify('Secure/User/v1.1/signInInfo') + '?from_amp=true', { username: username });
    return new Promise((resolve) => {
      this.http.post(this.urlify('Login'), {
        username,
        password
      }, { headers: headersReq })
        .subscribe((response: any) => {

          {
            localStorage.emailId = username;
            LoginSession.accessToken = response.access_token;
            LoginSession.refreshToken = response.refresh_token;
            //localStorage.propertySet = '';
            //LoginSession.idToken = response.data.idToken;
            LoginSession.expiresIn = response.expires_in;
            LoginSession.user_id = response.user_id;
            localStorage.userId = response.user_id;
            LoginSession.loggedInDate = Date.now();
            LoginSession.userData = '';
            localStorage.loginFlag = true;
            localStorage.first_name = '';
            localStorage.last_name = '';

            if (response.image !== null && response.image !== undefined) {

              localStorage.profileImageUrl = response.image.path;
            } else {
              localStorage.profileImageUrl = '';
            }
            //localStorage.roleSet = '';
            LoginSession.userGroup = '';
            localStorage.LoginSession = JSON.stringify(LoginSession);

            // location.reload();
            let obj = {
              userId: LoginSession.user_id
            };
            this.httpPrm.getData(ApiUrls.userProfile, obj).subscribe((response: any) => {

              console.log(response);
              //localStorage.loginFlag = true;
              localStorage.LoginSession = JSON.stringify(LoginSession);
              if (response.data) {
                localStorage.propertySet = JSON.stringify(response.data.propertyIdSet);
              }
              localStorage.roleSet = JSON.stringify(response.data.roleSet);
              localStorage.first_name = response.data.firstName;
              localStorage.last_name = response.data.lastName;
              localStorage.title = response.data.title;

              let groups = [];
              if (response.data.enterprise && response.data.enterprise.id) {
                localStorage.enterpriseId = response.data.enterprise.id;
                localStorage.enterpriseExternalId = response.data.enterprise.id;
                localStorage.enterpriseName =response.data.enterprise.name; 
              }

              resolve({ success: true });

            })



          }

        }, ({ error }: any) => {

          resolve({
            success: false,
            errors: error
          });
        });
    });

  }

  getEnterpriseId() {
    return localStorage.enterpriseId;
  }
  getTenantGroups() {
    return JSON.parse(localStorage.groups);
  }

  getPRMUserTitle() {
    return localStorage.title;
  }

  isTenant() {
    if (localStorage.isTenant === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  setUserSessionInfo(response) {
    if (response.meta.code === 200 && ((response.data.userData.group.join(',').indexOf(this.systemSetting.themeSettingsData.landlord_group_name) !== -1) || (response.data.userData.group.join(',').indexOf('SuperAdmin') !== -1))) {
      localStorage.propertySet = JSON.stringify(response.data.propertySet);
      LoginSession.userData = response.data.userData;
      localStorage.loginFlag = true;
      localStorage.first_name = response.data.userData.name;
      localStorage.last_name = response.data.userData.lastname;
      if (response.data.image !== null && response.data.image !== undefined) {
        localStorage.profileImageUrl = response.data.image.path;
      } else {
        localStorage.profileImageUrl = '';
      }
      localStorage.roleSet = JSON.stringify(response.data.roleSet);
      LoginSession.userGroup = response.data.userData.group;
      localStorage.LoginSession = JSON.stringify(LoginSession);
      this.afterLoginRedirects();
    } else {
      if (response.meta.code === 200 && (response.data.userData.group.join(',').indexOf('tenant') !== -1)) {
        this.toastService.showErrorToast('Tenant user can not login to AMP');
      }
      localStorage.clear();

    }
  }

  afterLoginRedirects() {
    let ampPermissions = this.getAssetPermissions();
    let params = this.route.snapshot.queryParams;
    if (params['redirectURL']) {
      console.log('redirect');

      this.router.navigateByUrl(params['redirectURL']).catch(
        (err) => {
          this.router.navigate(['home']);
          // this.router.navigate(['portfolio']);
        }
      );
    }
    else if (this.isAdmin() || this.isSuperAdmin() || this.isPropertyManager()) {
      this.router.navigate(['home']);
      // this.router.navigate(['portfolio']);

    }
    else {

      this.router.navigate(['home']);
      // if (ampPermissions.portfolioAccess) {
      //   this.router.navigate(['/portfolio']);
      // } else if (ampPermissions.productsAccess) {
      //   this.router.navigate(['/product']);
      // } else if (ampPermissions.customersAccess) {
      //   this.router.navigate(['/customers']);
      // } else if (ampPermissions.visitorAccess) {
      //   this.router.navigate(['/visitor']);
      // } else if (ampPermissions.opportunitiesAccess) {
      //   this.router.navigate(['/opportunities']);
      // } else if (ampPermissions.workOrder) {
      //   this.router.navigate(['/work-order/list']);
      // }

    }
  }

  getListPropertySet() {

    let roleSet = this.getRoleSet(), propertySet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.propertyArray) {
        if (roleSet[index].permissionJson.propertyArray[index2].action) {


          if (roleSet[index].permissionJson.propertyArray[index2].consumer.indexOf('admin') > -1) {
            if (roleSet[index].permissionJson.propertyArray[index2].action.join(',').indexOf('list') > -1) {
              propertySet.push(roleSet[index].permissionJson.propertyArray[index2]);
            }
          }

        }
      }

    }

    let propertyIdSet = [];

    for (const index in propertySet) {

      propertyIdSet.push(propertySet[index].id);

    }
    return propertyIdSet.join(',');

    // return productSet;

  }

  getPropertySet() {

    let data;

    if (localStorage.propertySet !== 'undefined') {
      data = JSON.parse(localStorage.propertySet);
    }

    let propertySet = [];
    for (const index in data) {
      propertySet.push(data[index].id);
    }

    return propertySet.join(',');
  }

  getRoleSet() {
      let userId = this.getPRMUserId();
      if (!!userId) {
        this.http.get(this.urlify('Secure/GetUserInfo/' + this.getPRMUserId()))
          .subscribe((response: any) => {
            localStorage.roleSet = JSON.stringify(response.data.roleSet);
            if (response.data.image !== null && response.data.image !== undefined) {
              localStorage.profileImageUrl = response.data.image.path;
            } else {
              localStorage.profileImageUrl = '';
            }
            this.setUserImage();
          });
      }
    
    if (localStorage.roleSet) {
      if (localStorage.roleSet !== 'undefined') {
        return JSON.parse(localStorage.roleSet);
      }
    }
    else {
      return '';
    }

  }

  getPrmRoleSet() {
    if (localStorage.roleSet) {
      if (localStorage.roleSet !== 'undefined') {
        return JSON.parse(localStorage.roleSet);
      }
    } else {
      return [];
    }
  }

  getPRMUserId() {
    if (localStorage.LoginSession) {
      const data = JSON.parse(localStorage.LoginSession);
      return data.user_id;
    }
    return '';
  }

  getPrmPortfolioId() {
    if (localStorage.prmSelectedPortfolioId) {
      const data = localStorage.prmSelectedPortfolioId;
      return data;
    }
    return '';
  }

  getPrmDomainPortfolio() {
    return localStorage.getItem('selected-portfolio')
  }

  getPRMRoleData() {
    if (localStorage.prmRoleData) {
      const data = JSON.parse(localStorage.prmRoleData);
      return data;
    }
    return {};
  }

  hasManageAccess(module) {
    let permissionModule = this.getPRMRoleData().permissionModule;
    if (permissionModule[module]) {
      let permissionSet = ["get", "list", "create", "update", "delete"];
      let roleSet = permissionModule[module];
      return permissionSet.every(val => roleSet.includes(val));
    }
    else {
      return false;
    }
  }

  resetPRMPassword(payload) {
    return this.http.post(this.urlify('SendResetPassordEmail?email=' + payload.username), payload);
  }


  isPropertyManager() {

    let roleSet = [];
    JSON.parse(localStorage.roleSet);
  
    for (const index in roleSet) {
      if (roleSet[index].name.indexOf('Property Manager') > -1 || roleSet[index].name.indexOf('Property manager') > -1) {
        return true;
      }
    }
    return false;
  }

  isAgent() {

    let roleSet = [];
      JSON.parse(localStorage.roleSet);
   

    for (const index in roleSet) {
      if (roleSet[index].name.indexOf('Agent') > -1 || roleSet[index].name.indexOf('Agent') > -1) {
        return true;
      }
    }
    return false;
  }

  isViewOnly() {
    let roleSet = localStorage.roleSet ? JSON.parse(localStorage.roleSet) : [];
    for (const index in roleSet) {
      if (roleSet[index].name.indexOf('View only') > -1) {
        return true;
      }
    }
    return false;
  }


  setPropertySet(propertyData) {
    let data;

    if (localStorage.propertySet !== 'undefined') {
      data = JSON.parse(localStorage.propertySet);
    }


    data.push({ id: propertyData.id, name: propertyData.name });

    localStorage.propertySet = JSON.stringify(data);

  }

  updatePropertySet(propertyData) {
    let data;
    if (localStorage.propertySet !== 'undefined') {
      data = JSON.parse(localStorage.propertySet);
    }
    let index = data.findIndex(x => x.id === propertyData.id);
    if (index > -1) {
      data[index].name = propertyData.name;
    }
    localStorage.propertySet = JSON.stringify(data);
  }


  getFullName() {
    return localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name');
  }

  getLastName() {
    return localStorage.getItem('last_name');
  }

  getToggleGraphs() {
    //return this.http.get(LoginService.APIBaseURL + '/togglegraphs')
    let hideGraph = this.systemSetting.allSettings.hide_graphs;
    localStorage.hide_graphs = hideGraph;
  }

  getToggleGraphsFlag() {
    return localStorage.hide_graphs;
  }


  getAccessToken() {
    if (localStorage.LoginSession) {
      const data = JSON.parse(localStorage.LoginSession);
      return data.accessToken;
    }
    return '';
  }

  // getAmpPermissions() {
  //   if (localStorage.LoginSession) {
  //     let permissions=JSON.parse(this.amplify.userAssetPermission());
  //     if (this.amplify.userAssetPermission() !== 'N/A' && permissions) {
  //       return permissions;
  //     }
  //   }

  //   return '';
  // }

  getProfileImageUrl() {
    if (!localStorage.hasOwnProperty('profileImageUrl') ||
      localStorage.profileImageUrl === '' || localStorage.profileImageUrl === undefined) {
      return '';
    }
    // return this.fileService.getImagePath(localStorage.profileImageUrl);
  }

  setProfileImageUrl(imageUrl) {
    localStorage.profileImageUrl = imageUrl;
  }

  getIdToken() {
    if (localStorage.LoginSession) {
      const data = JSON.parse(localStorage.LoginSession);
      return data.idToken;
    }
    return '';
  }

  getAssetPermissions() {

    return {readOnly: false};

    // if (localStorage.LoginSession) {
    //   try {
    //     return JSON.parse(JSON.parse(localStorage.LoginSession).userData.asset_permission);
    //   } catch (e) {
    //     return '';
    //   }
    // }
    // return '';
  }

  getUserId() {
    if (localStorage.LoginSession) {
      const data = JSON.parse(localStorage.LoginSession);
      return data.userData.username;
    }
    return '';
  }

  getLoggedUserId() {
    if (localStorage.LoginSession) {
      const data = JSON.parse(localStorage.LoginSession);
      return data.user_id;
    }
    return '';
  }

  getLoggedUserUUId() {
    if (localStorage.LoginSession) {
      const data = JSON.parse(localStorage.LoginSession);
      return data.userData.username;
    }
    return '';
  }

  resetPassword(payload) {
    return this.http.post(this.urlify('ResetPassword'), payload);
  }

  confirmResetPassword(username: string, password: string, confirmPassword: string, confirmationCode: string) {
    return this.http.post(this.urlify('ConfirmResetPassword'), { username, password, confirmPassword, confirmationCode });
  }

  logout() {
    const userName = localStorage.getItem('email');
    localStorage.clear();
    localStorage.loginFlag = true;

    LoginSession = { accessToken: '', refreshToken: '', idToken: '', user_id: '', userData: { name: '' }, expiresIn: 0, loggedInDate: 0, name: '', userGroup: '' };
    
    this.router.navigate(['/auth']);

    // this.http.post(LoginService.urlify('Secure/SignOut'), { userName }, {
    //   headers: new HttpHeaders({ Authorization: this.getIdToken(), AccessToken: this.getAccessToken() })
    // })
    //   .subscribe((response: any) => {
    //     localStorage.clear();
    //     localStorage.loginFlag = true;
    //     //LoginSession = { accessToken: '', idToken: '', userData: { name: '' } };
    //     LoginSession = { accessToken: '', refreshToken: '', idToken: '', userData: { name: '' }, expiresIn: 0, loggedInDate: 0, name: '', userGroup: '' };
    //     this.router.navigate(['/auth']);
    //   }, ({ error }: any) => {
    //     if (error.errors[0].code === 'NotAuthorizedException') {
    //       localStorage.clear();
    //       this.router.navigate(['/auth']);
    //     }
    //   });
  }

  getUser() {
    return LoginSession ? LoginSession.userData : null;
  }

  isPRMSuperAdmin(): boolean {
    if (localStorage.roleSet) {
      const data = JSON.parse(localStorage.roleSet);
      const found = data.some(elem => elem.name === 'SuperAdmin');
      return found;
    }
    return false;
  }

  getPRMEmailId() {
    return localStorage.emailId;
  }

  getName() {
    return localStorage.first_name;
  }

  setName(name) {
    localStorage.name = name;
  }


  getListProductSet() {

    let roleSet = this.getRoleSet(), productSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.productArray) {
        if (roleSet[index].permissionJson.productArray[index2].action) {


          if (roleSet[index].permissionJson.productArray[index2].consumer.indexOf('admin') > -1) {
            if (roleSet[index].permissionJson.productArray[index2].action.join(',').indexOf('list') > -1) {
              productSet.push(roleSet[index].permissionJson.productArray[index2]);
            }
          }

        }
      }

    }

    let productIdSet = [];

    for (const index in productSet) {

      productIdSet.push(productSet[index].id);

    }
    return productIdSet.join(',');

    // return productSet;

  }

  getListUserSet() {

    let roleSet = [];
    roleSet = JSON.parse(localStorage.roleSet);;
    
    let userSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.userArray) {
        if (roleSet[index].permissionJson.userArray[index2].action) {


          if (roleSet[index].permissionJson.userArray[index2].action.join(',').indexOf('list') > -1) {
            userSet.push(roleSet[index].permissionJson.userArray[index2]);
          }
        }
      }

    }

    let userIdSet = [];

    for (const index in userSet) {

      userIdSet.push(userSet[index].id);

    }
    return userIdSet.join(',');

    // return productSet;

  }

  getListTenantSet() {

    let roleSet = this.getRoleSet(), tenantSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.tenantArray) {
        if (roleSet[index].permissionJson.tenantArray[index2].action) {


          if (roleSet[index].permissionJson.tenantArray[index2].action.join(',').indexOf('list') > -1) {
            tenantSet.push(roleSet[index].permissionJson.tenantArray[index2]);
          }
        }
      }

    }

    let tenantIdSet = [];

    for (const index in tenantSet) {

      tenantIdSet.push(tenantSet[index].id);

    }
    return tenantIdSet.join(',');

    // return productSet;

  }

  getListPRMProposalIdSetString() {
    let role = JSON.parse(localStorage.prmRoleData);;

    let proposalIdSet = [];


    for (const index in role.permissionJson.proposalArray) {
      if (role.permissionJson.proposalArray[index].action.join(',').indexOf('list') > -1)
        proposalIdSet.push(role.permissionJson.proposalArray[index].id);
    }


    return proposalIdSet.join(',');

  }

  getListPRMProspectIdSetString() {
    let role = JSON.parse(localStorage.prmRoleData);;

    let prospectIdSet = [];


    for (const index in role.permissionJson.prospectArray) {
      if (role.permissionJson.prospectArray[index].action.join(',').indexOf('list') > -1)
        prospectIdSet.push(role.permissionJson.prospectArray[index].id);
    }


    return prospectIdSet.join(',');

  }

  getListProposalSet() {
    let roleSet = [];
    roleSet = JSON.parse(localStorage.roleSet);;
  
    let proposalSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.proposalArray) {
        if (roleSet[index].permissionJson.proposalArray[index2].action) {


          if (roleSet[index].permissionJson.proposalArray[index2].action.join(',').indexOf('list') > -1) {
            proposalSet.push(roleSet[index].permissionJson.proposalArray[index2]);
          }
        }
      }

    }

    let proposalIdSet = [];

    for (const index in proposalSet) {

      proposalIdSet.push(proposalSet[index].id);

    }
    return proposalIdSet.join(',');

    // return productSet;

  }


  getListProspectSet() {
    let roleSet = [];
    roleSet = JSON.parse(localStorage.roleSet);;
    
    let prospectSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.prospectArray) {
        if (roleSet[index].permissionJson.prospectArray[index2].action) {


          if (roleSet[index].permissionJson.prospectArray[index2].action.join(',').indexOf('list') > -1) {
            prospectSet.push(roleSet[index].permissionJson.prospectArray[index2]);
          }
        }
      }

    }

    let prospectIdSet = [];

    for (const index in prospectSet) {

      prospectIdSet.push(prospectSet[index].id);

    }
    return prospectIdSet.join(',');

    // return productSet;

  }

  getListRoleSet() {

    let roleSet = this.getRoleSet(), rolesSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.roleArray) {
        if (roleSet[index].permissionJson.roleArray[index2].action) {


          if (roleSet[index].permissionJson.roleArray[index2].action.join(',').indexOf('list') > -1) {
            rolesSet.push(roleSet[index].permissionJson.roleArray[index2]);
          }
        }
      }

    }

    let roleIdSet = [];

    for (const index in rolesSet) {

      roleIdSet.push(rolesSet[index].id);

    }
    return roleIdSet.join(',');

    // return productSet;

  }


  checkGetProductPermission(productid) {

    let roleSet = this.getRoleSet(), productSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.productArray) {
        if (roleSet[index].permissionJson.productArray[index2].action) {


          if (roleSet[index].permissionJson.productArray[index2].action.join(',').indexOf('get') > -1) {
            productSet.push(roleSet[index].permissionJson.productArray[index2]);
          }
        }
      }

    }

    let productIdSet = [];

    for (const index in productSet) {

      productIdSet.push(productSet[index].id);

    }
    if (productIdSet.join(',').indexOf(productid) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkGetPropertyPermission(id) {

    let roleSet = this.getRoleSet(), propertySet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.propertyArray) {
        if (roleSet[index].permissionJson.propertyArray[index2].action) {


          if (roleSet[index].permissionJson.propertyArray[index2].action.join(',').indexOf('get') > -1) {
            propertySet.push(roleSet[index].permissionJson.propertyArray[index2]);
          }
        }
      }

    }

    let propertyIdSet = [];

    for (const index in propertySet) {

      propertyIdSet.push(propertySet[index].id);

    }
    if (propertyIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }




  checkGetRolePermission(id) {

    let roleSet = this.getRoleSet(), rolesSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.roleArray) {
        if (roleSet[index].permissionJson.roleArray[index2].action) {


          if (roleSet[index].permissionJson.roleArray[index2].action.join(',').indexOf('get') > -1) {
            rolesSet.push(roleSet[index].permissionJson.roleArray[index2]);
          }
        }
      }

    }

    let roleIdSet = [];

    for (const index in rolesSet) {

      roleIdSet.push(rolesSet[index].id);

    }
    if (roleIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkGetUserPermission(id) {

    let roleSet = this.getRoleSet(), userSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.userArray) {
        if (roleSet[index].permissionJson.userArray[index2].action) {


          if (roleSet[index].permissionJson.userArray[index2].action.join(',').indexOf('get') > -1) {
            userSet.push(roleSet[index].permissionJson.userArray[index2]);
          }
        }
      }

    }

    let userIdSet = [];

    for (const index in userSet) {

      userIdSet.push(userSet[index].id);

    }
    if (userIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  getCreateProductSet() {

    let roleSet = this.getRoleSet(), productSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.productArray) {
        if (roleSet[index].permissionJson.productArray[index2].action) {


          if (roleSet[index].permissionJson.productArray[index2].action.join(',').indexOf('create') > -1) {
            productSet.push(roleSet[index].permissionJson.productArray[index2]);
          }
        }
      }

    }

    let productIdSet = [];

    for (const index in productSet) {

      productIdSet.push(productSet[index].id);

    }
    return productIdSet;

    // return productSet;

  }

  getCreateProposalSet() {

    let roleSet = this.getRoleSet(), proposalSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.proposalArray) {
        if (roleSet[index].permissionJson.proposalArray[index2].action) {


          if (roleSet[index].permissionJson.proposalArray[index2].action.join(',').indexOf('create') > -1) {
            proposalSet.push(roleSet[index].permissionJson.proposalArray[index2]);
          }
        }
      }

    }

    let proposalIdSet = [];

    for (const index in proposalSet) {

      proposalIdSet.push(proposalSet[index].id);

    }
    return proposalIdSet;

    // return productSet;

  }

  getCreateTenantPermission() {
    let roleSet = this.getRoleSet(), tenantSet = [];

    for (const index in roleSet) {

      if (roleSet[index].permissionModule.Tenants.join(',').indexOf('create') > -1) {

        return true;

      }


    }
    return false
  }

  getCreateTenantSet() {

    let roleSet = this.getRoleSet(), tenantSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.tenantArray) {
        if (roleSet[index].permissionJson.tenantArray[index2].action) {


          if (roleSet[index].permissionJson.tenantArray[index2].action.join(',').indexOf('create') > -1) {
            tenantSet.push(roleSet[index].permissionJson.tenantArray[index2]);
          }
        }
      }

    }

    let tenantIdSet = [];

    for (const index in tenantSet) {

      tenantIdSet.push(tenantSet[index].id);

    }
    return tenantIdSet;

    // return productSet;

  }

  getCreateProspectSet() {

    let roleSet = this.getRoleSet(), prospectSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.prospectArray) {
        if (roleSet[index].permissionJson.prospectArray[index2].action) {


          if (roleSet[index].permissionJson.prospectArray[index2].action.join(',').indexOf('create') > -1) {
            prospectSet.push(roleSet[index].permissionJson.prospectArray[index2]);
          }
        }
      }

    }

    let prospectIdSet = [];

    for (const index in prospectSet) {

      prospectIdSet.push(prospectSet[index].id);

    }
    return prospectIdSet;

    // return productSet;

  }

  getCreateRoleSet() {

    let roleSet = this.getRoleSet(), rolesSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.roleArray) {
        if (roleSet[index].permissionJson.roleArray[index2].action) {


          if (roleSet[index].permissionJson.roleArray[index2].action.join(',').indexOf('create') > -1) {
            rolesSet.push(roleSet[index].permissionJson.roleArray[index2]);
          }
        }
      }

    }

    let roleIdSet = [];

    for (const index in rolesSet) {

      roleIdSet.push(rolesSet[index].id);

    }
    return roleIdSet;

    // return productSet;

  }

  getCreateUser() {

    let roleSet = this.getRoleSet(), userSet = [];

    if (roleSet[0].permissionModule.Users.join(',').indexOf('create') > -1) {
      return true
    }
    else {
      return false;
    }


  }

  getEditUser() {

    let roleSet = this.getRoleSet(), userSet = [];

    if (roleSet[0].permissionModule.Users.join(',').indexOf('update') > -1) {
      return true
    }
    else {
      return false;
    }


  }

  getDeleteUser() {

    let roleSet = this.getRoleSet(), userSet = [];

    if (roleSet[0].permissionModule.Users.join(',').indexOf('delete') > -1) {
      return true
    }
    else {
      return false;
    }


  }


  getManageUser() {

    let roleSet = this.getRoleSet(), userSet = [];

    if (roleSet[0].permissionModule.Users.join(',').indexOf('get') > -1 && roleSet[0].permissionModule.Users.join(',').indexOf('list') > -1 &&
      roleSet[0].permissionModule.Users.join(',').indexOf('create') > -1 &&
      roleSet[0].permissionModule.Users.join(',').indexOf('update') > -1 && roleSet[0].permissionModule.Users.join(',').indexOf('delete') > -1) {
      return true
    }
    else {
      return false;
    }


  }

  getCreatePropertySet() {

    let roleSet = this.getRoleSet(), propertySet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.propertyArray) {
        if (roleSet[index].permissionJson.propertyArray[index2].action) {


          if (roleSet[index].permissionJson.propertyArray[index2].action.join(',').indexOf('create') > -1) {
            propertySet.push(roleSet[index].permissionJson.propertyArray[index2]);
          }
        }
      }

    }

    let propertyIdSet = [];

    for (const index in propertySet) {

      propertyIdSet.push(propertySet[index].id);

    }
    return propertyIdSet;

    // return productSet;

  }


  checkUpdateRolePermission(id) {

    let roleSet = this.getRoleSet(), rolesSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.roleArray) {
        if (roleSet[index].permissionJson.roleArray[index2].action) {


          if (roleSet[index].permissionJson.roleArray[index2].action.join(',').indexOf('update') > -1) {
            rolesSet.push(roleSet[index].permissionJson.roleArray[index2]);
          }
        }
      }

    }

    let roleIdSet = [];

    for (const index in rolesSet) {

      roleIdSet.push(rolesSet[index].id);

    }
    if (roleIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkUpdatePropertyPermission(id) {

    let roleSet = this.getRoleSet(), propertySet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.propertyArray) {
        if (roleSet[index].permissionJson.propertyArray[index2].action) {


          if (roleSet[index].permissionJson.propertyArray[index2].action.join(',').indexOf('update') > -1) {
            propertySet.push(roleSet[index].permissionJson.propertyArray[index2]);
          }
        }
      }

    }

    let propertyIdSet = [];

    for (const index in propertySet) {

      propertyIdSet.push(propertySet[index].id);

    }
    if (propertyIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkUpdateUserPermission(id) {

    let roleSet = this.getRoleSet(), userSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.userArray) {
        if (roleSet[index].permissionJson.userArray[index2].action) {


          if (roleSet[index].permissionJson.userArray[index2].action.join(',').indexOf('update') > -1) {
            userSet.push(roleSet[index].permissionJson.userArray[index2]);
          }
        }
      }

    }

    let userIdSet = [];

    for (const index in userSet) {

      userIdSet.push(userSet[index].id);

    }
    if (userIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkUpdateProductPermission(productid) {

    let roleSet = this.getRoleSet(), productSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.productArray) {
        if (roleSet[index].permissionJson.productArray[index2].action) {


          if (roleSet[index].permissionJson.productArray[index2].action.join(',').indexOf('update') > -1) {
            productSet.push(roleSet[index].permissionJson.productArray[index2]);
          }
        }
      }

    }

    let productIdSet = [];

    for (const index in productSet) {

      productIdSet.push(productSet[index].id);

    }
    if (productIdSet.join(',').indexOf(productid) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkUpdateProspectPermission(id) {

    let roleSet = this.getRoleSet(), prospectSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.prospectArray) {
        if (roleSet[index].permissionJson.prospectArray[index2].action) {


          if (roleSet[index].permissionJson.prospectArray[index2].action.join(',').indexOf('update') > -1) {
            prospectSet.push(roleSet[index].permissionJson.prospectArray[index2]);
          }
        }
      }

    }

    let prospectIdSet = [];

    for (const index in prospectSet) {

      prospectIdSet.push(prospectSet[index].id);

    }
    if (prospectIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkUpdateProposalPermission(id) {

    let roleSet = this.getRoleSet(), proposalSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.proposalArray) {
        if (roleSet[index].permissionJson.proposalArray[index2].action) {


          if (roleSet[index].permissionJson.proposalArray[index2].action.join(',').indexOf('update') > -1) {
            proposalSet.push(roleSet[index].permissionJson.proposalArray[index2]);
          }
        }
      }

    }

    let proposalIdSet = [];

    for (const index in proposalSet) {

      proposalIdSet.push(proposalSet[index].id);

    }
    if (proposalIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }


  checkUpdateTenantPermission(id) {

    let roleSet = this.getRoleSet(), tenantSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.tenantArray) {
        if (roleSet[index].permissionJson.tenantArray[index2].action) {


          if (roleSet[index].permissionJson.tenantArray[index2].action.join(',').indexOf('update') > -1) {
            tenantSet.push(roleSet[index].permissionJson.tenantArray[index2]);
          }
        }
      }

    }

    let tenantIdSet = [];

    for (const index in tenantSet) {

      tenantIdSet.push(tenantSet[index].id);

    }
    if (tenantIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkDeletePropertyPermission(productid) {

    let roleSet = this.getRoleSet(), propertySet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.propertyArray) {
        if (roleSet[index].permissionJson.propertyArray[index2].action) {


          if (roleSet[index].permissionJson.propertyArray[index2].action.join(',').indexOf('delete') > -1) {
            propertySet.push(roleSet[index].permissionJson.propertyArray[index2]);
          }
        }
      }

    }

    let propertyIdSet = [];

    for (const index in propertySet) {

      propertyIdSet.push(propertySet[index].id);

    }
    if (propertyIdSet.join(',').indexOf(productid) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkDeleteProductPermission(productid) {

    let roleSet = this.getRoleSet(), productSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.productArray) {
        if (roleSet[index].permissionJson.productArray[index2].action) {


          if (roleSet[index].permissionJson.productArray[index2].action.join(',').indexOf('delete') > -1) {
            productSet.push(roleSet[index].permissionJson.productArray[index2]);
          }
        }
      }

    }

    let productIdSet = [];

    for (const index in productSet) {

      productIdSet.push(productSet[index].id);

    }
    if (productIdSet.join(',').indexOf(productid) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkDeleteProspectPermission(id) {

    let roleSet = this.getRoleSet(), prospectSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.prospectArray) {
        if (roleSet[index].permissionJson.prospectArray[index2].action) {


          if (roleSet[index].permissionJson.prospectArray[index2].action.join(',').indexOf('delete') > -1) {
            prospectSet.push(roleSet[index].permissionJson.prospectArray[index2]);
          }
        }
      }

    }

    let prospectIdSet = [];

    for (const index in prospectSet) {

      prospectIdSet.push(prospectSet[index].id);

    }
    if (prospectIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }

  checkDeleteTenantPermission(id) {

    let roleSet = this.getRoleSet(), tenantSet = [];

    for (const index in roleSet) {

      //productSet.push.apply(productSet, roleSet[index].permissionJson.productArray);

      for (const index2 in roleSet[index].permissionJson.tenantArray) {
        if (roleSet[index].permissionJson.tenantArray[index2].action) {


          if (roleSet[index].permissionJson.tenantArray[index2].action.join(',').indexOf('delete') > -1) {
            tenantSet.push(roleSet[index].permissionJson.tenantArray[index2]);
          }
        }
      }

    }

    let tenantIdSet = [];

    for (const index in tenantSet) {

      tenantIdSet.push(tenantSet[index].id);

    }
    if (tenantIdSet.join(',').indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }

    // return productSet;

  }



  isAdmin(): boolean {

    if (localStorage.LoginSession) {

      const data = JSON.parse(localStorage.LoginSession);

      return (data.userGroup &&
        (data.userGroup.indexOf('Admin') !== -1));

    }

    return false;

  }

  isSuperAdmin(): boolean {
      if (localStorage.roleSet) {
        const data = JSON.parse(localStorage.roleSet);

        const found = data.some(elem => elem.name === 'SuperAdmin');
        return found;
      }
      return false;
  


  }

  getGroups(): string[] {
    if (localStorage.LoginSession) {
      const data = JSON.parse(localStorage.LoginSession);
      return data.userGroup ? data.userGroup : [];
    }
    return [''];
  }

  getLandLordGroup() {
    return this.systemSetting.allSettings.landlord_group_name;
  }

  getEmailId() {
    if (localStorage.LoginSession) {
      const data = JSON.parse(localStorage.LoginSession);
      return data.userData.email;
    }
    return "";
  }

  isAuthenticated(): boolean {
    // if (localStorage.LoginSession !== undefined) {
    //   const data = JSON.parse(localStorage.LoginSession);
    //   const time1 = new Date(JSON.parse(localStorage.LoginSession).loggedInDate);
    //   const time2 = new Date();
    //   // tslint:disable-next-line: max-line-length
    //   return Number(JSON.parse(localStorage.LoginSession).expiresIn) - (Number(new Date()) - Number(new Date(JSON.parse(localStorage.LoginSession).loggedInDate))) / 1000 > 0;
    // }
    // return false;
    if (localStorage.getItem('loginFlag')) {
      return true;
    }
    else {
      return false;
    }
  }

  isPRMAuthenticated(): boolean {

    if (localStorage.LoginSession) {
      return true;
    }
    else {
      return false;
    }
  }

  isUserAccessToken() {
    if (localStorage.LoginSession) {
      return JSON.parse(localStorage.LoginSession).accessToken;
    }
  }

  checkTokenIsExpiring() {
    if (localStorage.LoginSession !== undefined) {
      const data = JSON.parse(localStorage.LoginSession);

      return Number(JSON.parse(localStorage.LoginSession).expiresIn) - (Number(new Date()) - Number(new Date(JSON.parse(localStorage.LoginSession).loggedInDate))) / 1000 < 120;
    }
    return false;
  }

  checkAuthentication() {
    // debugger;
    if (!this.isAuthenticated()) {
      localStorage.clear();
      // this.router.navigate(['auth']);
    }
  }

  private userImageSubject = new Subject();
  userImage = this.userImageSubject.asObservable();
  setUserImage() {
    this.userImageSubject.next(true);
  }

  createCipher(value) {
      let publicKeyOperator = forge.pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----\n${'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAIrhK/6n4yQZ8pQXfu5w4rAcvAhvriCBcX804+G9DoVZcSxTBvXjB97I3272N7+wAX5qi1jwgoA9xkh89rMVfcMCAwEAAQ=='}\n-----END PUBLIC KEY-----`);
      return forge.util.encode64(publicKeyOperator.encrypt(value, 'RSAES-PKCS1-V1_5'));
  }

  checkPermission(key, type) {
    let rolesSet = this.getPrmRoleSet();
    if (rolesSet && rolesSet.length && rolesSet[0].permissionModule) {
      let permissions = rolesSet[0].permissionModule[key];
      if (permissions && permissions.length) {
        return permissions.findIndex(x => x == type) > -1 ? true : false;
      }
    }
    return false;
  }

  isPrmSuperAdmin(id) {
    let roleData = JSON.parse(localStorage.prmRoleData);
    if (roleData && roleData.id && roleData.id == id) {
      return true;
    }
    return false;
  }

  async getNewPropertySet() {
    let propertySet = [];
    let url = `Secure/User/property/list?userId=${this.getUserId()}&fromAmp=true`;
    const data = await this._http.getAuthData(url).toPromise();
    if (data.data && data.data.length) {
      data.data.map(element => {
        propertySet.push(element.id);
      });
    }
    return propertySet.join();
  }

  async getPRMNewPropertySet() {
    let propertySet = [];
    let url = `property?userId=${this.getUserId()}`;
    const data = await this._http.getAmpData(url).toPromise();
    if (data.data && data.data.length) {
      data.data.map(element => {
        propertySet.push(element.id);
      });
    }
    return propertySet.join();
  }

  getPropertySetData() {
    return JSON.parse(localStorage.getItem('propertySet'));
  }

  getPropertyTimezoneById(id) {
    const propertyData = this.getPropertySetData();
    const selectedProperty = propertyData ? propertyData.find(item => item.id === id) : '';
    return selectedProperty ? selectedProperty.propertyTimezone : '';
  }

  redirectionToAmpTenant() {
    let roleSetData = JSON.parse(localStorage.roleSet);
    let params = this.route.snapshot.queryParams;

    if (roleSetData && roleSetData[0].permissionModule) {
      if (roleSetData[0].permissionModule.AMP) {
        localStorage.setItem('tenantView', 'false');
        if (params['redirectURL']) {
          this.router.navigateByUrl(params['redirectURL']).catch(
            (err) => {
              this.router.navigate(['home']);
            }
          );
        }
      } 
    }
  }

  setTenantViewValue() {
    let roleSetData = JSON.parse(localStorage.roleSet);
    if (roleSetData && roleSetData[0].permissionModule) {
      if (roleSetData[0].permissionModule.AMP) {
        localStorage.setItem('tenantView', 'false');
      }
    }
  }
  

  loginUser(data) {

    localStorage.clear();

    let password = this.createCipher(data.password);
    this.loginPRM(data.email, password).then(async (data: any) => {

      if(data.success) {
        this.dataService.setAllSubjectDefault();
        await this.getAllThemeSettings();
        await this.updateUserRole();
        this.redirectionToAmpTenant();
      } else {
        if (data.errors && data.errors.data.includes('You are unable to login')) {
          this.toastService.showErrorToast(data.errors.data);
        }
        this.router.navigate(['/auth']);
        localStorage.clear();
      }

    });
  }

  updateUserRole() {
    return new Promise((resolve, rej) => {
      const roleSet = this.getRoleSet();
      if (roleSet && roleSet.length) {
        let roleId = roleSet[0].id;
        this.httpPrm.getData(ApiUrls.newRole, {roleId: roleId}).subscribe(res => {
          if (res.data) {
            localStorage.setItem("prmRoleData", JSON.stringify(res.data));
            localStorage.setItem("roleSet", JSON.stringify([res.data]));

            if(res.data.portfolios && res.data.portfolios.length > 0) {
              localStorage.setItem("prmSelectedPortfolioId", res.data.portfolios[0].id);
            }
            if (res.data.portfolios && res.data.portfolios.length > 0 && !localStorage.getItem('selected-portfolio')) {
              localStorage.setItem("selected-portfolio", res.data.portfolios[0].name);
            }

          }
          resolve(true);
        });
      }
    });
  }

  getAllThemeSettings() {
    return new Promise((resolve, rej) => {
      this.systemSetting.getAllSettings().subscribe(res => {
        if (res.data) {
          let data: any;
          data = res.data 
          // const data = JSON.parse(res.data);
          this.systemSetting.allSettings = data;
          this.systemSetting.setSystemSettings(data);
        }
        resolve(true);
      });
    });
  }

  isEntirePortfolioAccess(): boolean {
    if (localStorage.prmRoleData) {
      const data = JSON.parse(localStorage.prmRoleData);
      if(data.isEntirePortfolio) {
        return true;
      }
    }
    return false;
  }
}
