import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-group-builder',
  templateUrl: './group-builder.component.html',
  styleUrls: ['./group-builder.component.scss']
})
export class GroupBuilderComponent implements OnInit {

  constructor(private jsonPreviewService: JsonPreviewServiceService) { }

  newGroupName:string ='';
  repetitiveFlag: boolean= false;
  addMoreLabel:string='';
  groupData: any;
  fieldData: any;
  currentGroupName: string ='';
  currentGroupIndex: number;

  selectedGroupIndex = [];
  selectedFieldIds = [];
  hierarchyLevel=0;
  modalFlag: boolean = false;
  toppings = new FormControl();
  groupId: string = '';
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  selectedGroupUUID = '';
  displayType='inline';
  errMsg: string = '';

  description = '';

  tenantForm: boolean = false;
  @Output() emitNewField = new EventEmitter();
  validatorCount:[{}] = [
   {
    from:'',
    type: '',
    to:''
   }
  ];
  tempJson = {
    "groups": [
      {
        "groupA": [
          {
            "type": "text",
            "uuid": "70a1b298-dc8c-414f-aba8-92fe183c4b30"
          },
          {
            "type": "text",
            "uuid": "70a1b298-dc8c-414f-aba8-92fe183c4b30"
          }
        ]
      },
      {
        "groupB": [
          {
            "type": "text",
            "uuid": "70a1b298-dc8c-414f-aba8-92fe183c4b30"
          },
          {
            "type": "text",
            "uuid": "70a1b298-dc8c-414f-aba8-92fe183c4b30"
          },
          {
            "groups": [
              {
                "groupC": [
                  {
                    "type": "text",
                    "uuid": "70a1b298-dc8c-414f-aba8-92fe183c4b30"
                  }
                ],
                "groupD": "groupA",

              }
                  ]
                  }
              ]
          }
      ]
    };
  ngOnInit(): void {
    this.getGroupData();
    console.log("Me",this.lookup(this.tempJson, 'groups'))
  }
  addMoreValidators(){
    this.validatorCount.push({
        'from':'',
        'type': '',
        'to':''
       });
  }
  removeValidators(i){
    this.validatorCount.splice(i,1);
  }
  getGroupData(){
    this.groupData = this.jsonPreviewService.getAllGroups();
  }
  addNewGroup(){
    if(!this.newGroupName.length){
      this.errMsg = 'Group Name must have some text.';
      return;
    }

    else{
      this.errMsg = '';
    }
    if(this.jsonPreviewService.addNewGroup(this.newGroupName,this.repetitiveFlag,this.displayType,this.tenantForm,this.groupId,this.validatorCount,this.addMoreLabel, this.description)){
      this.getGroupData();
      this.resetGroup();
   }
    else{
      this.errMsg = "Group Name '"+this.newGroupName+"' already exists";
    }



  }
  resetGroup(){
    this.validatorCount = [{
      "from": '',
      "type": '',
      "to": ''
    }]
    this.newGroupName = '';
    this.groupId = '';
    this.description = '';
  }
  deleteGroup(){
    // this.jsonPreviewService.addNewGroup(groupName+Math.floor(Math.random()*100000));
    this.jsonPreviewService.removeGroup(this.selectedGroupUUID);
    this.modalFlag = false;
    this.getGroupData();
  }
  openGroupModal(groupName,groupIndex,levelHierarchy,groupUUID){
    // alert(groupUUID)
    this.selectedGroupUUID = groupUUID;

    if(levelHierarchy > 2){
      return;
    }
    // this.newGroupName = groupName;
    this.hierarchyLevel = levelHierarchy;
    this.currentGroupName = groupName;
    this.currentGroupIndex = groupIndex;

    //clear past group and field selections
    this.selectedFieldIds.length = 0;
    this.selectedGroupIndex.length = 0;
    this.fieldData = this.jsonPreviewService.getAllFields();
    this.groupData = this.jsonPreviewService.getAllGroups();
    console.log(this.fieldData,this.groupData)
    //reset selected groups here
    this.selectedGroupIndex.length = 0;
    this.modalFlag = true;
    // document.getElementById('modal-content').style.display = "block";
  }
  hideModal(){
    // document.getElementById('modal-content').style.display = "none";
    this.modalFlag = false;
  }
  showFormBuilder(){
    this.emitNewField.emit();
  }
  /**
   * Adds Groups for multiple grouping in a group
   */
  addSubGroup(){
    // this.jsonPreviewService.addSubGroupOnTopLevel(this.currentGroupIndex);
  }
  addSubGroupOnTopLevel(){
    if((this.selectedGroupIndex.length !== 0 || this.selectedFieldIds.length !== 0) && this.hierarchyLevel === 1){
      this.jsonPreviewService.addSubGroupOnTopLevel(this.currentGroupIndex,this.currentGroupName,this.selectedGroupIndex,this.selectedGroupIndex.length);
      this.jsonPreviewService.addFieldsOnTopLevel(this.currentGroupIndex,this.currentGroupName,this.selectedFieldIds);
    }
    else if((this.selectedGroupIndex.length !== 0 || this.selectedFieldIds.length !== 0) && this.hierarchyLevel === 2){
      // this.jsonPreviewService.addSubGroupOnSecondLevel(this.currentGroupIndex,this.currentGroupName,this.selectedGroupIndex,this.selectedGroupIndex.length);
      // this.jsonPreviewService.addFieldsOnSecondLevel(this.currentGroupIndex,this.currentGroupName,this.selectedFieldIds);
    }
    else{
      alert('Please select a group or field')
    }
    this.hideModal();
    this.getGroupData();
  }


  selectGroup(changes){
    console.log(changes)
    this.selectedGroupIndex.push(changes);
  }
  selectFields(changes){
    console.log(changes,this.selectedFieldIds)
    this.selectedFieldIds.push(changes);
  }
  getKeyNameforGroup(json){
    let obj = json;
    return Object.keys(obj)[0];
  }
  getFieldLabel(uuid){
    return this.jsonPreviewService.getFieldLabel(uuid);

  }
  addFieldGroup(){
    this.jsonPreviewService.addGroupField(this.selectedFieldIds,this.selectedGroupIndex,this.selectedGroupUUID);
  }
  /**
   *
   * Need to edit the below code of heirarchy structure
   */
  getHierarchyOfGroups(){
    this.lookup(this.tempJson, 'groups')
  }
  getType(object) {
    var stringConstructor = "test".constructor;
    var arrayConstructor = [].constructor;
    var objectConstructor = {}.constructor;

    if (object === null) {
      return "null";
    } else if (object === undefined) {
      return "undefined";
    } else if (object.constructor === stringConstructor) {
      return "String";
    } else if (object.constructor === arrayConstructor) {
      return "Array";
    } else if (object.constructor === objectConstructor) {
      return "Object";
    } else {
      return "null";
    }
  }
  lookup(obj, k) {
    console.log(obj)
    for (var key in obj) {
      var value = obj[key];

      if (k == key) {
        return [k, value];
      }

      if (typeof(value) === "object" && !Array.isArray(value)) {
        var y = this.lookup(value, k);
        if (y && y[0] == k) return y;
      }
      if (Array.isArray(value)) {
        // for..in doesn't work the way you want on arrays in some browsers
        //
        for (var i = 0; i < value.length; ++i) {
          var x = this.lookup(value[i], k);
          if (x && x[0] == k) return x;
        }
      }
    }

    return null;
  }
}
