import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-select-dropdown-field',
  templateUrl: './select-dropdown-field.component.html',
  styleUrls: ['./select-dropdown-field.component.scss', '../form-builder.component.scss']
})
export class SelectDropdownFieldComponent implements OnInit {

  constructor(private jsonPreviewService: JsonPreviewServiceService) { }
  label: string = '';
  inputID: string = '';
  inputName: string = '';
  placeholder: string = '';
  pattern: string = '';
  required: boolean = false;
  newField = {};
  selectValues = '';
  defaultValue = '';
  apiPath: string = '';
  multiple_flag: boolean = false;
  dynamic_flag: boolean = false;
  validationAttribute: string = '';
  autoPopulate: boolean = true;
  @Input() updateUUID;
  @Output() emitNewField = new EventEmitter();
  enable: string = '';
  disable: string = '';
  preFill: string = '';
  disableNameAttr: boolean = false;

  description: string = '';

  prefillSelections = [{
    name: 'None',
    value: ''
  },
  {
    name: 'Stock Set',
    value: 'stockSet'
  },
  {
    name: 'Block Enterprise',
    value: 'blockEnterprise'
  },
  {
    name: 'Select Enterprise',
    value: 'selectedEnterpriseSet'
  },
  {
    name: 'Request Set',
    value: 'requestSet'
  },
  {
    name: 'Property Set',
    value: 'propertySet'
  },
  {
    name: 'Suite',
    value: 'suite'
  }
  ];
  apiTrigger: string = '';
  errorMsg: string = '';
  errorMsg2: string = '';
  validatorCount: [{}] = [
    {
      from: '',
      type: '',
      to: ''
    }
  ];
  ngOnInit(): void {
    if (this.updateUUID) {
      this.populateData(this.updateUUID);
    }
  }

  populateData(uuid) {
    let data = this.jsonPreviewService.getFieldDataFromUUID(uuid);
    console.log(data)
    this.dynamic_flag = data.hasOwnProperty('apiPath');
    if (this.dynamic_flag) {
      this.label = data.label;
      this.inputID = data.id;
      this.inputName = data.name;
      this.placeholder = data.placeholder;
      this.apiPath = data['apiPath'];
      this.apiTrigger = data['apiTrigger'];
      this.multiple_flag = data['multiple'];
      this.required = data.required;
      this.validationAttribute = data['validationAttribute'];
      this.autoPopulate = data['autoPopulate'];
      this.validatorCount = data.validator;
      this.description = data.description;
    }
    else {
      this.selectValues = data['dropdownValue'].toString();
      this.defaultValue = data['defaultValue'];
      this.multiple_flag = data['multiple'];
      this.label = data.label;
      this.inputID = data.id;
      this.inputName = data.name;
      this.placeholder = data.placeholder;
      this.pattern = data.pattern;
      this.required = data.required;
      this.validationAttribute = data['validationAttribute'];
      this.validatorCount = data.validator;
      this.description = data.description;
    }
    if (data['dependencies']) {
      this.enable = data['dependencies']['enable'];
      this.disable = data['dependencies']['disable'];
    }
    let prefillSelections = this.prefillSelections.find(x => x.value == this.inputName);
    if(prefillSelections) {
      this.preFill = prefillSelections.value;
      this.disableNameAttr = true;
    }
  }
  modifyTemplateJSON() {
    if (!this.validateForm()) {
      return;
    }
    if (!this.jsonPreviewService.uniqueIdCheck(this.inputID, this.updateUUID)) {
      this.errorMsg = 'Field id should be unique!';
      return;
    }
    if (!this.jsonPreviewService.uniqueNameCheck(this.inputName, this.updateUUID)) {
      this.errorMsg = 'Field name should be unique!';
      return;
    }
    this.newField = this.dynamic_flag ?
      {
        "label": this.label,
        "type": "select",
        "name": this.inputName,
        "id": this.inputID,
        "placeholder": this.placeholder,
        "apiPath": this.apiPath,
        "apiTrigger": this.apiTrigger,
        "multiple": this.multiple_flag,
        "required": this.required,
        "validationAttribute": this.validationAttribute,
        "autoPopulate": this.autoPopulate,
        "validator": this.validatorCount,
        description: this.description
      }
      :
      {
        "label": this.label,
        "type": "select",
        "name": this.inputName,
        "id": this.inputID,
        "placeholder": this.placeholder,
        "dropdownValue": this.selectValues.split(','),
        "defaultValue": this.defaultValue,
        "multiple": this.multiple_flag,
        "required": this.required,
        "validationAttribute": this.validationAttribute,
        "validator": this.validatorCount,
        description: this.description
      };
    if (this.enable.length && this.disable.length) {
      this.newField['dependencies'] = {
        'enable': this.enable,
        'disable': this.disable
      }
    }
    else {
      if (this.enable.length) {
        this.newField['dependencies'] = {
          'enable': this.enable
        }
      }
      if (this.disable.length) {
        this.newField['dependencies'] = {
          'disable': this.disable
        }
      }
    }

    if (this.updateUUID) {
      //update the JSON >> true parameter handles update differently
      this.jsonPreviewService.updateField(this.updateUUID, this.newField, true);
      this.emitNewField.emit(this.newField);
    }
    else {
      // add into the json
      this.jsonPreviewService.pushTemplateField(this.newField);
      this.emitNewField.emit(this.newField);
    }
  }
  onCancel() {
    this.emitNewField.emit(this.newField);
  }
  validateForm() {
    if (this.inputID.indexOf(' ') >= 0 || this.inputName.indexOf(' ') >= 0) {
      this.errorMsg = 'ID and Name can not contain spaces.';
      return false;
    }
    // this.errorMsg=this.dynamic_flag )
    if (this.dynamic_flag && (this.label.length === 0 || this.inputID.length === 0 || this.inputName.length === 0 || this.apiPath.length === 0)) {
      this.errorMsg = 'Fill all the mandatory fields.';
      return false;
    }
    if (!this.dynamic_flag && (this.label.length === 0 || this.inputID.length === 0 || this.inputName.length === 0 || this.selectValues.length === 0 || this.defaultValue.length === 0)) {
      this.errorMsg = 'Fill all the mandatory fields.';
      return false;
    }
    this.errorMsg = '';
    if (this.errorMsg2 !== '') {
      return false;
    }
    return true;
  }
  onPrefillChange(event) {
    if (event === '') {
      this.inputName = event;
      this.disableNameAttr = false;
    }
    else {
      this.inputName = event;
      this.disableNameAttr = true;
    }
    console.log(event)
  }
  apiPathValidation(value) {
    // console.log(value)
    if (value.indexOf('?') != -1) {
      let allSet = value.match('{{.*}}');
      // console.log(allSet)
      if (allSet != null) {
        if (allSet[0].indexOf('{{}}') != -1) {
          this.errorMsg2 = 'Invalid entry on api path';
        }
        else {
          this.errorMsg2 = '';
        }
      }
      else {
        this.errorMsg2 = '';
      }

    }
  }
  addMoreValidators() {
    this.validatorCount.push({
      'from': '',
      'type': '',
      'to': ''
    });
  }
  removeValidators(i) {
    this.validatorCount.splice(i, 1);
  }
}
