import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-modal-template-detail',
  templateUrl: './modal-template-detail.component.html',
  styleUrls: ['./modal-template-detail.component.scss']
})
export class ModalTemplateDetailComponent implements OnInit {

  templateName='';
  templateCatalog='';
  templateVersion='';
  templateDescription='';

  errorFlag: boolean = false;
  @Input() modalHeader;
  @Input() modalFooter;

  constructor(private jsonPreviewService: JsonPreviewServiceService) { }

  @Output() closeModalMain = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.templateName = this.jsonPreviewService.getTemplateName();
    this.templateCatalog = this.jsonPreviewService.getTemplateCatalog();
    this.templateVersion = this.jsonPreviewService.getTemplateVersion();
    this.templateDescription = this.jsonPreviewService.getTemplateDescrition();
  }

  closeModal(){
    this.closeModalMain.emit(true);
  }
  updateTemplateDetails(){
    if(this.templateName.length === 0 || this.templateCatalog.length === 0 || this.templateVersion.length === 0){
      this.errorFlag = true;
    }
    else{
      this.errorFlag = false;
      this.jsonPreviewService.updateTemplateDetails(this.templateName,this.templateCatalog,this.templateVersion, this.templateDescription);
      this.closeModal();
    }

  }

}
