import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-file-upload-field',
  templateUrl: './file-upload-field.component.html',
  styleUrls: ['./file-upload-field.component.scss','../form-builder.component.scss']
})
export class FileUploadFieldComponent implements OnInit {

  constructor(private jsonPreviewService: JsonPreviewServiceService) { }
  label:string ='';
  inputID:string = '';
  inputName:string = '';
  value:string ='';
  accept:string ='';
  required:boolean = false;
  newField = {};
  multiselect: boolean = false;
  validationAttribute: string = '';
  enable:string ='';
  disable:string ='';
  errorMsg:string = '';
  description = '';
  validatorCount:[{}] = [
    {
     from:'',
     type: '',
     to:''
    }
   ];
  @Input() updateUUID;
  @Output() emitNewField = new EventEmitter();

  ngOnInit(): void {
    if(this.updateUUID){
      this.populateData(this.updateUUID);
    }
  }

  populateData(uuid){
    let data = this.jsonPreviewService.getFieldDataFromUUID(uuid);
    console.log(data)
    this.label = data.label;
    this.inputID = data.id;
    this.inputName = data.name;
    this.accept = data['accept'];
    this.multiselect = data['multiselect'];
    this.required = data.required;
    this.validationAttribute = data['validationAttribute'];
    this.validatorCount = data.validator;
    this.description = data.description;
    if(data['dependencies']){
      this.enable = data['dependencies']['enable'];
      this.disable = data['dependencies']['disable'];
    }
  }


  modifyTemplateJSON(){
    if(!this.validateForm()){
      return;
    }
    if (!this.jsonPreviewService.uniqueIdCheck(this.inputID,this.updateUUID)){
      this.errorMsg='Field id should be unique!';
      return;
    }
    if (!this.jsonPreviewService.uniqueNameCheck(this.inputName,this.updateUUID)){
      this.errorMsg='Field name should be unique!';
      return;
    }
    this.newField = {
           "label": this.label,
           "type": "file",
           "id": this.inputID,
           "name": this.inputName,
           "accept": this.accept,
           "multiselect": this.multiselect,
           "required": this.required,
           "validationAttribute": this.validationAttribute,
           "validator": this.validatorCount,
           description: this.description
      };
      if(this.enable.length && this.disable.length){
        this.newField['dependencies'] = {
          'enable' : this.enable,
          'disable': this.disable
        }
      }
      else
      {
        if(this.enable.length){
          this.newField['dependencies'] = {
            'enable' : this.enable
          }
        }
        if(this.disable.length){
          this.newField['dependencies'] = {
            'disable' : this.disable
          }
        }
    }

      if(this.updateUUID){
        //update the JSON
        this.jsonPreviewService.updateField(this.updateUUID,this.newField);
        this.emitNewField.emit(this.newField);

      }
      else{
        // add into the json
          this.jsonPreviewService.pushTemplateField(this.newField);
          this.emitNewField.emit(this.newField);
      }
  }
  onCancel(){
    this.emitNewField.emit(this.newField);
  }
  validateForm(){
    if(this.inputID.indexOf(' ') >= 0 || this.inputName.indexOf(' ') >= 0){
      this.errorMsg='ID and Name can not contain spaces.';
      return false;
    }
    if(this.label.length === 0 || this.inputID.length === 0 || this.inputName.length === 0 || this.accept.length === 0){
      this.errorMsg='Fill all the mandatory fields.';
      return false;
    }
      return true;
  }
  addMoreValidators(){
    this.validatorCount.push({
        'from':'',
        'type': '',
        'to':''
       });
  }
  removeValidators(i){
    this.validatorCount.splice(i,1);
  }
}
