import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';
import { MatDialogComponent } from './mat-dialog/mat-dialog.component';

@Component({
  selector: 'app-json-template-viewer',
  templateUrl: './json-template-viewer.component.html',
  styleUrls: ['./json-template-viewer.component.scss']
})
export class JsonTemplateViewerComponent implements OnInit {
  @Input() completeData;

  data = {
    "fields": [
       {
         "class": ["col-md-2"],
         "type": "label",
         "value": "Floor *"
       },
       {
         "class": ["col-md-8"],
         "type": "text",
         "id": "floor",
         "name": "floor",
         "placeholder": "#"
       },
       {
         "class": ["col-md-2"],
         "type": "label",
         "value": ""
       }
     ]
   };

  constructor(public dialog: MatDialog, private jsonPreviewService: JsonPreviewServiceService) {
    // this.completeData['rows'].push(this.data);
  }

  ngOnInit(): void {
  }

  copyToClipboard(){
    var copyText = <HTMLInputElement> document.getElementById("view_id");
      copyText.select();
      copyText.setSelectionRange(0, 99999)
      document.execCommand("copy");
  }
  expandViewer() {
    const dialogRef = this.dialog.open(MatDialogComponent,{
      data: {
       "viewJSON" : this.completeData
      },
      height: '500px',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog closed: ${result}`);
    });
  }
  resetTemplate(){
    this.jsonPreviewService.resetTemplate();
  }
}
