<h1>
  Label Field
  <!-- <span class="float-right">
    Required <mat-slide-toggle [checked]="required" (change)="required = !required">
    </mat-slide-toggle>
  </span> -->
</h1>
<div class="container-fluid">
  <!-- label with name -->
  <div class="form-group">
    <input class="form-control" id="label" type="text" placeholder="Label" [(ngModel)]="label" />
    <div class="form-label float-left">example: What is your first name?</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <!-- <<<<<< main input component -->
  <div class="form-group">
    <input class="form-control" id="input_Id" type="text" placeholder="ID" [(ngModel)]="inputID" value="inputID" />
    <div class="form-label float-left">example: firstName</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <div class="form-group">
    <select name="labelType" id="labelType" [(ngModel)]="labelType" class="form-control">
      <option value="default">Default</option>
      <option value="measurement">Measurement</option>
      <option value="currency">Currency</option>
    </select>
    <div class="form-label float-left">default selection: Default</div>
    <!-- <div class="form-label float-right" style="font-weight: 600;">Field required</div> -->
  </div>
  <div class="form-group">
    <input class="form-control" id="hierarchy" type="text" placeholder="header.." [(ngModel)]="hierarchy" />
    <div class="form-label float-left">example: header/sub-header</div>
  </div>

  <div class="form-group">
    <input class="form-control" id="description" type="text" placeholder="Description" [(ngModel)]="description"
      value="{{description}}" maxlength="1000" />
    <div class="form-label float-left">example: You can explain more about what this field will be used for.</div>
  </div>

  <div class="form-group" *ngFor="let count of validatorCount;index as i">
    <div class="col-md-1" *ngIf="i == 0">
      <button class="btn btn-sm" (click)="addMoreValidators()">
        +
      </button>
    </div>
    <div class="col-md">Add Validator</div>
    <div class="col-md">
      <input type="text" name="fromUUID" id="fromUUID" [(ngModel)]="count['from']" placeholder="From"
        class="form-control" />
    </div>
    <div class="col-md">
      <select id="typeOfComparision" name="typeOfComparision" [(ngModel)]="count['type']" class="form-control">
        <option value="" selected>Select comparision</option>
        <option value="lessThan">
          << /option>
        <option value="greaterThan">></option>
        <option value="notEqualsTo">!==</option>
        <option value="equalsTo">===</option>
      </select>
    </div>
    <div class="col-md">
      <input type="text" name="toUUID" id="toUUID" [(ngModel)]="count['to']" placeholder="To" class="form-control" />
    </div>
    <div class="col-md-1" *ngIf="i != 0">
      <button class="btn btn-sm" (click)="removeValidators(i)">
        -
      </button>
    </div>
  </div>
  <div class="text-danger">
    <span class="p-3 fw-600">
      {{errorMsg}}
    </span>
  </div>
  <div class="form-group">
    <button class="btn float-left" (click)="onCancel()">Cancel</button>
    <button class="btn float-right" (click)="modifyTemplateJSON()">Save & Update</button>
  </div>

</div>