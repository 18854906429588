  <!--<<<<<<----- main input component-->
  <ng-template [ngIf]="!customViewFlag">
    <app-input-checkbox-field *ngIf="elementType === 'checkbox'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-input-checkbox-field>
    <app-input-text-field *ngIf="elementType === 'text'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-input-text-field>
    <app-input-number-field *ngIf="elementType === 'number'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-input-number-field>
    <app-text-area-field *ngIf="elementType === 'textarea'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-text-area-field>
    <app-input-email-field *ngIf="elementType === 'email'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-input-email-field>
    <app-file-upload-field *ngIf="elementType === 'file'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-file-upload-field>
    <app-input-date-field *ngIf="elementType === 'date'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-input-date-field>
    <app-input-time-field *ngIf="elementType === 'time'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-input-time-field>
    <app-input-radio-button *ngIf="elementType === 'radio'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-input-radio-button>
    <app-label-field *ngIf="elementType === 'label'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-label-field>
    <app-select-dropdown-field *ngIf="elementType === 'select'" (emitNewField)="showCustom()" [updateUUID]="currentUUID"></app-select-dropdown-field>
    <app-group-builder *ngIf="elementType === 'group'" (emitNewField)="showCustom()"></app-group-builder>
    </ng-template>
  <!-- main input component >>>>>>-->

  <!-- empty label for space -->
<!-- <div class="container" *ngIf="!completeJSON.length">
  <div class="no-field-selected fw-600">
    No fields added
  </div>
</div> -->
<app-field-customization *ngIf="customViewFlag" (emitEditField)="editForm($event)"></app-field-customization>
