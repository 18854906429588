import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'printError'
})
export class PrintErrorPipe implements PipeTransform {

  transform(form: FormGroup, control: string, displayText: string, fieldType?: string): any {
    if (form.controls[control].hasError('required') ||
      form.controls[control].hasError('isEmpty')) {
      return `${displayText} is required`;
    } else if (form.controls[control].hasError('pattern') ||
      form.controls[control].hasError('email')) {
      return `${displayText} is invalid`;
    } else if (form.controls[control].hasError('invalidEmail') ||
      form.controls[control].hasError('required')) {
      return `${displayText} is invalid`;
    } else if (form.controls[control].hasError('minlength')) {
      return `${displayText} must be at least ${form.controls[control].errors.minlength.requiredLength} characters long`;
    } else if (form.controls[control].hasError('maxlength')) {
      return `${displayText} can be max ${form.controls[control].errors.maxlength.requiredLength} characters long.`;
    } else if (form.controls[control].hasError('invalid-booking-date')){
      return `${displayText} not allowed on selected date`;
    }
  }


}
