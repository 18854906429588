export const ApiUrls = Object.freeze({
    PRODUCT: 'product/',
    SYSTEM_SETTINGS: {
      PROPERTYSETTING: 'propertysetting/getamp',
      ALL: 'setting/get',
      SAVE: 'setting/save',
      AMP_LOGO: 'setting/getamplogo',
      RXR_LOGO: 'setting/getrxrlogo',
      THEME: 'setting/getrxrtheme',
      APPS: 'setting/getapplink'
    },
    USER: {
      DETAIL: 'Secure/GetUserInfo/',
      AMP: 'Secure/User/'
    },
    FILE_URL: {
      ALL_FILES: 'image-upload/api/files/lists3files'
    }
  });
  