import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-label-field',
  templateUrl: './label-field.component.html',
  styleUrls: ['./label-field.component.scss', '../form-builder.component.scss']
})
export class LabelFieldComponent implements OnInit {


  constructor(private jsonPreviewService: JsonPreviewServiceService) { }

  label: string = '';
  inputID: string = '';
  newField = {};
  // required: boolean = false;
  labelType = 'default';
  errorMsg: string = '';
  hierarchy = 'default';

  description = '';
  validatorCount: [{}] = [
    {
      from: '',
      type: '',
      to: ''
    }
  ];
  @Input() updateUUID;
  @Output() emitNewField = new EventEmitter();

  ngOnInit(): void {
    this.validatorCount = [{}];
    if (this.updateUUID) {
      this.populateData(this.updateUUID);
    }
  }
  populateData(uuid) {
    let data = this.jsonPreviewService.getFieldDataFromUUID(uuid);
    console.log(data)
    this.label = data.label;
    this.inputID = data.id;
    this.hierarchy = data.hierarchy;
    this.validatorCount = data.validator;
    this.description = data.description;

  }
  validateForm() {

    if (this.label.length === 0 || this.inputID.length === 0) {
      this.errorMsg = 'Fill all the mandatory fields.';
      return false;
    }
    return true;
  }
  modifyTemplateJSON() {
    if (!this.validateForm()) {
      return;
    }
    if (!this.jsonPreviewService.uniqueIdCheck(this.inputID, this.updateUUID)) {
      this.errorMsg = 'Field id should be unique!';
      return;
    }

    this.newField = {
      "label": this.label,
      "type": "label",
      "id": this.inputID,
      "labelType": this.labelType,
      "hierarchy": this.hierarchy,
      "validator": this.validatorCount,
      description: this.description
    };
    if (this.updateUUID) {
      //update the JSON
      this.jsonPreviewService.updateField(this.updateUUID, this.newField);
      this.emitNewField.emit(this.newField);

    }
    else {
      // add into the json
      this.jsonPreviewService.pushTemplateField(this.newField);
      this.emitNewField.emit(this.newField);
    }
  }
  onCancel() {
    this.emitNewField.emit(this.newField);
  }
  addMoreValidators() {
    this.validatorCount.push({
      'from': '',
      'type': '',
      'to': ''
    });
  }
  removeValidators(i) {
    this.validatorCount.splice(i, 1);
  }

}
