<div class="modal-container">
  <div class="modal-content">
    <div class="container-fluid p-4">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md-1" (click)="closeModal()">
          <i class="fa fa-close"></i>
        </div>
      </div>
      <div class="modal-head">{{modalHeader}}</div>
      <div class="form-group">
        <label class="form-label">
          Template name *
        </label>
        <input class="form-control" type="text" placeholder="" [(ngModel)]="templateName" maxLength="50" />
      </div>
      <div class="form-group">
        <label class="form-label">
          Category *
        </label>
        <input class="form-control" type="text" placeholder="" [(ngModel)]="templateCatalog" maxlength="50" />
      </div>
      <div class="form-group">
        <label>
          Version Number *
        </label>
        <input class="form-control" type="text" placeholder="" [(ngModel)]="templateVersion" digitOnly
          [decimal]="true" />
      </div>

      <div class="form-group">
        <label>
          Version Description *
        </label>
        <textarea class="form-control" type="text" placeholder="" [(ngModel)]="templateDescription" maxlength="1000" rows="3"></textarea>
      </div>

      <div class="mt-5" style="height: 16px;">
        <span class="text-danger" *ngIf="errorFlag">
          *All fields are mandatory
        </span>
      </div>
      <hr class="mt-1">
      <div class="form-group">
        <button class="save-btn" (click)="updateTemplateDetails()">{{modalFooter}}</button>
      </div>
    </div>
  </div>
</div>