import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  readonly duration = 2000;

  constructor(private snack: MatSnackBar) {
  }

  showToast(msg) {
    this.snack.open(msg, '', { 
      duration: this.duration, verticalPosition: 'top',
      horizontalPosition: 'right', panelClass: 'toast-prm'
    });
  }

  showSuccessToast(msg) {
    this.snack.open(msg, '', {
      duration: this.duration, verticalPosition: 'top',
      horizontalPosition: 'right', panelClass: 'toast-success-prm'
    });
  }

  showErrorToast(msg) {
    this.snack.open(msg, '', {
      duration: this.duration, verticalPosition: 'top',
      horizontalPosition: 'right', panelClass:  'toast-error-prm'
    });
  }
}
