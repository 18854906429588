<h1>
  Checkbox
  <span class="float-right">
    Required <mat-slide-toggle [checked]="required" (change)="required = !required">
    </mat-slide-toggle>
  </span>
</h1>
<div class="container-fluid">
  <!-- label with name -->
  <div class="form-group">
    <input class="form-control" id="label" type="text" placeholder="Label" [(ngModel)]="label" />
    <div class="form-label float-left">example: What is your first name?</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>

  <div class="form-group">
    <input class="form-control" id="input_Name" type="text" placeholder="Name" [(ngModel)]="inputName" value="inputName" />
    <div class="form-label float-left">example: firstName</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>

  <!-- <<<<<< main input component -->
  <div class="form-group">
    <input class="form-control" id="input_Id" type="text" placeholder="ID" [(ngModel)]="inputID" value="inputID" />
    <div class="form-label float-left">example: firstName</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>

  <div class="form-group">
    <input class="form-control" id="description" type="text" placeholder="Description" [(ngModel)]="description"
      value="{{description}}" maxlength="1000" />
    <div class="form-label float-left">example: You can explain more about what this field will be used for.</div>
  </div>

  <div class="text-danger">
    <span class="p-3 fw-600">
      {{errorMsg}}
    </span>
  </div>
  <div class="form-group">
    <button class="btn float-left" (click)="onCancel()">Cancel</button>
    <button class="btn float-right" (click)="modifyTemplateJSON()">Save & Update</button>
  </div>

</div>