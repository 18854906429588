import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-input-number-field',
  templateUrl: './input-number-field.component.html',
  styleUrls: ['./input-number-field.component.scss', '../form-builder.component.scss']
})
export class InputNumberFieldComponent implements OnInit {

  constructor(private jsonPreviewService: JsonPreviewServiceService) { }
  label: string = '';
  inputID: string = '';
  inputName: string = '';
  placeholder: string = '';
  minCharacter: string = '';
  maxCharacter: string = '';
  pattern: string = '';
  required: boolean = false;
  newField = {};
  prefix: string = '';
  suffix: string = '';
  validationAttribute: string = '';
  typeOfMeasurement: string = '';
  enable: string = '';
  disable: string = '';
  errorMsg: string = '';
  defaultValue: string = '';
  description = '';
  validatorCount: [{}] = [
    {
      from: '',
      type: '',
      to: ''
    }
  ];
  @Input() updateUUID;
  @Output() emitNewField = new EventEmitter();


  ngOnInit(): void {
    if (this.updateUUID) {
      this.populateData(this.updateUUID);
    }
  }

  populateData(uuid) {
    let data = this.jsonPreviewService.getFieldDataFromUUID(uuid);
    this.label = data.label;
    this.inputID = data.id;
    this.inputName = data.name;
    this.placeholder = data.placeholder;
    this.pattern = data.pattern;
    this.maxCharacter += data.maxLength;
    this.minCharacter += data.minLength;
    this.required = data.required;
    this.suffix = data.suffix;
    this.prefix = data.prefix;
    this.validationAttribute = data['validationAttribute'];
    this.defaultValue = data.value;
    this.validatorCount = data['validator'];
    this.description = data.description;
    if (data.hasOwnProperty('measurement')) {
      this.typeOfMeasurement = data['measurement'].type;
    }
    else {
      this.typeOfMeasurement = '';
    }
    console.log(data)
    if (data['dependencies']) {
      this.enable = data['dependencies']['enable'];
      this.disable = data['dependencies']['disable'];
    }
  }

  modifyTemplateJSON() {
    if (!this.validateForm()) {
      return;
    }

    if (!this.jsonPreviewService.uniqueIdCheck(this.inputID, this.updateUUID)) {
      this.errorMsg = 'Field id should be unique!';
      return;
    }
    if (!this.jsonPreviewService.uniqueNameCheck(this.inputName, this.updateUUID)) {
      this.errorMsg = 'Field name should be unique!';
      return;
    }
    //Removed measurement key if set to none || ''
    if (this.typeOfMeasurement === '') {
      this.newField = {
        "label": this.label,
        "type": "number",
        "id": this.inputID,
        "name": this.inputName,
        "placeholder": this.placeholder,
        "pattern": this.pattern,
        "maxLength": (this.maxCharacter || this.maxCharacter == '0') ? +this.maxCharacter : '',
      "minLength": (this.minCharacter || this.minCharacter == '0') ? +this.minCharacter : '',
        "required": this.required,
        "suffix": this.suffix,
        "prefix": this.prefix,
        "validationAttribute": this.validationAttribute,
        "value": this.defaultValue,
        "validator": this.validatorCount,
        description: this.description
      };
      if (this.enable.length && this.disable.length) {
        this.newField['dependencies'] = {
          'enable': this.enable,
          'disable': this.disable
        }
      }
      else {
        if (this.enable.length) {
          this.newField['dependencies'] = {
            'enable': this.enable
          }
        }
        if (this.disable.length) {
          this.newField['dependencies'] = {
            'disable': this.disable
          }
        }
      }
    }
    else {
      this.newField = {
        "label": this.label,
        "type": "number",
        "id": this.inputID,
        "name": this.inputName,
        "placeholder": this.placeholder,
        "pattern": this.pattern,
        "maxLength": (this.maxCharacter || this.maxCharacter == '0') ? +this.maxCharacter : '',
        "minLength": (this.minCharacter || this.minCharacter == '0') ? +this.minCharacter : '',
        "required": this.required,
        "suffix": this.suffix,
        "prefix": this.prefix,
        "validationAttribute": this.validationAttribute,
        "value": this.defaultValue,
        "validator": this.validatorCount,
        "measurement": {
          "type": this.typeOfMeasurement
        },
        description: this.description
      };
      if (this.enable.length && this.disable.length) {
        this.newField['dependencies'] = {
          'enable': this.enable,
          'disable': this.disable
        }
      }
      else {
        if (this.enable.length) {
          this.newField['dependencies'] = {
            'enable': this.enable
          }
        }
        if (this.disable.length) {
          this.newField['dependencies'] = {
            'disable': this.disable
          }
        }
      }

    }
    if (this.updateUUID) {
      //update the JSON
      this.jsonPreviewService.updateField(this.updateUUID, this.newField);
      this.emitNewField.emit(this.newField);

    }
    else {
      // add into the json
      this.jsonPreviewService.pushTemplateField(this.newField);
      this.emitNewField.emit(this.newField);
    }
  }
  onCancel() {
    this.emitNewField.emit(this.newField);
  }

  validateForm() {
    if (this.inputID.indexOf(' ') >= 0 || this.inputName.indexOf(' ') >= 0) {
      this.errorMsg = 'ID and Name can not contain spaces.';
      return false;
    }
    if (this.label.length === 0 || this.inputID.length === 0 || this.inputName.length === 0) {
      this.errorMsg = 'Fill all the mandatory fields.';
      return false;
    }
    if (this.pattern.length !== 0 && (this.pattern[0] !== '/' || this.pattern[this.pattern.length - 1] !== '/')) {
      this.errorMsg = 'Pattern is not valid.';
      return false;
    }
    return true;
  }

  addMoreValidators() {
    this.validatorCount.push({
      'from': '',
      'type': '',
      'to': ''
    });
  }
  removeValidators(i) {
    this.validatorCount.splice(i, 1);
  }
}
