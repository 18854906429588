
<div class="container-fluid p-4">
  <app-header (openModalMain)="openTemplateDetailsModal($event)"></app-header>
  <div class="row">
    <div class="col-md-2">
      <app-field-component (emitFormType)="formChanged($event);formBuilder.hideCustom()"></app-field-component>
    </div>
    <div class="col-md-6">
      <app-form-builder #formBuilder [elementType]="elementType" ></app-form-builder>
    </div>
    <div class="col-md-4">
      <app-json-template-viewer [completeData]="completeData"></app-json-template-viewer>
    </div>
  </div>
</div>
<app-modal-template-detail *ngIf="templateModalFlag"
(closeModalMain)="closeTemplateDetailsModal($event)" [modalHeader]="modalHeader" [modalFooter]="modalFooter"
></app-modal-template-detail>
