import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})

export class JsonPreviewServiceService {

  constructor(private commonService: CommonService) { }

  //groupFormFields are ket for now to maintain the list of fields availabl for group
  private JSONTemplate = {
    "template_id": this.commonService.UUIDGenerator(),
    "template_name": "",
    "template_catalog": "",
    "template_version": "",
    "template_description": "",
    "fields": {
      "formFields": [
        // {
        //   "type": "number",
        //   "uuid": "50411a67-aff1-472b-8481-586d1fa2f9b1"
        // }
      ]
    },
    // "groups" : [],
    "validations": [
      // {
      //   "label": "Dummy text field",
      //   "type": "text",
      //   "uuid": "7e789609-27bf-4bb4-8d16-01e0b4a4a0c2",
      //   "id": "Dummy",
      //   "name": "Dummy",
      //   "placeholder": "Dummy",
      //   "pattern": "",
      //   "maxLength": 0,
      //   "minLength": 0,
      //   "required": false,
      //   "prefix" : "Start text",
      //   "suffix" : "End text",
      //   "value": ""
      // }
    ]
  };

  /**
   *
   * Template func() - fetch, push, modify, reset Template JSON
   *
   */

  getTemplate() {
    return this.JSONTemplate;
  }

  pushTemplateField(field) {
    let UUID = this.commonService.UUIDGenerator();
    let formField = {
      "type": field.type,
      "uuid": UUID
    }
    let validationField = field;
    validationField["uuid"] = UUID;
    this.JSONTemplate["fields"]["formFields"].push(formField);
    this.JSONTemplate["validations"].push(validationField);
    console.log(field)
  }

  /**
   *
   * Getter Setter for Template Description
   *
   */

  getTemplateName() {
    return this.JSONTemplate["template_name"];
  }
  getTemplateCatalog() {
    return this.JSONTemplate["template_catalog"];
  }
  getTemplateVersion() {
    return this.JSONTemplate["template_version"];
  }
  getTemplateDescrition() {
    return this.JSONTemplate["template_description"];
  }

  updateTemplateDetails(tName, tCatalog, tVersion, tDescription) {
    this.JSONTemplate["template_name"] = tName.trim();
    this.JSONTemplate["template_catalog"] = tCatalog.trim();
    this.JSONTemplate["template_version"] = tVersion;
    this.JSONTemplate["template_description"] = tDescription;
  }

  /**
   *
   * Resets JSON Template - fields + validation + groups key
   *
   */
  resetTemplate() {
    this.JSONTemplate["fields"]["formFields"].length = 0;
    this.JSONTemplate["validations"].length = 0;
    this.JSONTemplate["groups"].length = 0;
  }

  /** */
  getAllFields() {
    // return this.JSONTemplate["fields"]["formFields"].concat(this.JSONTemplate["groupsFormFields"]);
    let allField = [];
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index]['type'] !== 'group') {
        allField.push(this.JSONTemplate["validations"][index])
      }

    }
    return allField;
  }
  /**
   *
   * Change required value (boolean) of a field directly from form builder UI
   * @param uuid
   * @param flagToBeSet
   */
  changeRequireFlag(uuid, flagToBeSet) {
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index].uuid === uuid) {
        this.JSONTemplate["validations"][index]['required'] = flagToBeSet;
      }
    }
  }
  /**
   *
   * Remove a field using its uuid
   * @param uuid
   */
  removeField(uuid) {
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index].uuid === uuid) {
        // this.JSONTemplate["validations"][index].pop();
        this.JSONTemplate["validations"].splice(index, 1);
      }

    }
    for (let index = 0; index < this.JSONTemplate["fields"]["formFields"].length; index++) {
      if (this.JSONTemplate["fields"]["formFields"][index].uuid === uuid) {
        this.JSONTemplate["fields"]["formFields"].splice(index, 1);
      }
    }
  }
  /**
   * Duplicate a field using it's uuid
   * New field's ID gets append to miliseconds to make it unique in the JSON
   * @param uuid
   */
  replicateField(uuid) {
    let newField;
    for (let index = 0; index < this.JSONTemplate["fields"]["formFields"].length; index++) {
      if (this.JSONTemplate["fields"]["formFields"][index].uuid === uuid) {
        // newField =  Object.assign({}, this.JSONTemplate["validations"][index]);
        newField = JSON.parse(JSON.stringify(this.JSONTemplate["validations"][index]));
        console.log("check me !!", newField)
        let UUID = this.commonService.UUIDGenerator();
        let formField = {
          "type": newField.type,
          "uuid": UUID
        }
        let validationField = newField;
        validationField["uuid"] = UUID;
        let timeInMiliseconds = new Date().getTime();
        validationField["id"] = validationField["id"] + timeInMiliseconds;
        
        if (validationField["name"]) {
          validationField["name"] = validationField["name"] + timeInMiliseconds;
        }
        this.JSONTemplate["fields"]["formFields"].push(formField);
        this.JSONTemplate["validations"].push(validationField);
      }
    }
  }
  /**
   *
   * Update field values with data
   * Select dropdown field handled separately
   *
   * @param uuid
   * @param data
   * @param selectFieldFlag
   */
  updateField(uuid, data, selectFieldFlag = false) {

    if (selectFieldFlag) {
      /**
       *
       * Updates select dropdown field
       *
       */

      for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
        if (this.JSONTemplate["validations"][index].uuid === uuid) {
          for (var key in this.JSONTemplate["validations"][index]) {
            if (key == 'id' || key == 'uuid') {
              continue;
            }
            delete this.JSONTemplate["validations"][index][key];
          }

        }
      }

      for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
        if (this.JSONTemplate["validations"][index].uuid === uuid) {
          for (var key in data) {
            this.JSONTemplate["validations"][index][key] = data[key];
          }

        }
      }
    }
    else {
      /**
       *
       * Updates rest of the fields other than Select dropdown
       *
       */
      for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
        if (this.JSONTemplate["validations"][index].uuid === uuid) {
          /**
           * Removes all keys other than id and uuid
           */
          alert(uuid)
          for (var key in this.JSONTemplate["validations"][index]) {
            if (key == 'id' || key == 'uuid') {
              continue;
            }
            delete this.JSONTemplate["validations"][index][key];
          }

          for (var key in data) {
            // if (this.JSONTemplate["validations"][index].hasOwnProperty(key)) {
            //   this.JSONTemplate["validations"][index][key] = data[key];
            //   }
            this.JSONTemplate["validations"][index][key] = data[key];
          }
        }
      }
    }
  }

  /*
   *
   * Get data from UUID for populating on update field form
   *
   */
  getFieldDataFromUUID(uuid) {
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index].uuid === uuid) {
        return this.JSONTemplate["validations"][index];
      }
    }
  }

  /*
   *
   * Id Validator for field - Must be kept unique always
   *
   */

  uniqueIdCheck(fieldID, fieldUUID) {
    for (let singleField of this.JSONTemplate["validations"]) {
      if (singleField['id'] === fieldID && singleField['uuid'] !== fieldUUID) {
        return false;
      }
    }
    return true;
  }

  /*
   *
   * Name Validator for field - Must be kept unique always
   *
   */

  uniqueNameCheck(fieldName, fieldUUID) {
    for (let singleField of this.JSONTemplate["validations"]) {
      if (singleField['name'] === fieldName && singleField['uuid'] !== fieldUUID) {
        return false;
      }
    }
    return true;
  }

  /*
   *
   * Group func() for add,modify,delete
   *
   */
  modifyGroup(json) {
    if (this.JSONTemplate.hasOwnProperty("groups")) {
      this.JSONTemplate["groups"] = [];
      this.JSONTemplate["groups"].push(json);
    }
    else {
      this.JSONTemplate["groups"] = json;
    }
  }
  /**
   *
   * Adds a new group on top level of "Groups" key
   * @param groupName
   */
  addNewGroup(groupName, repetetiveFlag, display, tenantForm, groupId, validator, addMoreLabel, description) {
    let repiitiveBool = Boolean(repetetiveFlag);  // == true
    let tenantFormBool = Boolean(tenantForm)
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index]['type'] === 'group') {
        if (this.JSONTemplate["validations"][index]['name'] === groupName) {
          return false;
        }
      }


    }

    let newGroup: any = {};
    newGroup = {
      'formFields': [],
      'type': 'group',
      'uuid': this.commonService.UUIDGenerator(),
      'name': groupName,
      'repetitive': repiitiveBool,
      'tenantForm': tenantFormBool,
      'display': display,
      'id': groupId,
      'validator': validator,
      description: description
    };
    if (repetetiveFlag) {
      newGroup['addMoreLabel'] = addMoreLabel;
    }
    let newGroupFormField: any = {};
    newGroupFormField = {
      'type': 'group',
      'uuid': newGroup['uuid'],
    };
    this.JSONTemplate['fields']['formFields'].push(newGroupFormField);
    this.JSONTemplate['validations'].push(newGroup);
    // this.JSONTemplate['validations']['name']= groupName;
    return true;
  }
  /**
   *
   * Removes group from validations and formfields
   * @param groupId
   */
  removeGroup(groupId) {
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index]["uuid"] === groupId) {
        this.JSONTemplate["validations"].splice(index, 1);
      }
    }
    for (let index = 0; index < this.JSONTemplate["fields"]["formFields"].length; index++) {
      if (this.JSONTemplate["fields"]["formFields"][index]["uuid"] === groupId) {
        this.JSONTemplate["fields"]["formFields"].splice(index, 1);
      }
    }
  }
  /**
   *
   * Returns "Groups" key JSON for display purpose
   *
   */
  getAllGroups() {
    // return this.JSONTemplate["groups"];

    let allGroups = [];
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index]['type'] === 'group') {
        allGroups.push(this.JSONTemplate["validations"][index])
      }
    }
    return allGroups;
  }

  /*
   *
   * Unused Code Below
   *
   */

  addSubGroup(groupName) {
    // this.JSONTemplate["groups"].push();
  }
  getFieldType(uuid) {
    for (let index = 0; index < this.JSONTemplate['validations'].length; index++) {
      if (this.JSONTemplate['validations'][index].uuid === uuid) {
        return this.JSONTemplate['validations'][index].type;
      }
    }
    return null;
  }
  addFieldsOnTopLevel(currentGroupIndex, groupName, selectedFields) {
    console.log('success');
    for (let fieldUUID of selectedFields) {
      this.JSONTemplate['groups'][currentGroupIndex][groupName].push({
        "type": this.getFieldType(fieldUUID),
        "uuid": fieldUUID
      })
    }
    console.log("completed");
  }
  /**
   *
   * @param currentGroupIndex
   * @param groupName
   * @param selectedGroupNames
   * @param selectedGroupLength
   */
  addSubGroupOnTopLevel(currentGroupIndex, groupName, selectedGroupNames, selectedGroupLength) {

    //if selectedGroupLength === 1, then add name of selected group directly
    let group = { 'group V': 'Fifith element' }
    let groupsKeyExistsFlag = false;
    //iterate through the specified group from @param insided hardcoded 'groups' key
    for (let groupIndex = 0; groupIndex < this.JSONTemplate['groups'][currentGroupIndex][groupName].length; groupIndex++) {
      //Search if the specifed group if it has a groups key inside to push the new group
      if (this.JSONTemplate['groups'][currentGroupIndex][groupName][groupIndex].hasOwnProperty('groups')) {
        //iterate through the groups to be added
        for (let _index = 0; _index < selectedGroupNames.length; _index++) {
          /*
          For single group assign name to key
          if(selectedGroupLength === 1){
            console.log('______',this.JSONTemplate['groups'][currentGroupIndex][groupName].length)
            this.JSONTemplate['groups'][currentGroupIndex][groupName][groupIndex].length = 0;
            this.JSONTemplate['groups'][currentGroupIndex][groupName][groupIndex] = [selectedGroupNames[_index]];
            return;
          }*/
          console.log(this.JSONTemplate['groups'][currentGroupIndex][groupName][groupIndex])
          //push the new groups inside the specified group
          this.JSONTemplate['groups'][currentGroupIndex][groupName][groupIndex]['groups'].push({
            [selectedGroupNames[_index]]: []
          });
          groupsKeyExistsFlag = true;
        }

        return;
      }
    }
    if (!groupsKeyExistsFlag) {
      this.JSONTemplate['groups'][currentGroupIndex][groupName]['groups'] = [];
      for (let _index = 0; _index < selectedGroupNames.length; _index++) {
        this.JSONTemplate['groups'][currentGroupIndex][groupName]['groups'].push(
          {
            // [selectedGroupNames[_index]] : []
            'hie': 'Hellow'
          }
        );
      }

      // this.JSONTemplate['groups'][currentGroupIndex][groupName].push(group)
    }
    // }
    // for(let element of this.JSONTemplate['groups'][currentGroupIndex]){
    //   alert('b4 grps');
    //   if(element.hasOwnProperty('groups')){
    //     alert('groups')
    //     for(let _index = 0;_index < selectedGroupNames.length;_index++){
    //       this.JSONTemplate['groups'][currentGroupIndex][groupName]['groups'].push({[selectedGroupNames[_index]] : []});
    //       groupsKeyExistsFlag = true;
    //     }

    //     return;
    //   }
    // }

  }


  /**
   * return label of a field
   * @param uuid
   *
   */
  getFieldLabel(uuid) {
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index].uuid === uuid) {
        return this.JSONTemplate["validations"][index].label;
      }
    }
  }
  addGroupField(fields, groups, groupUUID) {
    //adds fields to group
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index].uuid === groupUUID) {
        for (let fieldIndex = 0; fieldIndex < fields.length; fieldIndex++) {

          this.JSONTemplate["validations"][index]['formFields'].push({
            type: this.getFieldDataFromUUID(fields[fieldIndex]).type,
            uuid: fields[fieldIndex]
          })
        }
      }
    }
    //adds Groups
    for (let index = 0; index < this.JSONTemplate["validations"].length; index++) {
      if (this.JSONTemplate["validations"][index].uuid === groupUUID) {
        for (let fieldIndex = 0; fieldIndex < groups.length; fieldIndex++) {

          this.JSONTemplate["validations"][index]['formFields'].push({
            type: this.getFieldDataFromUUID(groups[fieldIndex]).type,
            uuid: groups[fieldIndex]
          })
        }
      }
    }
  }
}
