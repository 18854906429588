import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Component, Input, OnInit } from '@angular/core';
import { JsonPreviewServiceService } from '../service/json-preview-service.service';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private jsonPreviewService: JsonPreviewServiceService, private commonService: CommonService) { }
  elementType = '';
  completeData = this.jsonPreviewService.getTemplate();
  templateModalFlag = true;
  modalHeader = 'Create new template';

  modalFooter = 'Next step : Define Template Fields';

  ngOnInit(): void {
    this.openTemplateDetailsModal(null);
  }
  formChanged($event){
    console.log($event)
    this.elementType = $event;
  }
  openTemplateDetailsModal(eve){
    console.log(eve)
    if(eve){
      this.modalHeader = 'Edit template details';
      this.modalFooter = 'Update template details';
    }

    this.templateModalFlag = true;
  }
  closeTemplateDetailsModal(eve){
    this.templateModalFlag = false;
  }
  closeModal(){

  }
}
