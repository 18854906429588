import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from 'src/app/service/data.service';

@Pipe({
  name: 'assetUrl'
})
export class AssetUrlPipe implements PipeTransform {

  constructor(private data: DataService) {}

  transform(value: unknown): string {
    return `https://ility-defaults.s3.us-east-2.amazonaws.com/uploads/dev/amp/assets/${value}`
  }

}
