import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemService } from '../system.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  ampBaseUrl = '';
  ampBaseUrlV1 = '';
  ampBaseUrlV2 = '';
  ampBaseUrlV2_1 = '';
  tenantBaseUrl = '';
  utilityBaseUrl = '';
  authBaseUrl = '';
  ampBaseUrlZervV1 = '';
  baseUrlMriService = '';
  baseUrlCalendarService = '';
  ampBaseUrlOpenApi = '';
  utilityBaseUrl1 = '';
  ampBookingUrl = '';

  constructor(
    private http: HttpClient, private systemSetting: SystemService
  ) {
  
      let url = this.systemSetting.environmentUpdatedUrl();
      this.ampBaseUrl = url + '/prmamp/v1/auth/secure/';
      this.ampBaseUrlV1 = url + '/prmamp/v1/auth/secure/v1.1/';
      this.ampBaseUrlV2 = url + '/prmamp/v1/auth/secure/v1.2/';
      this.tenantBaseUrl = url + '/tenant/v1/auth/secure/';
      this.utilityBaseUrl = url + '/prmutility/v2/';

      this.utilityBaseUrl1 = url + '/prmutility/v1/';
      this.authBaseUrl = url + '/prmauthentication/v1/PrmAuth/';

      this.ampBaseUrlZervV1 = url + '/access-control/api/v1/zerv/';
      this.baseUrlMriService = url + '/mri-service/api/v1/';
      this.baseUrlCalendarService = url + '/prm-calendar-service/api/v1/';
      this.ampBaseUrlOpenApi = url + '/prmamp/v1/auth/openapi/';

      this.ampBookingUrl = url + '/prmbookings/v1/auth/secure/';
      this.ampBaseUrlV2_1 = url + '/prmamp/v2/auth/secure/';

  }

  // Amp get, post, put and delete function start

  /**
   * @method (get http request Amp)
   * @param url (api url)
   * @param data (all search params)
   */
  getAmpData(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.ampBaseUrl}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (get http request Amp)
   * @param url (api url)
   * @param data (all search params)
   */
   getBookingData(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.ampBookingUrl}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getAmpDataZervV1(url: string, data?:any, formData?: boolean) {
    const apiUrl = `${this.ampBaseUrlZervV1}${url}`;
    // const postData = !formData ? data : this.appendFormData(data);
    return this.http.get(apiUrl)
      .pipe(map((response: any) => {
        return response;
      }));
  }


  getAmpDataV2(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.ampBaseUrlV2}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getAmpOpenApiData(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.ampBaseUrlOpenApi}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getAmpDataCSV(url: string): Observable<any> {
    const apiUrl = `${this.ampBaseUrl}${url}`;
    return this.http.get(apiUrl, { responseType: 'blob' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  postTenantDataCSV(url: string): Observable<any> {
    const apiUrl = `${this.tenantBaseUrl}${url}`;
    return this.http.post(apiUrl, null, { responseType: 'blob' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  postTenantDataCSVWithBody(url: string,body): Observable<any> {
    const apiUrl = `${this.tenantBaseUrl}${url}`;
    return this.http.post(apiUrl,body, { responseType: 'blob' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getAmpDataCSVxls(url: string): Observable<any> {
    const apiUrl = `${this.utilityBaseUrl}${url}`;

    return this.http.post(apiUrl, '', { responseType: 'blob', observe: 'response' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  postAmpDataCSVxls(url: string,body): Observable<any> {
    const apiUrl = `${this.utilityBaseUrl}${url}`;

    return this.http.post(apiUrl, body, { responseType: 'blob', observe: 'response' })
      .pipe(map((response: any) => {
        return response;
      }));
  }


  /**
   * @method (post http request Amp)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData (optional, send true if you want send data as form data)
   */
  postAmpData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.ampBaseUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  postAmpV2Data(url: string, data, formData?: boolean) {
    const apiUrl = `${this.ampBaseUrlV2_1}${url}`; 
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }
  
  postAmpDataV1(url: string, data, formData?: boolean) {
    const apiUrl = `${this.ampBaseUrlV1}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  postAmpDataZervV1(url: string, data, formData?: boolean) {
    const apiUrl = `${this.ampBaseUrlZervV1}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }


  /**
   * @method (put http request Amp)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   */
  putAmpData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.ampBaseUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.put(apiUrl, postData, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  putBookingData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.ampBookingUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.put(apiUrl, postData, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  putAmpDataV1(url: string, data, formData?: boolean) {
    const apiUrl = `${this.ampBaseUrlV1}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.put(apiUrl, postData, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (delete http request Amp)
   * @param url (api url)
   */
  deleteAmpData(url: string) {
    const apiUrl = `${this.ampBaseUrl}${url}`;
    return this.http.delete(apiUrl)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  // Amp get, post, put and delete function end

  // Tenant get, post, put and delete function start

  /**
   * @method (get http request Tenant)
   * @param url (api url)
   * @param data (all search params)
   */
  getTenantData(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.tenantBaseUrl}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (post http request Tenant)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData (optional, send true if you want send data as form data)
   */
  postTenantData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.tenantBaseUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (put http request Tenant)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   */
  putTenantData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.tenantBaseUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.put(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (delete http request Tenant)
   * @param url (api url)
   */
  deleteTenantData(url: string) {
    const apiUrl = `${this.tenantBaseUrl}${url}`;
    return this.http.delete(apiUrl)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  // Tenant get, post, put and delete function end

  // Utility get, post, put and delete function start

  /**
   * @method (get http request Utitlity)
   * @param url (api url)
   * @param data (all search params)
   */
  getUtilityData(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.utilityBaseUrl}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getUtilityDataV1(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.utilityBaseUrl1}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (post http request Utility)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData (optional, send true if you want send data as form data)
   */
  postUtilityData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.utilityBaseUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (post http request Utility)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData (optional, send true if you want send data as form data)
   */
   postUtilityDataV1(url: string, data, formData?: boolean) {
    const apiUrl = `${this.utilityBaseUrl1}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

    /**
   * @method (post http request Utility)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData (optional, send true if you want send data as form data)
   */
     postUtilityDatav1(url: string, data, formData?: boolean) {
      const apiUrl = `${this.utilityBaseUrl1}${url}`;
      const postData = !formData ? data : this.appendFormData(data);
      return this.http.post(apiUrl, postData)
        .pipe(map((response: any) => {
          return response;
        }));
    }

  /**
   * @method (put http request Utility)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   */
  putUtilityData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.utilityBaseUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.put(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (put http request Utility)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   */
   putUtilityDataV1(url: string, data, formData?: boolean) {
    const apiUrl = `${this.utilityBaseUrl1}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.put(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (delete http request Utility)
   * @param url (api url)
   */
  deleteUtilityData(url: string) {
    const apiUrl = `${this.utilityBaseUrl}${url}`;
    return this.http.delete(apiUrl)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (delete http request Utility)
   * @param url (api url)
   */
  deleteUtilityDataV1(url: string) {
    const apiUrl = `${this.utilityBaseUrl1}${url}`;
    return this.http.delete(apiUrl)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (delete http request Utility)
   * @param url (api url)
   */
  deleteUtilityDataV1WithBody(url: string, body: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: body
    };
    const apiUrl = `${this.utilityBaseUrl1}${url}`;
    return this.http.delete(apiUrl, options)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (delete http request Utility)
   * @param url (api url)
   */
  deleteUtilityDataWithBody(url: string, body: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: body
    };
    const apiUrl = `${this.utilityBaseUrl}${url}`;
    return this.http.delete(apiUrl, options)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  // Utility get, post, put and delete function end

  // Auth get, post, put and delete function start

  /**
   * @method (get http request Auth)
   * @param url (api url)
   * @param data (all search params)
   */
  getAuthData(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.authBaseUrl}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (post http request Auth)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData (optional, send true if you want send data as form data)
   */
  postAuthData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.authBaseUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (post http request Auth)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData (optional, send true if you want send data as form data)
   */
  postBookingData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.ampBookingUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (put http request Auth)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   */
  putAuthData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.authBaseUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.put(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (delete http request Auth)
   * @param url (api url)
   */
  deleteAuthData(url: string) {
    const apiUrl = `${this.authBaseUrl}${url}`;
    return this.http.delete(apiUrl)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  // Auth get, post, put and delete function end

  /**
   * @method (get http request Auth)
   * @param url (api url)
   * @param data (all search params)
   */
  getData(url: string, data?: any, appendDomain?: any, headers?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    let newUrl = this.systemSetting.environmentUpdatedUrl();

    if (appendDomain) {
      url = `${newUrl}${url}`;
    }

    return this.http.get(url, { params: searchParams, headers: headers })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getPRMData(url: string, data?: any, appendDomain?: any): Observable<any> {
    let newUrl = this.systemSetting.environmentUpdatedUrl();
    const searchParams = this.appendParams(data);
    if (appendDomain) {
      url = `${newUrl}${url}`;
    }
    return this.http.get(url, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (get http request Auth)
   * @param url (api url)
   * @param data (all search params)
   */
  postData(url: string, data?: any, headers?: any): Observable<any> {
    const postData = data;
    return this.http.post(url, postData, { headers: headers })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (append formdata)
   * @param myFormData (pass which you want to make formdata type)
   */
  appendFormData(myFormData: { [x: string]: any; }): FormData {
    const fd = new FormData();
    for (const key of Object.keys(myFormData)) {
      fd.append(key, myFormData[key]);
    }
    return fd;
  }

  /**
   * @method (append params)
   * @param myParams (pass data which you want to make http params)
   */
  appendParams(myParams: { [x: string]: any; }): HttpParams {
    let params = new HttpParams();
    if (!!myParams) {
      for (const key of Object.keys(myParams)) {
        // if (data[key] || key == 'skip')
        params = params.append(key, myParams[key]);
      }
    }
    return params;
  }

  postMRIData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.baseUrlMriService}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getCalendarData(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.baseUrlCalendarService}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  deleteCalendarData(url: string, data?: any , prmBooking?:any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = prmBooking?`${this.ampBookingUrl}${url}`:`${this.baseUrlCalendarService}${url}`;
    return this.http.delete(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  postUpload(url: string, data, formData?: boolean) {
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(url, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

}
