import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import headerData from "../../../assets/JSON/field_menu.json";

@Component({
  selector: 'app-field-component',
  templateUrl: './field-component.component.html',
  styleUrls: ['./field-component.component.scss']
})
export class FieldComponentComponent implements OnInit {

  constructor() { }
  public menuList:[] = headerData;
  @Output() emitFormType = new EventEmitter<string>();

  ngOnInit(): void {
  }

  showForm(type){
    if(type){
      this.emitFormType.emit(type);
    }
  }
}
