import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-input-checkbox-field',
  templateUrl: './input-checkbox-field.component.html',
  styleUrls: ['./input-checkbox-field.component.scss', '../form-builder.component.scss']
})
export class InputCheckboxFieldComponent implements OnInit {

  constructor(private jsonPreviewService: JsonPreviewServiceService) { }

  label: string = '';
  inputID: string = '';
  inputName : string='';
  newField = {};
  required: boolean = false;
  errorMsg: string = '';
  description = '';
  @Input() updateUUID;
  @Output() emitNewField = new EventEmitter();

  ngOnInit(): void {
    if (this.updateUUID) {
      this.populateData(this.updateUUID);
    }
  }
  populateData(uuid) {
    let data = this.jsonPreviewService.getFieldDataFromUUID(uuid);
    this.label = data.label;
    this.inputID = data.id;
    this.required = data.required;
    this.description = data.description;
  }
  validateForm() {

    if (this.label.length === 0 || this.inputID.length === 0 || this.inputName.length===0) {
      this.errorMsg = 'Fill all the mandatory fields.';
      return false;
    }
    return true;
  }
  modifyTemplateJSON() {
    if (!this.validateForm()) {
      return;
    }
    if (!this.jsonPreviewService.uniqueIdCheck(this.inputID, this.updateUUID)) {
      this.errorMsg = 'Field id should be unique!';
      return;
    }

    this.newField = {
      "label": this.label,
      "type": "checkbox",
      "id": this.inputID,
      "name" : this.inputName,
      "required": this.required,
      description: this.description
    };
    if (this.updateUUID) {
      //update the JSON
      this.jsonPreviewService.updateField(this.updateUUID, this.newField);
      this.emitNewField.emit(this.newField);

    }
    else {
      // add into the json
      this.jsonPreviewService.pushTemplateField(this.newField);
      this.emitNewField.emit(this.newField);
    }
  }
  onCancel() {
    this.emitNewField.emit(this.newField);
  }
}
