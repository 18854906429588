import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  allEnvConfig: any = {};


  public data: BehaviorSubject<string> = new BehaviorSubject('');
  public notificationCount: BehaviorSubject<string> = new BehaviorSubject('');
  public propertyId: BehaviorSubject<string> = new BehaviorSubject('');
  public visitorLocation: BehaviorSubject<string> = new BehaviorSubject('');
  public maxDate: BehaviorSubject<string> = new BehaviorSubject('');
  public minTime: BehaviorSubject<string> = new BehaviorSubject('');
  public visitorDate: BehaviorSubject<any> = new BehaviorSubject('');
  public taxRateValue: BehaviorSubject<any> = new BehaviorSubject('');
  private selectedProperty: BehaviorSubject<string> = new BehaviorSubject('');
  private tenantView: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private propertyTimeZone: BehaviorSubject<any> = new BehaviorSubject('');
  private userPropertySet: BehaviorSubject<any> = new BehaviorSubject('');
  private eventList: BehaviorSubject<any> = new BehaviorSubject('');
  private _eventListner = new Subject<any>();
  private tenantviewTimezone: BehaviorSubject<string> = new BehaviorSubject('');
  private googleConnectivity: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private hambergerMenu: BehaviorSubject<any> = new BehaviorSubject('false');
  private analyticProduct: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private menuIconFlag: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private _location: Location
  ) { }




  setSelectedProperty(property) {
    this.selectedProperty.next(property);
  }

  getSelectedProperty() {
    return this.selectedProperty;
  }

  setTenantView(val) {
    this.tenantView.next(val);
  }

  getTenantView() {
    return this.tenantView;
  }

  setData(userData) {
    this.data.next(userData);
  }

  getData(): BehaviorSubject<string> {
    return this.data;
  }

  setNotificationData(count) {
    this.notificationCount.next(count);
  }
  getNotificationData(): BehaviorSubject<string> {
    return this.notificationCount;
  }

  setPropertyData(id) {
    this.propertyId.next(id);
  }
  getPropertyData(): BehaviorSubject<string> {
    return this.propertyId;
  }

  setVisitorLocation(locationId) {
    this.visitorLocation.next(locationId);
  }

  getVisitorLocation() {
    return this.visitorLocation;
  }

  getVisitorMaxDate() {
    return this.maxDate;
  }

  setVisitorMaxDate(date) {
    this.maxDate.next(date);
  }

  getVisitorMinTime() {
    return this.minTime;
  }

  setVisitorMinTime(time) {
    this.minTime.next(time);
  }
  changeVisitorDate(date) {
    this.visitorDate.next(date);
  }
  getVisitorDate() {
    return this.visitorDate;
  }


  setTaxRateValue(data) {
    this.taxRateValue.next(data);
  }

  getTaxRateValue() {
    return this.taxRateValue;
  }

  goBack() {
    this._location.back();
  }


  setSelectedPropertyTimeZone(timeZone) {
    this.propertyTimeZone.next(timeZone);
  }

  getPropertyTimeZone() {
    return this.propertyTimeZone;
  }


  setuserPropertySet(data) {
    this.userPropertySet.next(data);
  }

  getuserPropertySet() {
    return this.userPropertySet;
  }


  listen(): Observable<any> {
    return this._eventListner.asObservable();
  }

  filter(filterBy) {
    this._eventListner.next(filterBy);
  }

  setUpcomingEventList(data) {
    this.eventList.next(data);
  }

  getUpcomingEventList() {
    return this.eventList;
  }

  setTimezoneForTenantView(timezone) {
    this.tenantviewTimezone.next(timezone);
  }

  getTimezoneForTenantView() {
    return this.tenantviewTimezone;
  }

  processImage(value) {
    const size = '/uploads/raw/';
    const imageBaseUrl = this.allEnvConfig.CDN_URL;
    const result = imageBaseUrl + size + value;
    return result;
  }

  private propertiesSubject = new BehaviorSubject(false);
  properties = this.propertiesSubject.asObservable();
  setproperties(value) {
    this.propertiesSubject.next(value);
  }

  private allPropertiesSubject = new BehaviorSubject(null);
  allProperties = this.allPropertiesSubject.asObservable();
  setAllProperties(value) {
    this.allPropertiesSubject.next(value);
  }

  private allCategoriesSubject = new BehaviorSubject(null);
  allCategories = this.allCategoriesSubject.asObservable();
  setAllCategories(value) {
    this.allCategoriesSubject.next(value);
  }

  setGoogleConnectivity(data) {
    this.googleConnectivity.next(data);
  }

  getGoogleConnectivity() {
    return this.googleConnectivity;
  }

  setHambergerMenuFlag(data) {
    this.hambergerMenu.next(data);
  }

  getHambergerMenu() {
    return this.hambergerMenu;
  }

  setAnalyticProduct(data) {
    this.analyticProduct.next(data);
  }

  getAnalyticProduct() {
    return this.analyticProduct;
  }

  setMenuIconFlag(data) {
    this.menuIconFlag.next(data);
  }

  getMenuIconFlag() {
    return this.menuIconFlag;
  }

  private exterLinkDataSubject = new BehaviorSubject(null);
  exterLinkData = this.exterLinkDataSubject.asObservable();
  setExterLinkData(value) {
    this.exterLinkDataSubject.next(value);
  }

  private addEditProductIdSubject = new BehaviorSubject(null);
  productIdData = this.addEditProductIdSubject.asObservable();
  setProductIdData(value) {
    this.addEditProductIdSubject.next(value);
  }

  setAllSubjectDefault() {
    this.setSelectedProperty(null);
    this.setproperties(null);
    this.setAllProperties(null);
    this.setTimezoneForTenantView('');
    this.setAllCategories(null);
    this.setExterLinkData(null);
  }



  makeDataWithLeadTime(minimumLeadTime, start_time, oldFromTime) {

    let returnTime = '';

    let fromTime: any = this.makeLeadDate(minimumLeadTime);
    let startDate = moment(start_time).format('yyyy-DD-MM');
    let fromDate = moment(fromTime).format('yyyy-DD-MM');

    if (startDate === fromDate) {
      let newLeadTimeDate = moment(fromTime).format('H:mm').split(':');
      let hour = Number(newLeadTimeDate[1]) < 30 ? Number(newLeadTimeDate[0]) : (Number(newLeadTimeDate[0]) + 1);
      let min = Number(newLeadTimeDate[1]) < 30 ? '30' : '00';

      returnTime = `${hour}:${min}`;
    } else {
      returnTime = oldFromTime;
    }

    return returnTime;
  }

  makeLeadDate(minimumLeadTime) {
    let fromTime = moment();
    fromTime = fromTime.add(minimumLeadTime, 'hours');
    return fromTime;
  }

  makeTime(time) {
    let date: any = this.getTwentyFourHourTime(time);
    date = date.split(":");
    let setTime = new Date().setHours(date[0]);
    setTime = new Date(setTime).setMinutes(date[1]);
    setTime = new Date(setTime).setSeconds(0);
    return setTime;
  }

  getTwentyFourHourTime(amPmString) {
    var d = new Date("1/1/2013 " + amPmString);
    return (
      d.getHours() +
      ":" +
      `${d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()}`
    );
  }

  calculateShiftInterval(desiredStartTime, interval, period, desiredEndTime) {
    const periodsInADay = moment.duration(1, "day").as(period);
    const timeLabels = [];
    const startTimeMoment = moment(desiredStartTime, 'HH:mm');
    const endTimeMoment = moment(desiredEndTime, 'HH:mm');
    for (let i = 0; i < periodsInADay; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, period);
      timeLabels.push(startTimeMoment.format("hh:mm A"));
      if (moment(startTimeMoment).isSame(endTimeMoment)) {
        break;
      }
    }
    return timeLabels;
  }
}
