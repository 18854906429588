import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from '../login.service';
import { map } from 'rxjs/operators';
import { SystemService } from '../system.service';


@Injectable({
  providedIn: 'root'
})
export class PrmhttpService {

  apiBaseURL = '';

  constructor(
    private http: HttpClient, private systemService:SystemService
  ) {
      this.apiBaseURL = this.systemService.environmentUpdatedUrl();
  }

  /**
   * @method (get http request Amp)
   * @param url (api url)
   * @param data (all search params)
   */
  getData(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.apiBaseURL}${url}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getDataBlob(url: string, data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.apiBaseURL}${url}`;
    return this.http.get(apiUrl, { params: searchParams, responseType: 'blob' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (post http request Utility)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData (optional, send true if you want send data as form data)
   */
  postData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.apiBaseURL}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.post(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

   /**
   * @method (post http request Utility)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   * @param formData (optional, send true if you want send data as form data)
   */

  postDataByPassInterceptor(url: string, data) {
    const apiUrl = `${this.apiBaseURL}${url}`;
    return this.http.post(apiUrl, data , {params:{byPassInterceptor:'true'}})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (put http request Utility)
   * @param url (api url)
   * @param data (data which we have to pass to server)
   */
  putData(url: string, data, formData?: boolean) {
    const apiUrl = `${this.apiBaseURL}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
    return this.http.put(apiUrl, postData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  exportCSV(url: string): Observable<any> {
    const apiUrl = `${this.apiBaseURL}${url}`;
    return this.http.post(apiUrl, '', { responseType: 'blob', observe: 'response' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  exportCSVGet(url: string): Observable<any> {
    const apiUrl = `${this.apiBaseURL}${url}`;
    return this.http.get(apiUrl, { responseType: 'blob', observe: 'response' })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (delete http request Utility)
   * @param url (api url)
   */
  deleteData(url: string) {
    const apiUrl = `${this.apiBaseURL}${url}`;
    return this.http.delete(apiUrl)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (delete http request Utility)
   * @param url (api url)
   */
  deleteDataWithBody(url: string, body: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: body
    };
    const apiUrl = `${this.apiBaseURL}${url}`;
    return this.http.delete(apiUrl, options)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (append formdata)
   * @param myFormData (pass which you want to make formdata type)
   */
  appendFormData(myFormData: { [x: string]: any; }): FormData {
    const fd = new FormData();
    for (const key of Object.keys(myFormData)) {
      fd.append(key, myFormData[key]);
    }
    return fd;
  }

  /**
   * @method (append params)
   * @param myParams (pass data which you want to make http params)
   */
  appendParams(myParams: { [x: string]: any; }): HttpParams {
    let params = new HttpParams();
    if (!!myParams) {
      for (const key of Object.keys(myParams)) {
        // if (data[key] || key == 'skip')
        params = params.append(key, myParams[key]);
      }
    }
    return params;
  }

}
