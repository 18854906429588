<div class="fluid">
  <div class="row">
    <div class="col-md">
      <input type="text" class="form-control" [(ngModel)]="newGroupName" value="{{newGroupName}}"
      placeholder="Group Name" />
    </div>
    <div class="col-md">
      <select name="repetitiveFlag" id="repetitiveFlag_id" [(ngModel)]="repetitiveFlag" class="form-control">
        <option [ngValue]=false>False</option>
        <option [ngValue]=true>True</option>
      </select>
    </div>
    <div class="col-md">
      <input type="text" class="form-control" [(ngModel)]="groupId" value="{{groupId}}"
      placeholder="Group Id" />
    </div>
    <div class="col-md">
      <select name="displayType" id="displayType_id" [(ngModel)]="displayType" class="form-control">
        <option value="block">Block</option>
        <option value="inline">Inline</option>
      </select>
    </div>
    <div class="col-md">
      <select name="tenantForm" id="tenantForm_id" [(ngModel)]="tenantForm" class="form-control">
        <option value=true>Tenant</option>
        <option value=false>Amp</option>
      </select>
    </div>
    <div class="col-md">
      <button class="btn btn-sm btn-block" (click)="addNewGroup()">Add new group</button>
    </div>
    <div class="col-md">
      <button class="btn btn-sm btn-block" (click)="showFormBuilder()">Form Builder</button>
    </div>
  </div>

  <div class="row" *ngIf="repetitiveFlag">
    <input type="text" [(ngModel)]="addMoreLabel" class="form-control" placeholder="Add more label">
  </div>

  <div class="form-group row">
    <input class="form-control" id="description" type="text" placeholder="Description" [(ngModel)]="description"
      value="{{description}}" maxlength="1000" />
    <div class="form-label float-left">example: You can explain more about what this field will be used for.</div>
  </div>

  <div class="row"  *ngFor="let count of validatorCount;index as i">
    <div class="col-md-1" *ngIf="i == 0">
     <button class="btn btn-sm" (click)="addMoreValidators()">
      +
     </button>
    </div>
    <div class="col-md">Add Validator</div>
    <div class="col-md">
      <input type="text" name="fromUUID" id="fromUUID" [(ngModel)]="count['from']" placeholder="From" class="form-control" />
    </div>
    <div class="col-md">
      <select id="typeOfComparision" name="typeOfComparision" [(ngModel)]="count['type']" class="form-control">
        <option value="" selected >Select comparision</option>
        <option value="lessThan"><</option>
        <option value="greaterThan">></option>
        <option value="notEqualsTo">!==</option>
        <option value="equalsTo">===</option>
      </select>
    </div>
    <div class="col-md">
      <input type="text" name="toUUID" id="toUUID" [(ngModel)]="count['to']" placeholder="To" class="form-control"/>
    </div>
    <div class="col-md-1" *ngIf="i != 0">
      <button class="btn btn-sm" (click)="removeValidators(i)">
       -
      </button>
  </div>
  <div class="row">
    <span class="text-danger p-3">
      {{errMsg}}
    </span>
  </div>
<!---new group implementation-->



 <ng-template *ngIf="false">
  <ng-container *ngFor="let singleGroup of groupData;index as groupIndex">
    <div class="row pt-2">
      <div class="col-md-12">
        <!-- ---START of 1st level of Hierarchy of group -->
        <ng-container *ngFor="let data of singleGroup | keyvalue">
          <br>
          <button class="btn btn-md btn-group-main"
          (click)="openGroupModal(data.key,groupIndex,1)"
          >
              {{data.key}}
              <!-- {a}{b} >>>> a | b -->
          </button>
          <!-- ---START of 1st level of Hierarchy of subgroup -->
          <ng-container *ngFor="let subgroup of singleGroup[data.key];index as subGroupIndex">
            <ng-container *ngFor="let subGroupName of subgroup['groups'] | keyvalue;index as _index">
              <!-- <span class="ml-2" *ngIf="_index === 0"> > </span> -->
                <button class="btn btn-sm btn-second-level-btn btn-dark m-2 ml-2"
                (click)="openGroupModal(data.key,groupIndex,2)">
                  {{getKeyNameforGroup(subGroupName.value)}}
                </button>

            </ng-container>

              <!-- <span *ngIf="subgroup.key === 'groups'">
                Yesss
                <ng-container *ngFor="let subgroupName of subgroup.key | keyvalue;">
                  {{subgroupName.key}}
                </ng-container>
              </span> -->
          </ng-container>

          <ng-container *ngFor="let subgroup of singleGroup[data.key];index as subGroupIndex">
            <ng-template [ngIf]="!subgroup.hasOwnProperty('groups')">
              <span class="ml-2" *ngIf="subGroupIndex === 1"> > </span>
              <button class="btn btn-sm btn-field btn-primary m-2 ml-2"
              title="{{subgroup.type}}">
                Label : {{getFieldLabel(subgroup.uuid)}}
              </button>
            </ng-template>
              <!-- <button class="btn btn-sm btn-second-level-btn btn-dark m-2">{{getKeyNameforGroup(subGroupName.value)}}</button> -->
          </ng-container>
          <!-- 1st level of Hierarchy of subgroup END-->
         </ng-container>
         <!-- 1st level of Hierarchy of group END--- -->

      </div>
      <div class="col-md"></div>
    </div>
    <div class="row">
      <div class="col-md-12">

      </div>
    </div>
  </ng-container>
 </ng-template>
</div>
<ng-container *ngFor="let singleGroup of groupData;index as groupIndex">
  <div class="row">
    <button (click)="openGroupModal(singleGroup.name,groupIndex,1,singleGroup.uuid)">
      {{singleGroup.name}}
    </button>

  </div>
</ng-container>


<div class="container" *ngIf="modalFlag">
  <div id="modal-content" class="modal-content">
    <div class="modal-header">
      <h2>Update Group</h2>
      <span class="close float-right" (click)="hideModal()">&times;</span>
    </div>
    <div class="modal-body">
      <div class="container">
        <div class="row">
          <div class="col-md"></div>
          <div class="col-md-4">

          </div>

          <div class="col-md-3">
            <button class="btn btn-sm btn-danger" (click)="deleteGroup()">
              <i class="fa fa-trash"></i>
              Delete group
            </button>
          </div>
        </div>
        <div class="row">

          <div class="col-md">
            <label for="field" class="form-label">
              Field List
            </label>
            <div class="form-group">
              <select name="" id="" class="form-control" multiple>
                <option value="{{field.uuid}}" *ngFor="let field of fieldData" (click)="selectFields($event.target.value)">
                  {{field.label}}
                </option>
              </select>
              <div class="form-label float-left">default selection: Default</div>

            </div>
            <div class="form-group">
              <button class="btn btn-sm btn-primary" (click)="addFieldGroup()">
                <i class="fa fa-plus"></i>
                Add To group
              </button>
            </div>
          </div>
          <div class="col-md">
            <label for="group" class="form-label">
              Group List
            </label>
            <div class="form-group">
              <!-- <mat-form-field appearance="fill">
                <mat-label>Toppings</mat-label>
                <mat-select [formControl]="toppings" multiple>
                  <mat-select-trigger>
                    {{toppings.value ? toppings.value[0] : ''}}
                    <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                      (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                </mat-select>
              </mat-form-field> -->
              <select name="" id="" class="form-control" multiple  (click)="selectGroup($event.target.value)">
                <ng-container *ngFor="let group of groupData; index as _index">
                  <option value="{{group.uuid}}">{{group.name}}</option>
                </ng-container>

              </select>
              <div class="form-label float-left">default selection: Default</div>
            </div>
            <!-- <button class="btn btn-sm btn-primary" (click)="addSubGroupOnTopLevel()"> -->
              <button class="btn btn-sm btn-primary" (click)="addFieldGroup()">
              <i class="fa fa-plus"></i>
              Add To Group
            </button>
          </div>
        </div>
        <div class="row" style="display:none">
          <div class="col-md-12">
            <div class="float-right">
              <button class="btn btn-warning">UPDATE</button>
            </div>
            <div class="float-left">
              <button class="btn btn-secondary">CANCEL</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <h5>NOTE: Save your group before closing the modal.</h5>
    </div>
  </div>
</div>
