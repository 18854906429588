import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-input-radio-button',
  templateUrl: './input-radio-button.component.html',
  styleUrls: ['./input-radio-button.component.scss', '../form-builder.component.scss']
})
export class InputRadioButtonComponent implements OnInit {

  constructor(private jsonPreviewService: JsonPreviewServiceService) { }
  label: string = '';
  inputID: string = '';
  inputName: string = '';
  checked: boolean = false;
  radioValues = '';
  newField = {};
  required: boolean = false;
  validationAttribute: string = '';
  enable: string = '';
  disable: string = '';
  errorMsg: string = '';
  defaultValue: string = '';
  description = '';
  validatorCount: [{}] = [
    {
      from: '',
      type: '',
      to: ''
    }
  ];
  notRequiredUUID = '';
  requiredUUID = '';

  @Input() updateUUID;
  @Output() emitNewField = new EventEmitter();

  ngOnInit(): void {
    if (this.updateUUID) {
      this.populateData(this.updateUUID);
    }
  }

  populateData(uuid) {
    let data = this.jsonPreviewService.getFieldDataFromUUID(uuid);
    console.log(data)
    this.label = data.label;
    this.inputID = data.id;
    this.inputName = data.name;
    this.radioValues = data['radioValue'];
    this.checked = data['checked'];
    this.required = data.required;
    this.validationAttribute = data['validationAttribute'];
    this.defaultValue = data['value'];
    this.validatorCount = data.validator;
    this.description = data.description;
    if (data['dependencies']) {
      this.enable = data['dependencies']['enable'];
      this.disable = data['dependencies']['disable'];
      this.requiredUUID = data['dependencies']['required'];
      this.notRequiredUUID = data['dependencies']['notRequired'];
    }
  }

  modifyTemplateJSON() {
    if (!this.validateForm()) {
      return;
    }
    if (!this.jsonPreviewService.uniqueIdCheck(this.inputID, this.updateUUID)) {
      alert('Field id should be unique!')
      return;
    }
    if (!this.jsonPreviewService.uniqueNameCheck(this.inputName, this.updateUUID)) {
      this.errorMsg = 'Field name should be unique!';
      return;
    }
    this.newField = {
      "label": this.label,
      "type": "radio",
      "id": this.inputID,
      "name": this.inputName,
      "radioValue": this.radioValues,
      "checked": this.checked,
      "required": this.required,
      "validationAttribute": this.validationAttribute,
      "value": this.defaultValue,
      "validator": this.validatorCount,
      description: this.description
    };
    if (this.enable || this.disable || this.requiredUUID || this.notRequiredUUID) {
      this.newField['dependencies'] = {};
    }
    if (this.enable) {
      this.newField['dependencies']['enable'] = this.enable;
    }
    if (this.disable) {
      this.newField['dependencies']['disable'] = this.disable;
    }
    if (this.requiredUUID) {
      this.newField['dependencies']['required'] = this.requiredUUID
    }
    if (this.notRequiredUUID) {
      this.newField['dependencies']['notRequired'] = this.notRequiredUUID
    }

    if (this.updateUUID) {
      //update the JSON
      this.jsonPreviewService.updateField(this.updateUUID, this.newField);
      this.emitNewField.emit(this.newField);

    }
    else {
      // add into the json
      this.jsonPreviewService.pushTemplateField(this.newField);
      this.emitNewField.emit(this.newField);
    }
  }
  onCancel() {
    this.emitNewField.emit(this.newField);
  }
  validateForm() {
    if (this.inputID.indexOf(' ') >= 0 || this.inputName.indexOf(' ') >= 0) {
      this.errorMsg = 'ID and Name can not contain spaces.';
      return false;
    }
    if (this.label.length === 0 || this.inputID.length === 0 || this.inputName.length === 0 || this.radioValues.length === 0) {
      this.errorMsg = 'Fill all the mandatory fields.';
      return false;
    }
    return true;
  }
  addMoreValidators() {
    this.validatorCount.push({
      'from': '',
      'type': '',
      'to': ''
    });
  }
  removeValidators(i) {
    this.validatorCount.splice(i, 1);
  }
}
