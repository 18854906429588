import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EMPTY, throwError } from 'rxjs';
import { ToastService } from '../toast/toast.service';
import { LoginService } from '../login.service';
import { ApiUrls } from 'src/app/constants/apiUrls';


@Injectable({
  providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {

  tokenIsRefreshed = false;

  constructor(
    private toast: ToastService,
    private router: Router,
    private login: LoginService,
    // private storageService: StorageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let skipExternalParams = req.params.get('byPassInterceptor');

    let loginSession: any;
    if (this.login.checkTokenIsExpiring()) {
      if (!req.url.includes('GetNewToken') && !this.tokenIsRefreshed) {
        this.updateToken();
      }
      return;
    }
    if (localStorage.getItem('LoginSession')) {
      loginSession = JSON.parse(localStorage.getItem('LoginSession')).accessToken;
    }
    //if (authorization && !req.url.includes(ApiUrls.SYSTEM_SETTINGS.THEME)) 
    if (loginSession) {
      if (req.url) {
        if ((req.url.indexOf('.json') === -1 || req.method === 'DELETE') && req.url.indexOf('https://www.googleapis.com') === -1 &&
          req.url.indexOf('mockapi.io') === -1 && req.url.indexOf('Secure/SignOut') === -1 && req.url.indexOf('getapplink') === -1) {
          if (String(skipExternalParams) == 'true') {
            req = req.clone({
              setHeaders: {
                'Authorization': 'bearer ' + loginSession
              },
              params: new HttpParams()
            });
          } else {
            req = req.clone({
              setHeaders: {
                'Authorization': 'bearer ' + loginSession
              },
              params: (req.params ? req.params : new HttpParams())
                .set('userExternalId', localStorage.userId)
                .set('portfolioId', localStorage.prmSelectedPortfolioId)
            });
          }
        }
      }
    }

    return next.handle(req)
      .pipe(
        tap(event => { }, error => {
          switch (error.status) {
            case 401:
              /********** Auto logout if 401 response returned from api **********/
              // this.storageService.clearStorage();
              //this.toast.showErrorToast('Something went wrong : 401, contact support')
              // this.router.navigate(['auth']);
              break;
            case 403:
              // this.storageService.clearStorage();
              this.router.navigate(['/no-permission']);
              //handle 403 here 
              // if (!this.tokenIsRefreshed) {
              //   this.storageService.refreshToken(((er, data) => {
              //     if (er) { return; }
              //     location.reload();
              //   }));
              //   this.tokenIsRefreshed = true;
              // } else {
              //   return throwError(error);
              // }
              if (error.error && error.error.data && error.error.data.indexOf('Access token expired') > -1) {
                this.updateToken();
              } else {
                this.router.navigate(['/no-permission']);
              }
              break;
            case 0:
              /********** If server dosent respond **********/
              break;
            default:
              /********** Check for other serve-side errors **********/
              if (error.error && error.error.data) {
                try{
                if (error.error.data.includes('Security breach detected! access denied!')) {
                  localStorage.clear();
                  this.router.navigate(['/auth']);
                }
              }catch(err){
                
              }
                // this.toast.showErrorToast(error.error.data);
              }
              break;
          }
        }));
  }

  updateToken() {
    this.tokenIsRefreshed = true;
    // this.storageService.refreshToken(((er, data) => {
    //   if (er) { return; }
    //   else {
    //   }

    //   setTimeout(() => {
    //     location.reload();
    //   }, 1000)
    // }));
  }

}
