<h1>
  Radio Button
  <span class="float-right">
    Required <mat-slide-toggle [checked]="required" (change)="required = !required">
    </mat-slide-toggle>
  </span>
</h1>
<div class="container-fluid">
  <!-- label with name -->
  <div class="form-group">
    <input class="form-control" id="label" type="text" placeholder="Label" [(ngModel)]="label" />
    <div class="form-label float-left">example: Select your Gender</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <!-- <<<<<< main input component -->
  <div class="form-group">
    <input class="form-control" id="input_Id" type="text" placeholder="ID" [(ngModel)]="inputID" value="inputID" />
    <div class="form-label float-left">example: gender</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <div class="form-group">
    <input class="form-control" id="input_name" type="text" placeholder="Name" [(ngModel)]="inputName"
      value="inputName" />
    <div class="form-label float-left">example: purchasePrice</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <div class="form-group">
    <input class="form-control" id="radioValues" type="text" placeholder="Radio Button Values" [(ngModel)]="radioValues"
      value="radioValues" />
    <div class="form-label float-left">example: Male</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>

  <div class="form-group">
    <input id="check_checked" type="checkbox" class="input-box" [checked]="checked" (change)="checked = !checked" />
    Checked
  </div>
  <div class="form-group">
    <input class="form-control" id="validationAttribute_Id" type="text" placeholder="Validation Attribute"
      [(ngModel)]="validationAttribute" value="{{validationAttribute}}" />
    <div class="form-label float-left">example: Product</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>

  <div class="form-group">
    <input class="form-control" id="requiredUUID" type="text" placeholder="Required UUID" [(ngModel)]="requiredUUID"
      value="{{requiredUUID}}" />
    <div class="form-label float-left">example: 0b7ef922-a8a5-44e0-b497-4e4114d500ba</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>

  <div class="form-group">
    <input class="form-control" id="notRequiredUUID" type="text" placeholder="Not required UUID"
      [(ngModel)]="notRequiredUUID" value="{{notRequiredUUID}}" />
    <div class="form-label float-left">example: ebdb9b94-bb52-4db2-b925-c9a2e1091f8a</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>
  <div class="form-group">
    <input class="form-control" id="enable" type="text" placeholder="Enable" [(ngModel)]="enable" value="{{enable}}" />
    <div class="form-label float-left">example: UUID</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>
  <div class="form-group">
    <input class="form-control" id="disable" type="text" placeholder="Disable" [(ngModel)]="disable"
      value="{{disable}}" />
    <div class="form-label float-left">example: UUID</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>
  <div class="form-group">
    <input class="form-control" id="defaultValue" type="text" placeholder="Value" [(ngModel)]="defaultValue"
      value="{{defaultValue}}" />
  </div>

  <div class="form-group">
    <input class="form-control" id="description" type="text" placeholder="Description" [(ngModel)]="description"
      value="{{description}}" maxlength="1000" />
    <div class="form-label float-left">example: You can explain more about what this field will be used for.</div>
  </div>

  <div class="form-group" *ngFor="let count of validatorCount;index as i">
    <div class="col-md-1" *ngIf="i == 0">
      <button class="btn btn-sm" (click)="addMoreValidators()">
        +
      </button>
    </div>
    <div class="col-md">Add Validator</div>
    <div class="col-md">
      <input type="text" name="fromUUID" id="fromUUID" [(ngModel)]="count['from']" placeholder="From"
        class="form-control" />
    </div>
    <div class="col-md">
      <select id="typeOfComparision" name="typeOfComparision" [(ngModel)]="count['type']" class="form-control">
        <option value="" selected>Select comparision</option>
        <option value="lessThan">
          << /option>
        <option value="greaterThan">></option>
        <option value="notEqualsTo">!==</option>
        <option value="equalsTo">===</option>
      </select>
    </div>
    <div class="col-md">
      <input type="text" name="toUUID" id="toUUID" [(ngModel)]="count['to']" placeholder="To" class="form-control" />
    </div>
    <div class="col-md-1" *ngIf="i != 0">
      <button class="btn btn-sm" (click)="removeValidators(i)">
        -
      </button>
    </div>
  </div>
  <div class="text-danger">
    <span class="p-3 fw-600">
      {{errorMsg}}
    </span>
  </div>
  <div class="form-group">
    <button class="btn float-left" (click)="onCancel()">Cancel</button>
    <button class="btn float-right" (click)="modifyTemplateJSON()">Save & Update</button>
  </div>
</div>