<div class="main-div-auth">
    <div class="inner-div-auth">
      <form [formGroup]="form" autocomplete="off" class="form" novalidate (ngSubmit)="loginPRMUser()">
  
        <div class="left-side">
          <img class="left-image" [src]="ampBg">
        </div>
  
        <div class="right-side">
          <div class="right-side-inner">
  
            <img class="right-logo" [src]="ampLogo" *ngIf="ampLogo">
          
            <hr class="image-hr">
  
            <div class="heading">Login</div>
  
            <div class="fields">
  
              <div class="form-controls">
                <label>Email Address</label>
  
                <input name="username" type="text" class="form-control" formControlName="username" id="username"
                  placeholder="Your email address" (keydown.space)="$event.preventDefault()" (keyup)="checkEmail()" />
  
                <div class="error-message"
                  *ngIf="form.controls['username'].errors && (form.controls['username'].touched || show)">
                  {{form | printError: 'username': 'Email'}}
                </div>
              </div>
  
              <div class="form-controls password-div">
                <label>Password</label>
                <input name="password" [type]="hidePassword ? 'password':'text'" class="form-control" id="password"
                  placeholder="Your password" formControlName="password" (keydown.space)="$event.preventDefault()" />
                <div class="error-message"
                  *ngIf="form.controls['password'].errors && (form.controls['password'].touched || show)">
                  {{form | printError: 'password': 'Password'}}
                </div>
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                  {{hidePassword ? 'visibility_off' : 'visibility_on'}}
                </mat-icon>
              </div>
  
  
              <button type="submit" [class.submit-button-margin]="!enableSelfSignup" class="submit-button-new">
                {{loginFlag ? 'Processing...' : 'Login'}}
              </button>
  
  
  
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>