import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-field-customization',
  templateUrl: './field-customization.component.html',
  styleUrls: ['./field-customization.component.scss']
})
export class FieldCustomizationComponent implements OnInit {

  completeJSON: any = {};
  // togglePanel:false;
  togglePanel= [];
  @Output() emitEditField = new EventEmitter();
  constructor(private jsonPreviewService: JsonPreviewServiceService) { }

  ngOnInit(): void {
    this.completeJSON = this.jsonPreviewService.getTemplate();
    console.log(this.completeJSON)
  }
  replicateField(uuid){
    this.jsonPreviewService.replicateField(uuid);
  }
  removeField(uuid){
    this.jsonPreviewService.removeField(uuid);
  }
  editField(field){
    this.emitEditField.emit(field);
  }
  changeRequireFlag(uuid,flagToBeSet){
    this.jsonPreviewService.changeRequireFlag(uuid,flagToBeSet);
  }
}
