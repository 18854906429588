import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { JsonPreviewServiceService } from 'src/app/service/json-preview-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  templateName='';
  downloadJsonHref;
  @Output() openModalMain = new EventEmitter<boolean>();
  constructor(private jsonPreviewService: JsonPreviewServiceService,private sanitizer: DomSanitizer){}

  ngOnInit(): void {
    this.templateName= this.jsonPreviewService.getTemplateName();
  }

  downloadJson(){
        var sJson = JSON.stringify(this.jsonPreviewService.getTemplate());
        var element = document.createElement('a');
        element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
        element.setAttribute('download', this.jsonPreviewService.getTemplateName() +".json");
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
 }

 openModal(){
   this.openModalMain.emit(true);
 }
 getTemplateName(){
   this.templateName = this.jsonPreviewService.getTemplateName();
 }
 ngAfterContentChecked(){
  this.getTemplateName();
 }
}
