import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Helper } from "../classes/helper";
import { ApiUrls } from "../../app/constants/apiUrls";
import { AssetUrlPipe } from '../shared/pipes/asset-url.pipe';
import { DataService } from '../service/data.service';
import { ToastService } from '../service/toast/toast.service';
import { LoginService } from '../service/login.service';
import { SystemService } from '../service/system.service';
import { HttpService } from '../service/http/http.service'; 
import { ErrorConst } from '../constants/errorConst';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  ampLogo = '';
  ampBg = '';
  androidLink = '';
  iosLink = '';
  hidePassword = true;
  loginFlag = false;

  show = false;
  form: FormGroup;
  errorConst = ErrorConst;

  subject: Subscription;
  baseUrl: string = '';
  subscribeTheme: Subscription;
  multiplePortfolioUser: any = [];

  enableSelfSignup = false;

  constructor(
    private router: Router, public login: LoginService, private http: HttpService,
    private fb: FormBuilder, public centerSystem: SystemService, private route: ActivatedRoute,
    private dataService: DataService,
    private toast: ToastService
  ) { }

  ngOnInit() {

    let getUrl = window.location;
    this.baseUrl = getUrl.host;

    if (this.baseUrl.indexOf('localhost') > -1) {
      this.baseUrl = 'rxr.ility.tech';
    }

    this.makeForm();
    this.ampBg = `${new AssetUrlPipe(this.dataService).transform('icons/login_main.jpg')}`;
    if (this.login.isPRMAuthenticated()) {
      const roleSet = this.login.getRoleSet();
      let isSuperAdmin = roleSet.find(x => x.name == "SuperAdmin");
      this.multiplePortfolioUser = this.checkMultiplePortfolioUser();
      if (isSuperAdmin) {
        this.router.navigate(['/superadmin-dashboard']);
      } else if (!isSuperAdmin) {
        if ((this.multiplePortfolioUser.length > 1) && (roleSet[0]?.roleType == 0 || roleSet[0]?.roleType == 1)) {
          this.router.navigate(['/user-portfolios']);
        } else {
          this.login.redirectionToAmpTenant();
        }
      }
    } else {
      // this.subTheme();
    }

    this.subscribeAppLinks();
    this.getLogo();
  }

  subTheme() {
    this.ampBg = `${new AssetUrlPipe(this.dataService).transform('icons/login_main.jpg')}`;
    // debugger
    this.subscribeTheme = this.centerSystem.getThemeSettings.subscribe(data => {
      if (data) {
        this.enableSelfSignup = data.enable_selfsignup;
        this.ampLogo = data.amp_portal_logo;

        if (data.amp_login_background) {
          this.ampBg = data.amp_login_background;
        } else {
          this.ampBg = `${new AssetUrlPipe(this.dataService).transform('icons/login_main.jpg')}`;
        }

        this.centerSystem.allSettings = data;
        this.centerSystem.setSystemSettings(data);
      }
    });
  }

  getThemeSettings() {
    this.centerSystem.getAllThemeSettings().subscribe(
      (res: any) => {
        this.ampLogo = res.data.amp_portal_logo;

        this.enableSelfSignup = res.data.enable_selfsignup;

        if (res.data.amp_login_background) {
          if (Helper.isUrl(res.data.amp_login_background)) {
            this.ampBg = `url(${res.data.amp_login_background})`;
          } else {
            this.ampBg = res.data.amp_login_background;
          }
        } else {
          this.ampBg = `url(${new AssetUrlPipe(this.dataService).transform('icons/login_main.jpg')})`;
        }
        this.centerSystem.allSettings = res.data;
        this.centerSystem.setSystemSettings(res.data);
      }

    )
  }

  makeForm() {
    this.form = this.fb.group({
      'username': ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      'password': ['', [Validators.required]]
    });
  }

  subscribeAppLinks() {
    this.subject = this.centerSystem.getAppLinks.subscribe(data => {
      if (data && data.android_app_link) {
        this.androidLink = data.android_app_link.trim();
      }
      if (data && data.ios_app_link) {
        this.iosLink = data.ios_app_link.trim();
      }
    });
  }

  checkEmail() {
    this.form.controls.username.setValue(this.form.value.username.trim());
  }

  loginPRMUser() {
    if (!!this.loginFlag) {
      return;
    }
    if (this.form.valid) {      
      this.loginFlag = true;
      let password = this.login.createCipher(this.form.value.password);
      this.login.loginPRM(this.form.value.username.toLowerCase().trim(), password).then(async (data: any) => {
        if (data.success) {
          this.dataService.setAllSubjectDefault();
          let isSuperAdmin = this.login.isSuperAdmin();
          if(isSuperAdmin){
            this.router.navigate(['/template-generate']);
          }else{
            this.loginFlag = false;
            this.toast.showErrorToast('Only superadmins can view this page.');
            this.login.logout();
          }
        } else {

          if (data.errors && data.errors.data.includes('You are unable to login')) {
            this.toast.showErrorToast(data.errors.data);
          } else {
            this.toast.showErrorToast('Invalid email or password');
          }
          localStorage.clear();
          this.loginFlag = false;
        }
      });
    } else {
      this.show = true;
    }

  }


  getLogo() {
    // this.ampLogo = `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-sJKaXi0TbEhemWXSXa4YDFXt2yeNEKHCzA&usqp=CAU`;
    this.ampLogo = 'https://ility.com/wp-content/themes/twentynineteen-child/assets/images/logo.png';
  }

  checkMultiplePortfolioUser() {
    return new Promise((resolve, rej) => {
      this.http.getPRMData(`${ApiUrls.userPortfolios}`, {}, true).subscribe((res) => {
        this.multiplePortfolioUser = res.data;
        resolve(true);
      })
    });
  }
  getAllThemeSettings() {
    return new Promise((resolve, rej) => {
      this.centerSystem.getAllSettings().subscribe(res => {
        if (res.data) {
          let data: any;
          data = res.data;
          // const data = JSON.parse(res.data);
          this.centerSystem.allSettings = data;
          this.centerSystem.setSystemSettings(data);
        }
        resolve(true);
      });
    });
  }

  updateUserRole() {
    return new Promise((resolve, rej) => {
      const roleSet = this.login.getRoleSet();
      if (roleSet && roleSet.length) {
        let roleId = roleSet[0].id;
        this.http.getAuthData("Secure/Role?roleId=" + roleId).subscribe(res => {
          if (res.data) {
            localStorage.setItem("prmRoleData", JSON.stringify(res.data));
            localStorage.setItem("roleSet", JSON.stringify([res.data]));

            if (res.data.portfolios && res.data.portfolios.length > 0) {
              localStorage.setItem("prmSelectedPortfolioId", res.data.portfolios[0].id);
            }
            if (res.data.portfolios && res.data.portfolios.length > 0 && !localStorage.getItem('selected-portfolio')) {
              localStorage.setItem("selected-portfolio", res.data.portfolios[0].name);
            }

            if (window.location.host.indexOf('localhost') === -1) {
              if (window.location.host !== res.data.baseDomain) {
                res.data['domain_name'] = res.data.baseDomain;
                this.centerSystem.updateWindowUrl(res.data);
                return;
              }
            }

          }
          resolve(true);
        });
      }
    });
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
    if (this.subscribeTheme) {
      this.subscribeTheme.unsubscribe();
    }
  }

}
