<div class="container-fluid pt-3 pb-2">
  <div class="row">
    <div class="col-md">
      {{templateName}}
      <span (click)="openModal()">
        🖉
      </span>
    </div>
    <div class="col-md">
      <button class="btn float-right download-btn" (click)="downloadJson()">
        <!-- <a [href]="downloadJsonHref" download="download.json"> -->
          Download JSON
        <!-- </a> -->
      </button>
    </div>
  </div>
</div>
