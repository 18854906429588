export const ApiUrls = {

    requestServices: '/prmutility/v1/tickets/requestservices',
    requestType: '/prmutility/v1/tickets/requesttype',
    requesttypeByProperty: '/prmutility/v1/tickets/requesttype/property',
    task: '/prmutility/v1/tickets/task',
    reqyesTypeByProperty: '/prmutility/v1/tickets/requesttype/property',
    propertyList: '/prmamp/v1/auth/secure/property',
    calculate: '/prmutility/v1/tickets/servicecharge/calculate',
    prmUser: '/prmauthentication/v1/PrmAuth/Secure/User',
    category: '/prmutility/v1/product/category',
    role: '/prmauthentication/v1/PrmAuth/Secure/Role',
    newRole: '/prmauthentication/v1/PrmAuth/Secure/Role',
    getTickets: '/prmutility/v1/tickets',
    getTicketsAMP: '/utility/v1.1/tickets/enterprise',
    statusLabel: '/prmutility/v1/tickets/statusLabel/work_order',
    ticketsExport: '/prmutility/v1/tickets/export',
    notification: '/authentication/v1/Auth/Secure/User/permission/notification/',
    prmNotification: '/prmauthentication/permission/notification/',
    enterpriseAll: '/prmamp/v1/auth/secure/enterpriseAll',
    propertyByEnterprise: '/prmamp/v1/auth/secure/property/enterprise',
    floorAndSuite: '/prmamp/v1/auth/secure/product/floorAndSuite',
    tickets: '/prmutility/v1/tickets',
    payments: '/amp/v1/auth/secure/customer/enterprise',
    paymentsPRM: '/prmbookings/v1/auth/secure/customer/enterprise/',
    propertyListV1: '/prmamp/v1/auth/secure/property/page',
    portfolioPRM: '/prmamp/v1/auth/secure/property/role/portfolio',
    generateProposal: '/payments/v1/auth/secure/invoice/proposal/enterprise/generate',
    updateEndDate: '/amp/v1/auth/secure/contract/updateEndDate',
    prmUpdateEndDate: '/prmamp/v1/auth/secure/contract/updateEndDate',
    openapiProposal: '/amp/v1/auth/openapi/proposal/',
    prmOpenapiProposal: '/prmamp/v1/auth/openapi/proposal/',
    createCustomer: '/amp/v1/auth/secure/customer/enterprise/create-customer',
    prmCreateCustomer: '/prmbookings/v1/auth/secure/customer/enterprise/create-customer',
    proposalAccept: '/amp/v1/auth/openapi/proposal/accept/',
    prmProposalAccept: '/prmamp/v1/auth/openapi/proposal/accept/',
    getCssOpenAPIConfig: '/amp/v1/auth/openapi/proposal/getCssOpenAPIConfig',
    prmGetCssOpenAPIConfig: '/prmamp/v1/auth/openapi/proposal/getCssOpenAPIConfig',
    enterpriseCharge: '/amp/v1/auth/secure/customer/enterprise/charge',
    enterpriseChargePrm: '/prmbookings/v1/auth/secure/customer/enterprise/charge',
    mriCharge: '/tenant/v1/auth/bill/create',
    keyContact: '/amp/v1/auth/secure/key-contacts',
    keyContactPrm: '/prmamp/v1/auth/secure/key-contacts',
    deleteKey: '/amp/v1/auth/secure/key-contacts/delete',
    deleteKeyPrm: '/prmamp/v1/auth/secure/key-contacts',
    updateKeyContact: '/amp/v1/auth/secure/key-contacts/update',
    updateKeyContactPrm: '/prmamp/v1/auth/secure/key-contacts',
    bookingExists: '/calendar-service/api/v1/booking/bookingExists',
    calendarBooking: '/tenant/v1/auth/secure/meeting/newBooking',
    allBooking: '/calendar-service/api/v1/booking',
    getMeetingRoomProduct: '/tenant/v1/auth/secure/booking',
    getBookingRequest: '/tenant/v1/auth/secure/meeting/requests',
    updateRequest: '/tenant/v1/auth/secure/meeting/updateRequest',
    openpathGroup: '/access-control/api/v1/openpath/groups/listAll',
    zervGroup: '/access-control/api/v1/zerv/group/list',
    openpathAdd: '/access-control/api/v1/openpath/group/assignUser',
    zervAdd: '/access-control/api/v1/zerv/group/assignUser',
    productGet: '/amp/v2/auth/secure/product/page',
    calculatePrice: '/tenant/v1/auth/secure/booking/price',
    productByTemplateName: '/prmamp/v1/auth/secure/product/v1.2/templateName',
    listEnableTemplatesPrm: '/prmutility/v2/template/listenabletemplates/property',
    getByTemplatePrm: '/prmamp/v2/auth/secure/product/get-by-template',
    getByTemplatePrmV2: '/prmamp/v2/auth/secure/product/v2.1/get-by-template',
    requestServicesPrm: '/prmutility/v1/tickets/requestservices',
    getServiceByIdPrm: '/prmutility/v1/tickets/requestservices/serviceId',
    serviceChargeCalculatePrm: '/prmutility/v1/tickets/servicecharge/calculate',
    addServicechargeToTicketPrm: '/prmutility/v1/tickets/servicecharge/add-to-ticket',
    tenantByRolePrm: '/prmamp/v1/auth/secure/tenant/role',
    getAllInvoicesPrm: '/prmbookings/v1/auth/secure/invoice/getAllInvoices/page',
    taxRatesPrm: '/prmbookings/v1/auth/secure/meeting/payment/taxRates',
    calculatePricePrm: '/prmbookings/v1/auth/secure/booking/price',
    userProfile: '/prmauthentication/v1/PrmAuth/Secure/User/Profile',

    getProperty: '/prmamp/v1/auth/secure/property/externalId',
    getEnterpriseData: '/prmamp/v1/auth/secure/enterprise/',
    updateOne: '/prmamp/v1/auth/secure/enterprise/updateOne',
    upload: '/image-upload/api/files/upload',
    statusLabelCommon: '/prmutility/v1/tickets/statusLabel',
    createLead: '/prmamp/v1/auth/secure/leads',
    humanEnterprise: '/prmamp/v1/auth/secure/human/enterprise',
    imageEnterpriseDelete: '/prmamp/v1/auth/secure/image/enterprise',
    getMeetingRoomProductPrm: '/prmbookings/v1/auth/secure/booking',
    getPropertyPrm: '/prmamp/v1/auth/secure/property',
    getEnterprisePrm: '/prmamp/v1/auth/secure/enterprise',
    additionalData: '/prmbookings/v1/auth/secure/visitorNative/additionalData',
    checkInCheckOutPRM: '/prmbookings/v1/auth/secure/visitorNative/checkInCheckOut',
    visitorNative: '/prmbookings/v1/auth/secure/v1.1/visitorNative',
    allBookingPrm: '/prm-calendar-service/api/v1/booking/bookingsByProduct',
    calendarBookingPrm: '/prmbookings/v1/auth/secure/meeting/newBooking',
    prmSystemSettingUpdate: 'setting/update/',
    prmSystemSettingUpdatePayment: 'setting/updatepaymentsettings/',
    sendResetPassordEmail: '/prmauthentication/v1/PrmAuth/SendResetPassordEmail',
    keyContactDetailPrm: '/prmauthentication/v1/PrmAuth/Secure/user-keycontact',
    createMultipleUserPrm: '/prmauthentication/v1/PrmAuth/CreateMultipleUser',
    postCreate: '/prmamp/v1/auth/secure/post/create',
    postLike: '/prmamp/v1/auth/secure/postproduct/postLike',
    postUnLike: '/prmamp/v1/auth/secure/postproduct/postUnLike',
    totalLikeCountForPost: '/prmamp/v1/auth/secure/postproduct/totalLikeCountForPost',
    getPostDetail: '/prmamp/v1/auth/secure/product/get-one/',
    getComment: '/prmamp/v1/auth/secure/comment/entityId/page',
    postDeleteComment: '/prmamp/v1/auth/secure/comment',
    postPortfolio: '/prmamp/v1/auth/secure/portfolio',
    systemSetting: 'setting/addkeyvalueinallportfolio',
    combinePostFeed: '/prmamp/v1/auth/secure/product/combinePostFeed',
    postLikeCreate: '/prmamp/v1/auth/secure/postLike/create',
    postLikeDelete: '/prmamp/v1/auth/secure/postLike/delete',
    postDetailNeighborhood: '/prmamp/v1/auth/secure/post/get-one',
    postDelete: '/prmamp/v1/auth/secure/post/delete',
    bookingsByProperty: '/prm-calendar-service/api/v1/booking/bookingsByProperty',
    bookingsByPropertyV2:'/prm-calendar-service/api/v1/booking/v2/bookingsByProperty',
    getBookingRequestPRM: '/prmbookings/v1/auth/secure/meeting/requests',
    updateRequestPRM: '/prmbookings/v1/auth/secure/meeting/updateRequest',
    approveRejectBooking:'/prmbookings/v1/auth/secure/product/booking/approveOrReject',
    GetNewToken: '/prmauthentication/v1/PrmAuth/GetNewToken',
    tenantProposal: '/prmamp/v1/auth/secure/proposal/stage',
    tenantContract: '/prmamp/v1/auth/secure/contract/status',
    proposalGetDetail: '/prmamp/v1/auth/secure/proposal/get-one',
    acceptProposal: '/prmamp/v1/auth/openapi/proposal/accept',
    contractDetail: '/prmamp/v1/auth/secure/contract',
    DeletePrmUser: '/prmauthentication/v1/PrmAuth/Secure/User',
    SendResetPassordEmail: '/prmauthentication/v1/PrmAuth/SendResetPassordEmail',
    taxRatesOpen: '/prmbookings/v1/auth/openapi/payment/taxRates',
    selfSignup: '/prmauthentication/v1/PrmAuth/SelfSignUp',
    editTenantData: 'Secure/UpdateUserProfile',
    bookingExistsPrm: '/prm-calendar-service/api/v1/booking/booking-exists',
    openBookingExistsPrm: '/prm-calendar-service/api/v1/openapi/booking/booking-exists',
    proposalAcceptPRM: '/prmamp/v1/auth/proposal/accept',
    publishKeyPRM: '/prmbookings/v1/auth/openapi/customer/publishKey',
    propertyEntirePortfolioPRM: '/prmutility/v1/tickets/requesttype/property-entirePortfolio',
    propertyPRM: '/prmamp/v1/auth/secure/property/templatePresent',
    prmStripeInvoice: '/prmbookings/v1/auth/secure/invoice/enterprise/generate',
    userstatus: '/prmauthentication/v1/PrmAuth/Secure/User/userstatus',
    resendInvite: '/prmauthentication/v1/PrmAuth/Open/ResendInvite',
    commentDelete: '/prmamp/v1/auth/secure/comment/',
    openApiProduct: '/prmamp/v1/openApi/product',
    insertNewTypeInPortfolio: '/prmutility/v2/setting/insertNewTypeInPortfolio',
    clearCache: '/prmauthentication/v1/PrmAuth/ClearCache?fromUI=true',
    combineCharge: '/prmbookings/v1/auth/secure/booking/charge/combine',
    combineChargeDownload: '/prmbookings/v1/auth/secure/booking/charge/download',
    confirmProductSession: '/prmbookings/v1/auth/secure/meeting/payment/confirm-product-session',
    bookingDetail: '/prmbookings/v1/auth/secure/meeting/payment/bookingId',
    getCssOpenAPIConfigProduct: '/prmamp/v1/openApi/getCssOpenAPIConfig',
    findOutMoreOpenApi: '/prmamp/v1/openApi/product/findOutMore',
    userPortfolios: '/prmamp/v1/auth/secure/portfolio/userPortfolios',
    productPricing: '/prmamp/v1/auth/secure/product/pricing',
    productPrice: '/prmbookings/v1/openApi/booking/price',
    checkoutAsGuest: '/prmamp/v1/openApi/product/checkoutAsGuest',
    login: '/prmauthentication/v1/PrmAuth/Login',
    openCreateProductSession: '/prmbookings/v1/openApi/meeting/payment/create-product-session',
    openConfirmProductSession: '/prmbookings/v1/openApi/meeting/payment/confirm-product-session',
    openBookingDetail: '/prmbookings/v1/openApi/meeting/payment/bookingId',
    totalAmountProposal: '/prmamp/v1/auth/secure/proposal/monthlyTotal',
    openTotalAmountProposal: '/prmamp/v1/openApi/proposal/monthlyTotal',
    checkin: '/prmutility/v1/auth/secure/checkin',
    getUserCheckinDetail: '/prmutility/v1/auth/secure/checkin/user/',
    deleteCheckin: '/prmutility/v1/auth/secure/checkin/',
    enterpriseCheckin: '/prmutility/v1/auth/secure/checkin/enterprise/',
    openPaymentStatus: '/prm-calendar-service/api/v1/openapi/meeting-room-booking/payment-status',
    calculatePricing: '/prmbookings/v1/auth/secure/booking/price',
    openTeamData: '/prmamp/v1/openApi/product',
    roleListByTabName: '/prmauthentication/v1/PrmAuth/Secure/Role/ListByTabName',
    productFromFloorJson: '/prmamp/v1/openApi/product/productFromFloorJson/',
    inventory: '/prmbookings/v1/auth/v1/auth/secure/product/inventory',
    productInventory: '/prmamp/v1/auth/secure/productInventory',
    exportMyData: '/prmamp/v1/auth/secure/human/getDataByEmail',
    getDataByEmail: '/prmamp/v1/auth/secure/human/getDataByEmail',
    messageHistoryList: '/prmutility/v1/message/messageHistoryList',
    UpdateUser: '/prmauthentication/v1/PrmAuth/Secure/UpdateUser',
    mriExcute: '/prmamp/v1/auth/mri/execute',
    productByRole: '/prmamp/v1/auth/secure/product/portfolio/page',
    listEnableProductTemplates: '/prmutility/v2/template/listEnableProductTemplates/',
    getActiveContractProducts: '/prmamp/v1/auth/secure/enterprise/getActiveContractProducts',
    delete_contract: 'contract/delete/',
    failedTransaction: '/prmbookings/v1/openApi/product/inventory/payment/failed-transaction/',
    stripeAccounts: '/prmbookings/v1/auth/secure/customer/stripe/stripeAccounts',
    productStripeAccount: '/prmbookings/v1/auth/secure/customer/stripe/product/stripeAccount',
    deleteKeyValueFromAllPortfolio: '/prmutility/v2/setting/deleteKeyValueFromAllPortfolio',
    deleteKeyValueFromOnePortfolio: '/prmutility/v2/setting/deleteKeyValueFromOnePortfolio/',
    calendarList: 'https://www.googleapis.com/calendar/v3/users/me/calendarList',
    googleToken: "https://www.googleapis.com/oauth2/v4/token",
    addToCalendars: "https://www.googleapis.com/calendar/v3/calendars",
    bookingConfig: "/prmbookings/v1/auth/secure/product/booking/config",
    logout: '/prmauthentication/v1/PrmAuth/Secure/Logout',
    bookingsByProduct: '/prm-calendar-service/api/v1/openapi/booking/bookingsByProduct',
    newStripeUpdate : '/prmbookings/v1/auth/secure/customer/stripe/createUpdateCustomerEnterpriseStripe',
    getCustomerEnterpriseStripe : '/prmbookings/v1/auth/secure/customer/stripe/getCustomerEnterpriseStripe',
    stripeAccountOpen: '/prmbookings/v1/openApi/customer/stripe/product/stripeAccount',
    noPricingOpen: '/prmbookings/v1/openApi/meeting/noPaymentBooking',
    getConsent : '/prmamp/v1/auth/secure/consent/getConsent',
    postConsent : '/prmamp/v1/auth/secure/consent/postConsent',
    checkVisitorEmailStatus: '/prmbookings/v1/openApi/visitorNative/checkVisitorEmailStatus',
    checkInCheckOutOpen: '/prmbookings/v1/openApi/visitorNative/checkInCheckOut',
    visitorNativeOpen: '/prmbookings/v1/openApi/visitorNative',
    confirmedusersOpen: '/prmamp/v1/openApi/enterpriseOpen/{enterpriseId}/confirmedusers',
    enterpriseOpen: '/prmamp/v1/openApi/property/{propertyId}/enterprise?isCustomer=true',
    deleteCompanyPaymentMethod: '/prmbookings/v1/auth/secure/customer/stripe/deleteCompanyPaymentMethod',
    prmCreateCustomerV2: '/prmbookings/v1/auth/secure/customer/enterprise/v2/create-customer',
    publishKey: '/prmbookings/v1/auth/secure/customer/publishKey',
    prmUpdateCustomerV2: '/prmbookings/v1/auth/secure/customer/enterprise/v2/update-customer',
    plaidLinkToken: '/prmbookings/v1/openApi/ach/plaidLinkToken',
    addCustomerByBankToken: '/prmbookings/v1/openApi/ach/addCustomerByBankToken',
    messageSummary: '/prmutility/v1/message/messageSummary'
};

