import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { ApiUrls } from '../shared/constants/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  utilityBaseUrl = '';
  utilityBaseUrl2 = '';

  allSettings: any = {};
  themeSettingsData: any = {};
  baseUrl: string = '';

  isCombinedApiCalled = false;

  constructor(
    private http: HttpClient, private dataService: DataService,
    private titleService: Title, @Inject(DOCUMENT) private document: HTMLDocument
  ) {
      this.setBaseUrl();
    
  }

  setBaseUrl() {
    let url = this.environmentUpdatedUrl();
    this.baseUrl = this.getDomain();
    this.utilityBaseUrl = url + '/prmutility/v2/';
    this.utilityBaseUrl2 = url + '/prmutility/v2/';
  }

  getAllSettings(data?: any): Observable<any> {
    this.setBaseUrl();
    const searchParams = this.appendParams(data);
    return this.http.get(`${this.utilityBaseUrl2}setting/getallcombinedvalue/${this.getDomain()}`, { params: searchParams })
      .pipe(map((response: any) => {
        localStorage.setItem("google_analytics_key", response.data.google_analytics_key);
        return response;
      }));
  }



  getAllAmpSettings(data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.utilityBaseUrl}${ApiUrls.SYSTEM_SETTINGS.PROPERTYSETTING}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getAllThemeSettings(data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    let apiUrl;
    apiUrl = `${this.utilityBaseUrl2}setting/gettheme/${this.baseUrl}`;

    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getLoginLogoTheme(data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    let apiUrl = `${this.utilityBaseUrl2}setting/getloginlogo/${this.baseUrl}`;;

    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getPublicAmpLogo(data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.utilityBaseUrl}${ApiUrls.SYSTEM_SETTINGS.AMP_LOGO}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getPublicRXRLogo(data?: any): Observable<any> {
    const searchParams = this.appendParams(data);
    const apiUrl = `${this.utilityBaseUrl}${ApiUrls.SYSTEM_SETTINGS.RXR_LOGO}`;
    return this.http.get(apiUrl, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getAllAppLinks(data?: any): Observable<any> {
    this.setBaseUrl();
    const searchParams = this.appendParams(data);
    return this.http.get(`${this.utilityBaseUrl}setting/getapplink/${this.baseUrl}`, { params: searchParams })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  saveNewSetting(data): Observable<any> {
    let apiUrl;
    apiUrl = `${this.utilityBaseUrl}setting/update/${this.baseUrl}/all`;

    return this.http.post(apiUrl, data)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  /**
   * @method (append params)
   * @param myParams (pass data which you want to make http params)
   */
  appendParams(myParams: { [x: string]: any; }): HttpParams {
    let params = new HttpParams();
    if (!!myParams) {
      for (const key of Object.keys(myParams)) {
        // if (data[key] || key == 'skip')
        params = params.append(key, myParams[key]);
      }
    }
    return params;
  }

  private systemSettings = new BehaviorSubject<any>(null);
  getSystemSettings = this.systemSettings.asObservable();
  setSystemSettings(data) {
    this.systemSettings.next(data);
  }

  private appLinks = new BehaviorSubject<any>(null);
  getAppLinks = this.appLinks.asObservable();
  setAppLinks(data) {
    this.appLinks.next(data);
  }

  private themeSettings = new BehaviorSubject<any>(null);
  getThemeSettings = this.themeSettings.asObservable();
  setThemeSettings(data) {
    this.themeSettings.next(data);
    this.themeSettingsData = data;
  }

  getAmpPortalLogo() {
    try {
      return this.allSettings.amp_portal_logo;
    } catch (e) {
      return false;
    }
  }

  makeSystemSettings(response) {
    let data = JSON.parse(response);
    let newKeys = {};
    for (const key of Object.keys(data)) {
      for (const childKey of Object.keys(data[key])) {
        newKeys[childKey] = data[key][childKey];
      }
    }
    return newKeys;
  }

  setAllSettings(settings) {
    localStorage.setItem("google_analytics_key", settings.google_analytics_key);
    localStorage.country = settings.Country;
    document.documentElement.style.setProperty('--css-darkest-color', settings['$darkest']);
    document.documentElement.style.setProperty('--css-darker-color', settings['$darker']);
    document.documentElement.style.setProperty('--css-dark-color', settings['$dark']);
    document.documentElement.style.setProperty('--css-accent-color', settings['$accent']);
    document.documentElement.style.setProperty('--css-accent-light-color', settings['$accent-light']);
    document.documentElement.style.setProperty('--css-white-color', settings['$white']);
    document.documentElement.style.setProperty('--css-light-color', settings['$light']);
    document.documentElement.style.setProperty('--css-accent-dark', settings['$accent-dark']);
    document.documentElement.style.setProperty('--css-primary-text-color', settings.css_primary_text_color);
    document.documentElement.style.setProperty('--css-h1-font-size', settings['$h1-font-size']);
    document.documentElement.style.setProperty('--css-h1-line-height', settings['$h1-line-height']);
    document.documentElement.style.setProperty('--css-h1-letter-spacing', settings['$h1-letter-spacing']);
    document.documentElement.style.setProperty('--css-h1-font-weight', settings['$h1-font-weight']);
    document.documentElement.style.setProperty('--h1-font-family', settings['$h1-font-family']);

    document.documentElement.style.setProperty('--css-nav-background-color', settings.css_amp_navbar_background_color);
    document.documentElement.style.setProperty('--css-nav-text-color', settings.css_amp_navbar_text_color);
    document.documentElement.style.setProperty('--css-pages-background-color', settings.css_amp_pages_background_color);
    document.documentElement.style.setProperty('--css-table-odd-rows-background', settings.css_table_odd_rows_background);
    document.documentElement.style.setProperty('--css-table-header-background', settings.css_table_header_background);
    document.documentElement.style.setProperty('--css-table-header-text', settings.css_table_header_text_color);
    document.documentElement.style.setProperty('--css-nav-search-background', settings.css_nav_search_background);
    document.documentElement.style.setProperty('--css-form-inputs-background', settings.css_amp_form_inputs_background);
    document.documentElement.style.setProperty('--css-header-sort-active-color', settings.css_header_sort_active_color);
    document.documentElement.style.setProperty('--css-buttons-background', settings.css_amp_buttons_background);
    document.documentElement.style.setProperty('--css-buttons-text-color', settings.css_buttons_text_color);
    document.documentElement.style.setProperty('--css-footer-background-color', settings.css_amp_footer_background_color);
    document.documentElement.style.setProperty('--css-cards-background-color', settings.css_amp_cards_background);
    document.documentElement.style.setProperty('--css-reset-btn-background-color', settings.css_amp_reset_button_background);
    document.documentElement.style.setProperty('--css-reset-btn-text-color', settings.css_amp_reset_button_text_color);
    document.documentElement.style.setProperty('--css-btn-border-radius', settings.css_amp_buttons_border_radius);
    document.documentElement.style.setProperty('--css-btn-border', settings.css_amp_buttons_border);

    document.documentElement.style.setProperty('--body-large-font-family', settings['$body-large-font-family']);
    document.documentElement.style.setProperty('--body-large-font-size', settings['$body-large-font-size']);
    document.documentElement.style.setProperty('--body-large-letter-spacing', settings['$body-large-letter-spacing']);
    document.documentElement.style.setProperty('--body-large-line-height', settings['$body-large-line-height']);
    document.documentElement.style.setProperty('--body-large-font-weight', settings['$body-large-font-weight']);
    document.documentElement.style.setProperty('--body-large-color', settings['$body-large-color']);

    // new added keys
    if (settings['$amp_contrast'] && settings['$amp_dark']) {
      settings['$amp_dark_mix'] = this.colorMixer(this.hex_to_rgb(settings['$amp_contrast']), [0, 0, 0], (parseInt(settings['$amp_dark']) / 100));
    }
    if (settings['$amp_dark_mix'] && settings['$amp_mid']) {
      settings['$amp_mid_mix'] = this.colorMixer(settings['$amp_dark_mix'].replace(/[^\d,]/g, '').split(','), [255, 255, 255], (parseInt(settings['$amp_mid']) / 100));
    }
    if (settings['$amp_mid_mix'] && settings['$amp_light']) {
      settings['$amp_light_mix'] = this.colorMixer(settings['$amp_mid_mix'].replace(/[^\d,]/g, '').split(','), [255, 255, 255], (parseInt(settings['$amp_light']) / 100));
    }
    if (settings['$amp_light_mix'] && settings['$amp_background']) {
      settings['$amp_background_mix'] = this.colorMixer(settings['$amp_light_mix'].replace(/[^\d,]/g, '').split(','), [255, 255, 255], (parseInt(settings['$amp_background']) / 100));
    }

    document.documentElement.style.setProperty('--amp-contrast-color', settings['$amp_contrast']);
    document.documentElement.style.setProperty('--amp-dark-color', settings['$amp_dark_mix']);
    document.documentElement.style.setProperty('--amp-mid-color', settings['$amp_mid_mix']);
    document.documentElement.style.setProperty('--amp-light-color', settings['$amp_light_mix']);
    document.documentElement.style.setProperty('--amp-background-color', settings['$amp_background_mix']);
    document.documentElement.style.setProperty('--amp-action-color', settings['$amp_action']);
    document.documentElement.style.setProperty('--amp-white-color', settings['$amp_white']);
    document.documentElement.style.setProperty('--amp-link-color', settings['$amp_link']);
    document.documentElement.style.setProperty('--amp-error-color', settings['$amp_error']);
    document.documentElement.style.setProperty('--amp-success-color', settings['$amp_success']);

    // Typography keys
    document.documentElement.style.setProperty('--amp-h1-font-family', settings['$amp_h1_font_family']);
    document.documentElement.style.setProperty('--amp-h1-font-weight', settings['$amp_h1_font_weight']);
    document.documentElement.style.setProperty('--amp-h1-font-size', settings['$amp_h1_font_size']);
    document.documentElement.style.setProperty('--amp-h1-font-line-height', settings['$amp_h1_font_line_height']);

    document.documentElement.style.setProperty('--amp-h2-font-family', settings['$amp_h2_font_family']);
    document.documentElement.style.setProperty('--amp-h2-font-weight', settings['$amp_h2_font_weight']);
    document.documentElement.style.setProperty('--amp-h2-font-size', settings['$amp_h2_font_size']);
    document.documentElement.style.setProperty('--amp-h2-font-line-height', settings['$amp_h2_font_line_height']);

    document.documentElement.style.setProperty('--amp-h3-font-family', settings['$amp_h3_font_family']);
    document.documentElement.style.setProperty('--amp-h3-font-weight', settings['$amp_h3_font_weight']);
    document.documentElement.style.setProperty('--amp-h3-font-size', settings['$amp_h3_font_size']);
    document.documentElement.style.setProperty('--amp-h3-font-line-height', settings['$amp_h3_font_line_height']);

    document.documentElement.style.setProperty('--amp-h4-font-family', settings['$amp_h4_font_family']);
    document.documentElement.style.setProperty('--amp-h4-font-weight', settings['$amp_h4_font_weight']);
    document.documentElement.style.setProperty('--amp-h4-font-size', settings['$amp_h4_font_size']);
    document.documentElement.style.setProperty('--amp-h4-font-line-height', settings['$amp_h4_font_line_height']);

    document.documentElement.style.setProperty('--amp-body-font-family', settings['$amp_body_font_family']);
    document.documentElement.style.setProperty('--amp-body-font-weight', settings['$amp_body_font_weight']);
    document.documentElement.style.setProperty('--amp-body-font-size', settings['$amp_body_font_size']);
    document.documentElement.style.setProperty('--amp-body-font-line-height', settings['$amp_body_font_line_height']);

    document.documentElement.style.setProperty('--amp-text-font-family', settings['$amp_text_font_family']);
    document.documentElement.style.setProperty('--amp-text-font-weight', settings['$amp_text_font_weight']);
    document.documentElement.style.setProperty('--amp-text-font-size', settings['$amp_text_font_size']);
    document.documentElement.style.setProperty('--amp-text-font-line-height', settings['$amp_text_font_line_height']);

    // this.allSettingsLoaded = true;

    // prm new design tokens
    document.documentElement.style.setProperty('--primary-color', settings.color_primary || '#02070C');
    document.documentElement.style.setProperty('--secondary-color', settings.color_secondary || '#F0723D');
    document.documentElement.style.setProperty('--tertiary-color', settings.color_tertiary || '#F3F3F3');

    document.documentElement.style.setProperty('--dark-color', settings['$dark']);
    document.documentElement.style.setProperty('--mid-color', settings['$mid']);
    document.documentElement.style.setProperty('--light-color', settings['$light']);
    document.documentElement.style.setProperty('--fog-color', settings['$fog']);
    document.documentElement.style.setProperty('--white-color', settings['$white']);
    document.documentElement.style.setProperty('--link-color', settings['$link']);
    document.documentElement.style.setProperty('--error-color', settings['$error']);
    document.documentElement.style.setProperty('--success-color', settings['$success']);
    document.documentElement.style.setProperty('--amp-light-color', settings['$light']);
    document.documentElement.style.setProperty('--amp-dark-color', settings['$dark']);
    this.setSettingData(settings);
  }

  setSettingData(allSettings) {
    this.document.getElementById('appicon').setAttribute('href', allSettings.favicon_link);
    this.titleService.setTitle(allSettings.webpage_tab_name_amp || 'Ility');
    document.documentElement.style.setProperty('--css-brand-logo-font', allSettings.css_login_brand_logo_size);
    if (allSettings.amp_font) {
      this.setFont(allSettings.amp_font);
    } else if (allSettings.rxr_font) {
      this.setFont(allSettings.rxr_font);
    }
  }

  // Adding it for future if theming is required in amp
  setFont(font) {
    let fonts = JSON.parse(font);
    console.log(fonts);
    let styles = '';
    fonts.forEach(element => {
      styles = styles + `
      @font-face {
        font-family: "${element.name}";
        src: url('${element.url}') format('${element.format}');
      }
      `;
    });

    const node = document.createElement('style');
    node.innerHTML = styles;
    document.body.appendChild(node);

    fonts.forEach(element => {
      if (element.type == 'light') {
        document.documentElement.style.setProperty('--light-font', `"${element.name}"`);
      }
      if (element.type == 'regular') {
        document.documentElement.style.setProperty('--regular-font', `"${element.name}"`);
      }
      if (element.type == 'bold') {
        document.documentElement.style.setProperty('--bold-font', `"${element.name}"`);
      }
      if (element.element == 'general') {
        document.documentElement.style.setProperty('--general-font', `"${element.name}"`);
      }
    });
  }

  colorMixer(rgbA, rgbB, amountToMix) {
    var r = this.colorChannelMixer(parseInt(rgbA[0]), parseInt(rgbB[0]), amountToMix);
    var g = this.colorChannelMixer(parseInt(rgbA[1]), parseInt(rgbB[1]), amountToMix);
    var b = this.colorChannelMixer(parseInt(rgbA[2]), parseInt(rgbB[2]), amountToMix);
    return "rgb(" + r + "," + g + "," + b + ")";
  }

  colorChannelMixer(colorChannelA, colorChannelB, amountToMix) {
    var channelA: any = colorChannelA * (1 - amountToMix);
    var channelB: any = colorChannelB * amountToMix;
    return parseInt(channelA + channelB);
  }

  hex_to_rgb(hex) {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal) return normal.slice(1).map(e => parseInt(e, 16));
    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand) return shorthand.slice(1).map(e => 0x11 * parseInt(e, 16));
    return null;
  }

  setLocalDomain(domain) {
    localStorage.setItem('selected-portfolio', domain);
  }
  setLocalDomainURL(domainURL) {
    localStorage.setItem('selected-portfolio-domain', domainURL)
  }

  getDomain() {
    if (window.location.host.indexOf('localhost') > -1) {
      let localDomain = localStorage.getItem('selected-portfolio');
      if (localStorage.getItem('selected-portfolio')) {
        return localDomain;
      }
      return 'prm1.ility.space';
    } else {
      let localDomain = localStorage.getItem('selected-portfolio');
      if (localStorage.getItem('selected-portfolio')) {
        return localDomain;
      }
      return window.location.host;
    }
  }

  getDomainURL() {
    if (window.location.host.indexOf('localhost') > -1) {
      return 'prm1.ility.space';
    } else {
      return window.location.host;
    }
  }


  changeBaseURL() {
    this.baseUrl = this.getDomain();
  }

  private tenantMenu = new BehaviorSubject<any>(null);
  getTenantMenu = this.tenantMenu.asObservable();
  setTenantMenu(data) {
    this.tenantMenu.next(data);
  }

  updateWindowUrl(domain) {
    let prmSelectedPortfolioId = localStorage.getItem("prmSelectedPortfolioId");
    let selectedPortfolio = localStorage.getItem("selected-portfolio");
    let loginSession = JSON.parse(localStorage.LoginSession);
    localStorage.clear();
    let baseurl = `${window.location.protocol}//${domain.domain_name}/updatelocalstorage?token=${loginSession.refreshToken}&id=${loginSession.user_id}`;
    window.location.href = `${baseurl}&portfolioId=${prmSelectedPortfolioId}&portfolioName=${selectedPortfolio}&loggedInDate=${loginSession.loggedInDate}`;
  }

  makeAdminKeys(allNewkeys) {
    let allKeys = [];
    for (const key of Object.keys(allNewkeys)) {
      if (key.indexOf('fename_') > -1) {
        let mainKey = key.split('fename_')[1];
        allKeys.push({
          key: mainKey,
          name: allNewkeys[key],
          value: allNewkeys[mainKey]
        });
      }
    }
    return allKeys;
  }

  makeSuperAdminKeys(allNewkeys) {
    let allKeys = [];
    for (const key of Object.keys(allNewkeys)) {
      if (key.indexOf('fename_') == -1) {
        allKeys.push({
          key: key,
          name: allNewkeys[`fename_${key}`],
          value: allNewkeys[key]
        });
      }
    }
    return allKeys;
  }

  environmentUpdatedUrl() {
    let url = this.dataService.allEnvConfig.domain;
    if (this.dataService.allEnvConfig.alternate_url) {
      let data = this.dataService.allEnvConfig.alternate_url;
      if (data[window.location.host]) {
        url = `https://${data[window.location.host]}`;
      }
    }
    return `https://prm-api.ility.space` ;
  }

  splitPlusProductId(nameId) {
    let name = nameId.split('+');
    return name[name.length - 1];
  }

}
