<h1>
  Date Input
  <span class="float-right">
    <!-- <input id="check_required" type="checkbox" class="input-box" [checked]="required"
    (change)="required = !required" /> Required -->
    Required <mat-slide-toggle [checked]="required" (change)="required = !required">
    </mat-slide-toggle>
  </span>
</h1>
<div class="container-fluid">
  <!-- label with name -->
  <div class="form-group">
    <input class="form-control" id="label" type="text" placeholder="Label" [(ngModel)]="label" />
    <div class="form-label float-left">example: What is your first name?</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <!-- <<<<<< main input component -->
  <div class="form-group">
    <input class="form-control" id="input_Id" type="text" placeholder="ID" [(ngModel)]="inputID" value="inputID" />
    <div class="form-label float-left">example: startDate</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <div class="form-group">
    <input class="form-control" id="input_name" type="text" placeholder="Name" [(ngModel)]="inputName"
      value="inputName" />
    <div class="form-label float-left">example: startDate</div>
    <div class="form-label float-right" style="font-weight: 600;">Field required</div>
  </div>
  <div class="form-group">
    <input class="form-control" id="placeholder_Id" type="text" placeholder="Placeholder" [(ngModel)]="placeholder"
      value="{{placeholder}}" />
    <div class="form-label float-left">example: MM/DD/YYYY</div>
  </div>
  <div class="form-group">
    <span [owlDateTimeTrigger]="dt1">
      <input [owlDateTime]="dt1" placeholder="Min Date" class="form-control" [(ngModel)]="min" readonly />
    </span>
    <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
    <!-- <input class="form-control" id="min_Id" type="text" placeholder="min" [(ngModel)]="min" value="{{min}}"/> -->
    <div class="form-label float-left">example: 12/11/2018</div>
    <!-- <div class="form-label float-right" style="font-weight: 600;">Field required</div> -->
  </div>
  <div class="form-group">
    <!-- <input class="form-control" id="max_Id" type="text" placeholder="max" [(ngModel)]="max" value="{{max}}"/> -->
    <span [owlDateTimeTrigger]="dt3">
      <input [owlDateTime]="dt3" placeholder="Max Date" class="form-control" [(ngModel)]="max" readonly />
    </span>
    <owl-date-time #dt3 [pickerType]="'calendar'"></owl-date-time>
    <div class="form-label float-left">example: 12/12/2018</div>
    <!-- <div class="form-label float-right" style="font-weight: 600;">Field required</div> -->
  </div>
  <div class="form-group">
    <div class="form-control">
      <div class="float-left">
        Enable Past Dates
      </div>
      <input id="check_enablePastDates" type="checkbox" class="input-box checkbox-30 float-right"
        [checked]="enablePastDates" (change)="enablePastDates = !enablePastDates" />
    </div>
    <div class="form-label float-left"> default: false</div>
  </div>

  <div class="form-group">
    <div class="form-control">
      <div class="float-left">
        Enable Future Dates
      </div>
      <input id="check_enableFutureDates" type="checkbox" class="input-box checkbox-30 float-right"
        [checked]="enableFutureDates" (change)="enableFutureDates = !enableFutureDates" />
    </div>
    <div class="form-label float-left"> default: false</div>
  </div>

  <div class="form-group">
    <div class="form-control">
      <div class="float-left">
        Enable Internationalization
      </div>
      <input id="check_internationalization" type="checkbox" class="input-box checkbox-30 float-right"
        [checked]="internationalization" (change)="internationalization = !internationalization" />
    </div>
    <div class="form-label float-left"> default: false</div>
  </div>

  <div class="form-group">
    <input class="form-control" id="validationAttribute_Id" type="text" placeholder="Validation Attribute"
      [(ngModel)]="validationAttribute" value="{{validationAttribute}}" />
    <div class="form-label float-left">example: Product</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>
  <div class="form-group">
    <input class="form-control" id="enable" type="text" placeholder="Enable" [(ngModel)]="enable" value="{{enable}}" />
    <div class="form-label float-left">example: UUID</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>
  <div class="form-group">
    <input class="form-control" id="disable" type="text" placeholder="Disable" [(ngModel)]="disable"
      value="{{disable}}" />
    <div class="form-label float-left">example: UUID</div>
    <div class="form-label float-right" style="font-weight: 600;">
    </div>
  </div>
  <div class="form-group">
    <span [owlDateTimeTrigger]="dt2">
      <input [owlDateTime]="dt2" placeholder="Date Time" class="form-control" [(ngModel)]="defaultValue" readonly />
    </span>
    <owl-date-time #dt2 [pickerType]="'calendar'"></owl-date-time>
  </div>

  <div class="form-group">
    <input class="form-control" id="description" type="text" placeholder="Description" [(ngModel)]="description"
      value="{{description}}" maxlength="1000" />
    <div class="form-label float-left">example: You can explain more about what this field will be used for.</div>
  </div>

  <div class="row" *ngFor="let count of validatorCount;index as i">
    <div class="col-md-1" *ngIf="i == 0">
      <button class="btn btn-sm" (click)="addMoreValidators()">
        +
      </button>
    </div>
    <div class="col-md">Add Validator</div>
    <div class="col-md">
      <input type="text" name="fromUUID" id="fromUUID" [(ngModel)]="count['from']" placeholder="From"
        class="form-control" />
    </div>
    <div class="col-md">
      <select id="typeOfComparision" name="typeOfComparision" [(ngModel)]="count['type']" class="form-control">
        <option value="" selected>Select comparision</option>
        <option value="lessThan">
          << /option>
        <option value="greaterThan">></option>
        <option value="notEqualsTo">!==</option>
        <option value="equalsTo">===</option>
      </select>
    </div>
    <div class="col-md">
      <input type="text" name="toUUID" id="toUUID" [(ngModel)]="count['to']" placeholder="To" class="form-control" />
    </div>
    <div class="col-md-1" *ngIf="i != 0">
      <button class="btn btn-sm" (click)="removeValidators(i)">
        -
      </button>

    </div>
  </div>
  <div class="text-danger">
    <span class="p-3 fw-600">
      {{errorMsg}}
    </span>
  </div>
  <div class="form-group">
    <button class="btn float-left" (click)="onCancel()">Cancel</button>
    <button class="btn float-right" (click)="modifyTemplateJSON()">Save & Update</button>
  </div>

</div>